<template>
  <div class="bg">
    <div class="products">
      <loading v-show="loadingShow"></loading>
      <div class="header">
        <div class="account">
          当前账号:
          <b>{{ store.state.user.email }}</b>
        </div>
      </div>
      <div class="content">
        <div class="title">
          <b>选购超级VPS</b>
          <h1>{{ products.name }}</h1>
          <p>
            <span v-for="(des, index) in products.desc" v-bind:key="index"
              >{{ des }} /
            </span>
          </p>
        </div>
        <div class="steps">
          <div class="groups" v-if="getObjectLength(products.groups) > 1">
            <h4>选择用户数量:</h4>
            <ul class="list groups-list">
              <li
                v-for="(group, index) in products.groups"
                v-bind:key="index"
                @click="changeGroup(index, group)"
                :class="{ active: index === groupNumber }"
              >
                {{ group[0].give_son_num }}用户
              </li>
            </ul>
          </div>
          <div class="months">
            <h4>选择结算周期:</h4>
            <ul class="list months-list">
              <li
                :class="{ active: monthNumber === 99 }"
                @click="changeMonth(99, products.groups[groupNumber][0])"
                v-if="
                  Number($store.state.user.vip) > 0 &&
                    (Number($store.state.user.vip) < Number(products.vip) ||
                      Number($store.state.user.vipInfo.son_num) + 1 <
                        Number(products.groups[groupNumber][0].give_son_num))
                "
              >
                <h4>仅升级套餐</h4>
                <p>升级套餐剩余时间</p>
              </li>
              <li
                v-for="(product, index) in products.groups[groupNumber]"
                v-bind:key="index"
                :class="{ active: index === monthNumber }"
                @click="changeMonth(index, product)"
              >
                <h4>{{ product.title }}</h4>
                <p class="price">
                  {{ product.price_discount }}元
                  <span
                    v-if="
                      Number(product.price) > Number(product.price_discount)
                    "
                    >{{ product.price }}元</span
                  >
                </p>
                <span
                  v-if="
                    Number(product.price) === Number(product.price_discount)
                  "
                >
                  <span v-if="product.discount">{{ product.discount }}折</span>
                </span>
                <span v-else class="orange">
                  活动节省
                  {{ Number(product.price) - Number(product.price_discount) }}
                  元
                </span>
                <span v-if="product.is_hot === '1'" class="orange">
                  推荐购买
                </span>
              </li>
            </ul>
          </div>
          <div class="pays">
            <h4>选择支付方式</h4>
            <ul class="list pays-list">
              <li
                v-for="(pay, index) in pays"
                v-bind:key="index"
                :class="{ active: index === payNumber }"
                @click="changePay(index, pay)"
              >
                <WechatOutlined v-if="index === 0" />
                <AlipaySquareFilled v-else />
                {{ pay.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="submit">
        <a-button @click="$emit('cancel')" type="link">取消</a-button>
        <a-button type="primary" @click="submitHandleOldUser">下一步</a-button>
      </div>
    </div>
  </div>
  <!--客服-->
  <service
    v-if="serviceShow"
    @cancel="serviceShow = false"
    :tips="serviceTips"
  ></service>
  <!--补差价-->
  <order-update
    v-if="orderUpdateInfo.show"
    :days="orderUpdateInfo.days"
    :price="orderUpdateInfo.priceDiff"
    :total="orderUpdateInfo.priceTotal"
    @cancel="orderUpdateInfo.show = false"
    @confirm="
      orderUpdateInfo.show = false;
      orderPayInfo.show = true;
    "
  ></order-update>
  <!--弹出二维码支付订单-->
  <order-pay
    v-if="orderPayInfo.show"
    :pic-url="orderPayInfo.picUrl"
    :order-no="orderPayInfo.orderNo"
    :pay-model="orderPayInfo.payModel"
    @cancel="orderPayInfo.show = false"
    @success="$emit('cancel')"
  ></order-pay>
  <!--提示-->
  <popup v-if="popupShow" @cancel="popupShow = false" @confirm="submitOrder">
    <template v-slot:title>
      您正在从旧版套餐升级
    </template>
    <template v-slot:describe>
      因为旧版套餐已不在提供，所以升级行为不可逆，请慎重选择比较!
    </template>
  </popup>
</template>

<script>
import { WechatOutlined, AlipaySquareFilled } from "@ant-design/icons-vue";
import Loading from "@/components/Loading";
import { useStore } from "vuex";
import { onMounted, reactive, ref } from "vue";
import { getObjectLength } from "@/utils/utils";
import { buy } from "@/utils/api";
import Service from "@/components/Service";
import OrderUpdate from "@/components/OrderUpdate";
import OrderPay from "@/components/OrderPay";
import Popup from "@/components/Popup";

export default {
  name: "ProductsShop",
  components: {
    Popup,
    OrderPay,
    Service,
    Loading,
    OrderUpdate,
    WechatOutlined,
    AlipaySquareFilled
  },
  props: { products: { type: Object }, pays: { type: Object }, focus: {} },
  emits: ["cancel"],
  setup(props) {
    // loading
    const loadingShow = ref(false);
    // store
    const store = useStore();
    // products
    // 切换用户套餐
    const groupNumber = ref(0);
    function changeGroup(index, group) {
      groupNumber.value = index;
      setMonthRecommend(group);
    }
    // 切换套餐周期
    const monthNumber = ref(0);
    function changeMonth(index, month) {
      monthNumber.value = index;
      itemId.value = month.id;
    }
    // 设置推荐周期
    function setMonthRecommend(group) {
      group.forEach((item, index) => {
        if (item.is_recommend === "1") {
          monthNumber.value = index;
          itemId.value = item.id;
        }
      });
    }
    // 设置焦点
    function setFocus() {
      props.products.groups.forEach((group, index) => {
        if (Number(group[0].give_son_num) === Number(props.focus.group)) {
          groupNumber.value = index;
          setMonthRecommend(group);
        }
      });
      props.products.groups[groupNumber.value].forEach((product, index) => {
        if (product.title === props.focus.title) {
          monthNumber.value = index;
          itemId.value = product.id;
        }
      });
    }
    // 切换支付方式
    const payNumber = ref(0);
    function changePay(index, pay) {
      payNumber.value = index;
      payMentId.value = pay.id;
    }
    // 初始化选中商品, 未切换状态下可以直接购买
    const itemId = ref(null);
    const payMentId = ref(props.pays[0].id);
    onMounted(() => setFocus());
    // 提交订单
    async function submitOrder() {
      popupShow.value = false;
      loadingShow.value = true;
      let isUpdate = 0;
      if (monthNumber.value === 99) {
        isUpdate = 1;
      }
      await buy.buy(itemId.value, payMentId.value, isUpdate).then(res => {
        loadingShow.value = false;
        if (res.data.code === 0) {
          // 设置二维码支付
          setOrderPay(res.data.data);
          if (Number(res.data.data.days) !== 0) {
            showOrderUpdate(
              res.data.data.days,
              res.data.data.price_diff,
              res.data.data.price_total
            );
          } else {
            orderPayInfo.show = true;
          }
        } else {
          showService(res.data.msg);
        }
      });
    }
    // 提交订单预处理
    const popupShow = ref(false);
    function submitHandleOldUser() {
      if (store.state.user.isOld === 1) {
        popupShow.value = true;
      } else {
        submitOrder();
      }
    }
    // 弹出二维码支付订单
    const orderPayInfo = reactive({
      show: false,
      orderNo: "",
      picUrl: "",
      payModel: 0
    });
    // 设置二维码支付
    function setOrderPay(payInfo) {
      orderPayInfo.orderNo = payInfo.order_no;
      orderPayInfo.picUrl = payInfo.payurl;
      orderPayInfo.payModel = payNumber.value;
    }
    // 升级套餐补差价
    const orderUpdateInfo = reactive({
      show: false,
      days: 0,
      priceDiff: 0,
      priceTotal: 0
    });
    function showOrderUpdate(days, priceDiff, priceTotal) {
      orderUpdateInfo.days = days;
      orderUpdateInfo.priceDiff = priceDiff;
      orderUpdateInfo.priceTotal = priceTotal;
      orderUpdateInfo.show = true;
    }
    // 出现问题弹出客服
    const serviceTips = ref("");
    const serviceShow = ref(false);
    function showService(message) {
      serviceTips.value = message;
      serviceShow.value = true;
    }
    return {
      loadingShow,
      store,
      getObjectLength,
      groupNumber, // 选择的套餐
      changeGroup,
      monthNumber, // 选择的周期
      changeMonth,
      payNumber,
      changePay,
      submitOrder,
      serviceShow, // 客服显示
      serviceTips, // 客服显示消息
      orderUpdateInfo, //套餐升级
      orderPayInfo, // 二维码支付
      submitHandleOldUser, // 旧用户处理
      popupShow // 旧用户升级提示
    };
  }
};
</script>
<style scoped>
.products {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(64, 64, 67, 0.3);
  max-width: 900px;
  min-width: 600px;
  min-height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 20px 30px;
}
.account {
  position: absolute;
  right: 30px;
  top: 30px;
}
.account b {
  color: #1a73e8;
}
.title {
  margin-top: 10px;
}
.title b {
  font-size: 14px;
  color: #7f868a;
  font-weight: 400;
}

.title h1 {
  font-size: 28px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #1a73e8;
}

.title p {
  font-size: 14px;
  margin: 0;
  padding: 2px 0;
}
.steps {
  border-top: 1px #f0f0f0 solid;
  margin-top: 10px;
  padding-top: 10px;
}
.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
ul.groups-list li {
  width: 80px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
  cursor: pointer;
  background: #f1f5fa;
  margin-right: 10px;
  margin-bottom: 10px;
}
.groups .active {
  background: #1a73e8;
  color: #ffffff;
}
.months {
  margin-top: 10px;
}
ul.months-list li {
  padding: 10px;
  min-width: 150px;
  border-radius: 4px;
  font-size: 12px;
  background: #f1f5fa;
  margin-right: 14px;
  margin-bottom: 10px;
  color: #676a6e;
  cursor: pointer;
}
ul.months-list li .price {
  padding: 4px 0;
  margin: 0;
  font-size: 18px;
  color: #202124;
  font-weight: 500;
}
ul.months-list li .price span {
  font-size: 12px;
  text-decoration: line-through;
  color: #676a6e;
}
ul.months-list li h4 {
  padding: 0;
  margin: 0;
  font-size: 14px;
}
ul.months-list .active {
  background: #1a73e8;
  color: rgba(255, 255, 255, 0.8);
}
ul.months-list .active h4 {
  color: #ffffff;
}
ul.months-list .active .price {
  color: #ffffff;
}
ul.months-list .active .price span {
  color: #ffffff;
}
.pays {
  margin-top: 10px;
}
ul.pays-list li {
  min-width: 140px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
  cursor: pointer;
  background: #f1f5fa;
  margin-right: 10px;
  margin-bottom: 10px;
}
.pays .active {
  background: #1a73e8;
  color: #ffffff;
}
.submit {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  border-top: 1px #f0f0f0 solid;
}
.orange {
  color: #fa8c16;
}
</style>
