<template>
  <button class="button">
    <span class="text">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: "ZButton"
};
</script>

<style scoped>
.button {
  background-color: #1d73e9;
  color: #ffffff;
  min-width: 88px;
  padding: 0 24px;
  height: 36px;
  font-size: 14px;
  font-family: "PingFang SC", Roboto, Arial, sans-serif;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 6px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 2px;
}
.button:hover {
  background-color: #2065ca;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 2px;
}
</style>
