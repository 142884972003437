import { createStore } from "vuex";

// create

const store = createStore({
  state() {
    return {
      user: {
        // 注册时间
        addTime: "",
        // 注册邮箱
        email: "",
        // 用户ID
        id: "",
        // 邀请码
        inviteCode: "",
        // 上次登录时间
        loginTime: "",
        // 手机手否认证
        mobileVerify: "",
        // 邮箱是否验证
        emailVerify: "",
        // 支付url
        payUrl: "",
        // 邀请人
        referrer: "",
        token: "",
        // vip类型
        vip: "",
        // vip到期时间
        vipTime: "",
        // vip名称
        vipTitle: "",
        // vip套餐信息
        vipInfo: {},
        // 是否是老用户
        isOld: 0,
        // 设备状态
        status: {},
        ver: "",
        luminatiUser: null,
        pay_discount: "0",
        // 促销信息
        discountInfo: {
          desc: "",
          discount: 0,
          coupon: "",
          tip: ""
        }
      },
      code: ""
    };
  },
  mutations: {
    login(state, loginData) {
      const {
        add_time: addTime = "",
        email = "",
        email_verify: emailVerify = "0",
        id = "",
        is_son: isSon = "0",
        is_old: isOld = 0,
        invitecode: inviteCode = "",
        login_time: loginTime = "",
        mobile_verify: mobileVerify = "0",
        pay_url: payUrl = "",
        referrer = "0",
        token = "",
        vip = "0",
        vip_data: vipTitle = "",
        vip_info: vipInfo = {},
        vip_time: vipTime = "",
        status,
        ver,
        lumi_user: luminatiUser = null
      } = loginData;

      state.user = {
        addTime,
        email,
        emailVerify,
        id,
        isSon,
        isOld,
        inviteCode,
        loginTime,
        mobileVerify,
        payUrl,
        referrer,
        token,
        vip,
        vipTitle,
        vipTime,
        vipInfo,
        status,
        ver,
        luminatiUser
      };
      if (loginData.status.discount_info) {
        state.user.discountInfo = loginData.status.discount_info;
      }
      sessionStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    updateLogin(state, data) {
      state.user = data;
      sessionStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    quit(state) {
      state.user = {
        addTime: "",
        email: "",
        id: "",
        inviteCode: "",
        loginTime: "",
        mobileVerify: "",
        payUrl: "",
        referrer: "",
        token: "",
        vip: "",
        vipTime: "",
        vipTitle: "",
        ver: "",
        vipInfo: {},
        status: {},
        isSon: "0",
        isOld: 0,
        luminatiUser: null,
        pay_discount: "0",
        discountInfo: {}
      };
      localStorage.removeItem("user");
      sessionStorage.removeItem("user");
    },
    updateStatus(state, data) {
      state.user.addTime = data.user_info.add_time;
      state.user.email = data.user_info.email;
      state.user.id = data.user_info.id;
      state.user.loginTime = data.login_time;
      state.user.inviteCode = data.user_info.invitecode;
      state.user.status = data;
      state.user.luminatiUser = data.lumi_user;
      state.user.vip = data.user_info.vip;
      state.user.vipTitle = data.user_info.vip_data;
      state.user.vipTime = data.user_info.vip_time;
      state.user.vipInfo = data.user_info.vip_info;
      state.user.token = data.user_info.token;
      state.user.emailVerify = data.user_info.email_verify;
      state.user.isSon = data.user_info.is_son;
      state.user.isOld = data.user_info.is_old;
      state.user.pay_discount = data.pay_discount || "0";
      state.user.ver = data.user_info.ver;
      state.user.discountInfo = data.discount_info;
      if (data.discount_info) {
        state.user.discountInfo = data.discount_info;
      }
      sessionStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    putCode(state, data) {
      state.code = data;
      localStorage.setItem("code", data);
    },
    lumiLogin(state, data) {
      state.user.luminatiUser = data;
      sessionStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("user", JSON.stringify(state.user));
    }
  }
});

export default store;
