<template>
  <div class="loading">
    <div class="bar">
      <div class="move"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>

<style scoped>
.loading {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  border-radius: 8px;
  z-index: 888;
}
.bar {
  height: 4px;
  width: 100%;
  background: #e0e0e0;
  position: absolute;
  top: 0;
  left: 0;
}
.move {
  width: 40%;
  height: 4px;
  background: #1d73e9;
  animation: 0.5s linear 0s infinite move-step;
}
@keyframes move-step {
  from {
    margin-left: -40%;
  }
  to {
    margin-left: 100%;
  }
}
</style>
