<template>
  <div class="reg-success">
    <div class="content">
      <div class="form">
        <header class="header">
          <img src="../../assets/img/logo.png" width="40" alt="logo" />
          <h2>注册成功 - 请激活</h2>
        </header>
        <label class="code-tips">
          请查收<b>{{ mail }}</b
          >邮件并点击激活链接，激活账号后可登录使用。
        </label>
        <label class="code-tips">
          如果没收到验证码，请检查您的垃圾邮箱，或
          <a @click="sendActiveMailAgain">
            重新发送
          </a>
          <span v-if="timer > 0" class="timer">{{ timer }} 秒后可重发</span>
        </label>
        <div class="submit">
          已经激活? 请登录
          <p>
            <z-button @click="$router.push('/console')">登录</z-button>
          </p>
        </div>
      </div>
      <div class="pic">
        <img src="../../assets/img/reg-robot.png" width="244" alt="robot" />
      </div>
    </div>
  </div>
</template>

<script>
import ZButton from "../../components/ZButton.vue";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { message } from "ant-design-vue";
import { reg } from "@/utils/api";
export default {
  name: "RegSuccess",
  components: { ZButton },
  props: {
    mail: String
  },
  setup(props) {
    const router = useRouter();
    const timer = ref(0);
    // 检查邮箱
    function checkMail() {
      const mailRule = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      console.log(mailRule.test(props.mail));
      if (mailRule.test(props.mail)) {
        return true;
      } else {
        message.error("参数不合法");
        router.replace("/console");
      }
    }
    // 重新发送
    async function sendActiveMailAgain() {
      if (timer.value > 0) {
        message.warn(`发送邮件过于频繁, ${timer.value}秒后重试...`);
        return false;
      }
      await reg.sendActiveLinkByEmail(props.mail).then(res => {
        if (res.data.code === 0) {
          message.success(res.data.msg);
          countDown(60);
        } else {
          message.error(res.data.msg);
        }
      });
    }
    async function countDown(seconds) {
      timer.value = 60;
      for (let i = 0; i <= seconds; i++) {
        await sleep(1020);
        timer.value--;
      }
    }
    async function sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    }
    onMounted(() => {
      checkMail();
      countDown(60);
    });
    return {
      sendActiveMailAgain,
      timer
    };
  }
};
</script>

<style scoped>
.content {
  display: flex;
}
.form {
  text-align: left;
  padding-right: 40px;
}
.header h2 {
  font-size: 28px;
  font-weight: 400;
}
.code-tips {
  display: block;
  margin-bottom: 10px;
}
.timer {
  font-size: 12px;
  color: #7f868a;
}
</style>
