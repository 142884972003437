<template>
  <div class="bg">
    <div class="content">
      <h4 class="icon">
        <SoundTwoTone />
        {{ tips || ": ( 出现了一些问题,请联系客服处理.." }}
      </h4>
      <div class="wechat">
        <div class="qrcode one">
          <img
            src="https://www.chaojivps.com/img/kefu.png"
            alt="wechat"
            width="200"
          />
          <h4>夜间/周末客服(微信)</h4>
          <p>工作日18:00-24:00,周末全天</p>
        </div>
        <div class="qrcode">
          <img
            src="https://www.chaojivps.com/img/kefu1.png"
            alt="wechat"
            width="200"
          />
          <h4>工作日客服(微信)</h4>
          <p>工作日09:00-18:00</p>
        </div>
      </div>
      <div class="submit">
        <a-button type="primary" @click="$emit('cancel')">知道了</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { SoundTwoTone } from "@ant-design/icons-vue";
export default {
  name: "Service",
  props: { tips: String },
  emits: ["cancel"],
  components: { SoundTwoTone }
};
</script>

<style scoped>
.content {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(64, 64, 67, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #5f6368;
  padding: 20px 24px 20px 24px;
  text-align: left;
}
.wechat {
  padding: 10px 0;
  display: flex;
}
.one {
  margin-right: 20px;
}
.qrcode h4 {
  font-size: 14px;
  margin: 6px 0 0 0;
  padding: 0;
}
.qrcode {
  font-size: 12px;
}
.icon {
  color: #202124;
  border-bottom: 1px #f0f0f0 solid;
  padding-bottom: 10px;
}
.submit {
  border-top: 1px #f0f0f0 solid;
  padding-top: 10px;
  text-align: center;
}
</style>
