<template>
  <console-header></console-header>
  <div class="content">
    <img src="../assets/img/404.png" width="800" alt="404" />
  </div>
  <div class="info">
    <h1>404</h1>
    <p>您访问的内容不存在或已删除</p>
    <z-button @click="$router.push('/')">返回控制台</z-button>
  </div>
</template>

<script>
import ConsoleHeader from "./console/ConsoleHeader.vue";
import ZButton from "../components/ZButton.vue";

export default {
  name: "NotFound",
  components: { ZButton, ConsoleHeader },
  data() {
    return {
      backTime: 10
    };
  }
};
</script>

<style scoped>
.content {
  text-align: center;
}

.info {
  text-align: center;
}

.info h1 {
  font-size: 36px;
  font-weight: 400;
}
</style>
