<template>
  <header class="header">
    <div class="header-content">
      <div class="logo">
        <img src="../../assets/img/logo-across.png" width="134" alt="logo" />
      </div>
      <a href="https://www.chaojivps.com/" class="back-link">
        返回首页
      </a>
      <div class="user-info">
        <b>{{ $store.state.user.email }}</b>
        <a-button
          type="link"
          @click="mailCheckShow = true"
          v-if="$store.state.user.emailVerify !== '1'"
        >
          验证邮箱
        </a-button>
        <a-button type="link" @click="changePassword()">修改密码</a-button>
        <span class="quit" @click="quit" v-if="$store.state.user.token">
          退出
        </span>
      </div>
    </div>
  </header>
  <change-password
    v-if="changePasswordShow"
    v-on:close="changePasswordShow = false"
    v-on:success="popMessage"
  >
  </change-password>
  <div class="mail-container" v-if="mailCheckShow">
    <div class="mail" ref="target">
      <reg-code
        v-on:close="mailCheckShow = false"
        v-on:success="mailCheckSuccess"
      ></reg-code>
    </div>
  </div>
</template>

<script>
import ChangePassword from "@/pages/console/ChangePassword";
import RegCode from "@/pages/reg/RegCode";
import { message } from "ant-design-vue";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
export default {
  name: "ConsoleHeader",
  data() {
    return {
      changePasswordShow: false
    };
  },
  setup() {
    const mailCheckShow = ref(false);
    const target = ref(null);
    onClickOutside(target, () => (mailCheckShow.value = false));
    return { target, mailCheckShow };
  },
  components: { RegCode, ChangePassword },
  methods: {
    quit() {
      this.$store.commit("quit");
      this.$router.push("/");
    },
    changePassword() {
      this.changePasswordShow = true;
    },
    mailCheckSuccess() {
      this.$router.go(0);
    },
    popMessage() {
      message.success("修改成功,请重新登录");
      setTimeout(() => {
        this.$store.commit("quit");
        this.$router.push("/");
      }, 500);
    }
  }
};
</script>

<style scoped>
.header {
  height: 64px;
  line-height: 64px;
  color: #80868b;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 400;
}
.back-link {
  padding-left: 20px;
  color: #7f868a;
  text-decoration: none;
}
.back-link:hover {
  color: #1a73e8;
}
.header-content {
  display: flex;
  align-items: center;
  padding: 0 100px;
}

.logo {
  display: flex;
  align-items: center;
}
.user-info {
  flex: 1;
  text-align: right;
}
.user-info b {
  padding-right: 10px;
  color: #202124;
  font-weight: 400;
}
.quit {
  cursor: pointer;
}
.mail-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}
.mail {
  background: #ffffff;
  border-radius: 8px;
  padding: 40px;
  min-height: 450px;
  max-width: 700px;
}
@media only screen and (max-width: 1200px) {
  .header-content {
    padding: 0 20px;
  }
}
</style>
