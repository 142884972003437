<template>
  <console-header></console-header>
  <div class="content">
    <h1>超级VPS用户服务协议</h1>
    <p>
      超级VPS保留在不提前通知用户的情况下，对本协议条款或业务规则做任何修改或补充的权利，修改或补充后的协议条款或业务规则将在本软件公布，并自公布时立即生效。
    </p>
    <p>
      如果您代表某个机构而非您个人注册、登录和使用本软件或服务，则您将被认为获得充分授权代表该机构同意本协议条款以及本协议条款不时的修改和补充。
    </p>
    <h2>1. 帐户条款</h2>

    您必须具有完全民事行为能力和民事权利能力方可使用本服务。
    为了能正常访问本软件和使用本服务，您必须依照本软件注册提示填写准确的电子邮箱、手机号以及其他任何在注册时要求的信息，并须确保在上述注册信息发生变化时及时更新信息及确保更新信息的有效性和合法性。
    若您提供任何错误、虚假、过时或不完整的资料，或者超级VPS依其独立判断怀疑资料为错误、虚假、过时或不完整，超级VPS有权限制、封禁您在本软件的帐号，及暂停或终止提供服务的部分或全部功能。在此情况下，超级VPS将不向您承担任何责任。
    您的帐户只可以由您本人登录及使用，多人共享帐号是被禁止的。不过您可以根据购买的套餐内容，在本协议及相关业务规则允许的范围内，添加多个子帐户。您知悉并同意，您与子账户使用人之间或各子账户使用人之间的任何纠纷将由您及或相关方自行解决及承担责任，与超级VPS无关；超级VPS将不会介入该等纠纷的处理，亦不会就此向您或任何相关方承担任何责任。
    您有责任维护您的帐户和密码的安全性。超级VPS不会因您未能遵守这一安全保障义务而对因此导致的任何损失或损害承担任何责任。
    您仅能为自身内部运营之目的使用本服务，您不得出于任何非法或未经授权的目的使用本服务，包括但不限于不得以营利或非营利目的以任何方式向任何第三方提供本服务或其任何部分，不得利用本服务从事任何违法或侵犯第三方知识产权或其他合法权益的活动。
    您在使用本服务过程中应遵守所有可适用的法律法规及本协议、业务规则的规定，不得发生任何违法、违反本协议或侵犯第三方合法权益的行为；您应对您帐户项下的全部行为承担相应法律责任。

    <h2>2. 支付，退款，升级/降级，服务到期 条款</h2>

    所有的支付必须通过一个有效的微信/支付宝等完成。
    本服务收费方式为预付费、按月/年扣费。非因超级VPS原因导致本服务在服务期限内中止或终止的，已支付费用不予退还。超级VPS不会为未使用完的月费、年费、升级/降级套餐退款，也不会为服务计划中尚未使用的剩余期限退款。为了保证公平地对待每一个用户，不存在例外情况。
    所有费用均为不含税价格，您应负责支付及自行承担所有及任何税金。
    服务到期或降级后可能会导致您帐户项下的内容、功能、特征或能力的丧失。您明确知悉并同意上述变化及风险，超级VPS对因此发生的任何损失不承担任何责任。

    <h2>3. 服务解除和终止</h2>

    如果您在缴足费的服务期届满之前停止使用超级VPS浏览器相关服务，您将不会被收取后续费用，剩余服务期的已缴付费用不予退还。
    如您违反本协议任何条款、任何业务规则或可适用的法律法规，或发生任何第三方侵权投诉，超级VPS将有权限制、封禁您在本软件的帐号，及暂停或终止提供服务的部分或全部功能，并有权要求您赔偿因此给超级VPS造成的全部损失。除此之外，超级VPS将有权因任何原因，在任何时间自行决定暂停或终止您的帐号，或拒绝提供任何所有现在或将来的服务。如果因超级VPS原因终止向您提供某项收费服务，超级VPS的义务仅在于向您返还您尚未使用的服务期对应的部分费用。
    终止本服务将导致终止及删除您的帐户及帐户中的所有内容及信息。
    超级VPS保留无解释的，对任何人因任何原因在任何时间拒绝服务的权利。

    <h2>4 服务和价格的修订</h2>

    超级VPS保留随时变更、中止或终止部分或全部服务的权利，并保留根据实际情况随时调整超级VPS提供的服务种类、形式、费用标准的权利。
    所有服务的价格，包括但不限于包月/包年计划收费服务的改变，都会收到来自超级VPS的通知。超级VPS可通过推送通知、邮件、官网公告或改变超级VPS软件或服务本身等方式对服务的变更、终止或终止及服务价格及其变更进行通知。您继续访问本软件或使用本服务，即视为您接受变更后的服务及价格。
    因服务变更、中止或终止，或服务价格变更对您与第三方之间的任何法律关系产生任何影响的，超级VPS将不承担任何责任。

    <h2>5. 用户信息内容及合法性规则</h2>

    您应确保使用本软件及服务过程中所上传、存储、访问的任何内容的合法性，确保内容不违反任何法律法规的规定，不违反公序良俗，不侵犯任何第三方的知识产权或其他合法权益。任何与您上传、存储、访问的内容有关的知识产权纠纷及其他纠纷将由您与相关方自行解决并承担全部责任，与超级VPS无关，超级VPS因此遭受任何损失的，您同意全额赔偿。本平台如果收到关于内容合法性或权属的任何投诉或异议，将有权不经通知用户删除相关内容。
    超级VPS不预先筛选内容；如任何第三方对您的任何内容提出任何异议，或超级VPS认为您的任何内容涉嫌违法、违反本协议、侵犯第三方权益、存在任何不当，或可能影响其他用户或第三方权益，超级VPS有自行决定拒绝或删除任何内容的权利（而非义务），且不承担任何责任。您必须为第三方因认为您的内容违法、违反本协议，侵犯第三方的权益（包括知识产权）或与您有关的其他原因而对超级VPS提出的任何索赔、主张、请求或诉讼，赔偿超级VPS因此发生的全部费用及遭受的全部损失。
    超级VPS有权，但没有义务，删除在超级VPS看来包含非法的、攻击、威胁、中伤、诽谤、淫秽、色情或任何其他不良信息的内容和帐户，或删除任何违反法律规定、本协议约定、对超级VPS任何用户、员工或第三方造成侵害或损害，或超级VPS认为可能存在任何其他不良影响的内容和帐户。你不得传播任何蠕虫或病毒或具有破坏性的任何代码。
    您通过本软件及服务使用其他用户或第三方提供的内容时，应事先取得内容提供方的许可或同意；您未能遵守上述使用规则所引发的责任及第三方损失将由您自行承担，与超级VPS无关，给超级VPS造成损失的，还须赔偿超级VPS的全部损失。

    <h2>6. 超级VPS版权</h2>

    本软件及本服务的外观设计、应用程序、源代码、商标、标示图案、界面设计、应用程序编程接口及相关著作权，以及与超级VPS提供的服务有关的任何著作权及其他知识产权均归超级VPS所有，其它本协议中未经提及的权利亦由超级VPS保留。未经超级VPS事先书面许可，您不能复制、拷贝、模仿或者使用上述知识产权的任何部分，不得复制、拷贝或使用本服务的任何部分或视觉设计元素和理念。未经超级VPS事先书面同意，您不得为任何目的自行实施、利用、转让或许可他人实施上述任何知识产权或其任何部分。

    <h2>7. 隐私条款</h2>

    您同意本软件及资讯为提供本服务及优化服务等相关目的收集、保留、存储您的用户信息及一些用户选项（例如隐私设置）。您同意超级VPS收集和使用有关您所使用服务的技术性或诊断性信息。收集到的这些信息将用于改进本软件或本协议服务的内容和技术，提升服务品质。
    超级VPS将不会在不通知的情况下透露用户的个人信息，除非根据法律法规的规定、国家行政、司法机关的要求予以披露，或在超级VPS为保护超级VPS、超级VPS其他用户或任何第三方的合法权益认为必要时进行披露，或为提供你所要求的产品和服务，而必须和第三方分享您的个人信息。
    超级VPS会与其他网站链接，但不对其他网站的隐私政策及内容负责。
    其他隐私政策请见本软件不时公布及修订的隐私条款。

    <h2>8. 有限保证及服务免责</h2>

    您同意超级VPS只基于“现况”及“现有”基础提供服务。超级VPS保证提供的本服务与相应的服务介绍及超级VPS不时公布的服务承诺相符。除上述保证外，超级VPS不对本软件及本服务作出任何其他明示或暗示的保证，包括但不限于不保证：
    （1）本服务将满足您的具体要求或需要；
    （2）本服务将不间断，及时、安全或无差错；
    （3）使用本服务所获得的结果准确或可靠； （4）本服务的任何错误都将被纠正。
    您知悉及同意上述服务状况，并同意自行承担使用本服务的全部风险。
    您知悉及同意，超级VPS不对您可能通过超级VPS接触的到任何内容负责，您同意自担风险使用本服务。
    基于互联网的开放性属性，您知悉将图片、信息或任何资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，您必须充分意识到此类风险的存在；您明确同意使用本软件及本协议服务所存在的风险将完全由您自己承担；因第三方侵权所产生的责任和损失将由您向第三方追索，超级VPS无须就此向您承担任何责任。
    超级VPS只向正在付费中的帐户持有人提供技术支持。支持仅提供中/文版本。
    您知悉并理解本服务使用第三方供应商或托管合作伙伴提供必要的硬件、软件、网络、存储以及相关的技术来运行服务，您同意遵守该等第三方的任何业务规则，并同意因该等第三方硬件、软件、网络、存储以及相关技术所引发的任何故障、意外、责任或风险将由您与该等第三方解决，超级VPS不就此作出任何承诺或保证，亦不承担任何责任。
    您不得以任何方式明示或暗示您或非超级VPS所有及运营的任何软件或服务与超级VPS有任何关联。
    不论在何种情况下，超级VPS均不对由于连接故障，通讯或其他系统的故障，黑客攻击、病毒、电力故障，罢工，劳动争议，暴乱，起义，骚乱，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令，第三方的不作为或任何超级VPS不能合理控制的原因而造成的本平台不能访问、信息及数据的延误、停滞或错误，不能提供或延迟提供本服务而承担责任。
    超级VPS将采取现有技术水平项下合理可行的措施努力保护用户账号和数据隐私，但超级VPS不能确保用户的全部私人通讯及其他资料不会通过本隐私规则中未列明的途径泄露出去，您知悉并同意承担上述风险，并同意超级VPS无须就此承担任何责任。
    不论是否可以预见，不论是源于何种形式的行为或不作为，超级VPS不对因任何原因造成的任何特别的、间接的、惩罚性的、突发性的或其他任何损害（包括但不限于利润或利息的损失、因您隐私泄漏、您个人资料等的泄露、被盗用等，因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他原因造成的金钱上的损失或其他损失等）承担责任。

    <h2>9. 其他规定</h2>

    您同意未经超级VPS的明确书面许可，不复制、出售、转售或以其他方式向第三方提供本服务或其任何部分。
    本协议项下超级VPS向您发出的所有通知，超级VPS可按以下任一方式发出：（1）通过推送通知、邮件、官网公告等形式发出；（2）向您注册时或者此后变更您信息时向超级VPS提供的电子邮箱发送电子邮件（发出即视为送达）。
    超级VPS未能或延迟行使本协议规定的任何权利、权力或特权不应视为放弃行使，且任何单一或部分权利、权力或特权的行使不应排除权利、权力或特权的任何其他或进一步的行使或本协议项下任何其他权利、权力或特权的行使。
    本协议构成您与超级VPS之间的全部协议，并取代您与超级VPS之间的任何先前的协议（包括但不限于本协议的任何以前的版本）。
    本协议之解释与适用，均应依照中华人民共和国法律进行。与本协议有关的争议，由超级VPS所在地有管辖权的法院管辖。
    本协议最终解释权归超级VPS所有。
  </div>
</template>

<script>
import ConsoleHeader from "./console/ConsoleHeader.vue";

export default {
  name: "Agreement",
  components: { ConsoleHeader }
};
</script>

<style scoped>
.content {
  color: #5f6368;
  padding: 20px 100px;
  max-width: 1200px;
  margin: 0 auto;
  line-height: 32px;
  font-size: 16px;
}
h1 {
  font-size: 36px;
  font-weight: 400;
}
h2 {
  font-size: 30px;
  font-weight: 400;
}
</style>
