<template>
  <div class="bg">
    <div class="pay">
      <loading v-show="loadingShow"></loading>
      <div class="pay-form" v-show="payFormShow">
        <header class="pay-header">
          <div class="logo">
            <img src="../assets/img/logo-big.png" width="80" alt="logo" />
          </div>
          <div class="account">
            <b>{{ $store.state.user.email }}</b>
            <span class="change-account" @click="changeAccount">更换账号</span>
          </div>
        </header>
        <div class="pay-content">
          <div class="title">
            <b>订购超级VPS</b>
            <h1>超级VPS - {{ payData.name }}</h1>
            <p>{{ payData.info }}</p>
          </div>
          <div class="step one">
            <div class="step-title">
              <span class="point">1</span>
              <b>选择您的结算周期</b>
            </div>
            <div class="month-list">
              <ul>
                <li
                  class="month"
                  @click="
                    monthChange(
                      payData.recommend.id,
                      calcPrice(payData.recommend.price) >
                        payData.recommend.price_discount
                        ? calcPrice(payData.recommend.price)
                        : payData.recommend.price_discount
                    )
                  "
                  :class="{ active: payData.recommend.id === monthActive }"
                >
                  <div class="info">
                    <h2>{{ payData.recommend.title }}</h2>
                    <p>可重复购买，效果叠加</p>
                  </div>
                  <div class="price">
                    <h1>
                      <span>￥</span>
                      {{
                        calcPrice(payData.recommend.price) >
                        payData.recommend.price_discount
                          ? calcPrice(payData.recommend.price)
                          : payData.recommend.price_discount
                      }}
                    </h1>
                    <span
                      v-if="
                        $store.state.user.discountInfo &&
                          $store.state.user.discountInfo !== ''
                      "
                      class="discount"
                    >
                      活动节省:
                      {{
                        payData.recommend.price -
                          calcPrice(payData.recommend.price)
                      }}元
                    </span>
                  </div>
                </li>
                <li
                  class="month"
                  v-for="item in payData.priceList"
                  :key="item.id"
                  @click="
                    monthChange(
                      item.id,
                      calcPrice(payData.recommend.price) >
                        payData.recommend.price_discount
                        ? calcPrice(payData.recommend.price)
                        : payData.recommend.price_discount
                    )
                  "
                  :class="{ active: item.id === monthActive }"
                >
                  <div class="info">
                    <h2>{{ item.title }}</h2>
                    <p>可重复购买，效果叠加</p>
                  </div>
                  <div class="price">
                    <h1>
                      <span>￥</span>
                      {{
                        calcPrice(item.price) > item.price_discount
                          ? item.price_discount
                          : calcPrice(item.price)
                      }}
                    </h1>
                    <span
                      v-if="
                        $store.state.user.discountInfo &&
                          $store.state.user.discountInfo.desc
                      "
                      class="discount"
                    >
                      活动节省
                      {{
                        calcPrice(item.price) > item.price_discount
                          ? item.price - item.price_discount
                          : item.price - calcPrice(item.price)
                      }}元
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="step two">
            <div class="step-title">
              <span class="point">2</span>
              <b>选择您的付款方式</b>
            </div>
            <div class="pay-list">
              <ul>
                <li
                  :class="{ active: payMode.wechat.id === payActive }"
                  @click="changePay(payMode.wechat.id)"
                >
                  {{ payMode.wechat.name }}
                </li>
                <li
                  :class="{ active: payMode.alipay.id === payActive }"
                  @click="changePay(payMode.alipay.id)"
                >
                  {{ payMode.alipay.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="submit">
            <button class="cancel-button" @click="$emit('cancel')">取消</button>
            <z-button @click="tipsNoSupport">下一步</z-button>
          </div>
          <!--          <div class="submit">-->
          <!--            <button class="cancel-button" @click="$emit('cancel')">取消</button>-->
          <!--            <z-button @click="payGo">下一步</z-button>-->
          <!--          </div>-->
        </div>
      </div>
      <!--alipay form-->
      <div v-html="htmlData"></div>
      <!--二维码扫描-->
      <div class="pay-code" v-if="payCodeShow">
        <h1>使用微信扫描二维码完成支付</h1>
        <h2>请在 {{ payCode.time }} 秒内完成支付</h2>
        <div class="pay-code-img">
          <img
            :src="
              'https://admin.chaojivps.com/api/qrcode/show?url=' +
                payCode.payPicUrl
            "
            :alt="payCode.payOrderNumber"
          />
        </div>
        <div class="cancel-pay">
          <button
            class="cancel-button cancel-pay-button"
            @click="cancelPayCode"
          >
            取消
          </button>
        </div>
      </div>
      <div class="pay-success" v-if="paySuccessShow">
        <img src="../assets/img/success.png" width="100" alt="支付成功" />
        <h1>支付成功</h1>
        <div class="cancel-pay">
          <z-button @click="successGo">确定</z-button>
        </div>
      </div>
    </div>
  </div>
  <!--弹出框-->
  <popup v-show="popShowInfo" @confirm="payGo">
    <template v-slot:title>
      您正在尝试升级套餐
    </template>
    <template v-slot:describe>
      超级VPS套餐升级会通过计算您所升级套餐与现有套餐剩余时间的差价 +
      新采购套餐价格来计算，如有不清楚请联系超级VPS客服
    </template>
  </popup>
</template>

<script>
import Popup from "../components/Popup.vue";
import ZButton from "./ZButton.vue";
import Loading from "./Loading.vue";
import { buy } from "@/utils/api";
import { useStore } from "vuex";
import { message, Modal } from "ant-design-vue";
import { h } from "vue";

export default {
  emits: ["cancel", "pop"],
  props: {
    payData: {
      type: Object
    },
    defaultSelected: {
      type: String
    },
    payMode: {
      type: Object
    }
  },
  setup() {
    const store = useStore();
    // 计算价格
    function calcPrice(price) {
      const discount = Number(
        store.state.user.discountInfo
          ? store.state.user.discountInfo.discount
          : 0
      );
      if (discount !== 0) {
        return Math.floor((price * (100 - discount)) / 100);
      } else {
        return calcCoupon(price);
      }
    }
    // 计算满减
    function calcCoupon(price) {
      const coupon = store.state.user.discountInfo
        ? store.state.user.discountInfo.coupon.split("|")
        : [];
      let discountMax = 0;
      if (coupon.length > 0) {
        coupon.forEach(item => {
          const limitPrice = item.split("-");
          if (Number(limitPrice[0]) <= price) {
            if (Number(limitPrice[1]) > discountMax) {
              discountMax = Number(limitPrice[1]);
            }
          }
        });
      }
      return price - discountMax;
    }
    function tipsNoSupport() {
      message.info("旧版套餐已不再支持");
    }
    return {
      calcPrice,
      tipsNoSupport
    };
  },
  data() {
    return {
      popShowInfo: false,
      monthActive: this.defaultSelected,
      payActive: this.payMode.wechat.id,
      loadingShow: false,
      payFormShow: true,
      payCodeShow: false,
      payCode: {
        price: "",
        payOrderNumber: "",
        payPicUrl: "",
        time: 300,
        cancel: false
      },
      popShow: false,
      paySuccessShow: false,
      formData: "",
      htmlData: ""
    };
  },
  mounted() {},
  name: "Pay",
  components: { Loading, ZButton, Popup },
  methods: {
    monthChange(code, price) {
      this.monthActive = code;
      this.payCode.price = price;
      // this.active.price = price;
      // this.active.title = title;
    },
    changePay(code) {
      this.payActive = code;
    },
    async payGo() {
      this.popShowInfo = false;
      // 打开loading
      this.loadingShow = true;

      //微信支付
      if (this.payActive === this.payMode.wechat.id) {
        const res = await buy
          .buy(this.monthActive, this.payActive)
          .then(res => res.data);

        if (res.code === 0) {
          this.payCode.payOrderNumber = res.data.order_no;
          this.payCode.payPicUrl = encodeURIComponent(res.data.payurl);
          // 关闭loading
          this.loadingShow = false;
          // 关闭表单
          this.payFormShow = false;
          // 打开扫码支付
          this.payCodeShow = true;
          // 开始检测
          const payState = await this.checkOrder(
            this.payCode.payOrderNumber,
            1000,
            300
          );
          if (payState) {
            this.payCodeShow = false;
            this.paySuccessShow = true;
          } else {
            this.$emit("cancel");
            this.payCode.cancel = true;
          }
        } else if (res.code === -1) {
          this.loadingShow = false;
          this.$emit("cancel");
          Modal.warn({
            title: res.msg,
            content: h("div", {}, [
              h("img", {
                style: { width: "300px", verticalAlign: "middle" },
                src: "https://www.chaojivps.com/img/kefu.png"
              })
            ]),
            onOk() {}
          });
        } else {
          this.loadingShow = false;
          this.$emit("pop");
          this.$emit("cancel");
        }
      } else {
        //:TODO 支付宝支付
        const res = await buy
          .buy(this.monthActive, this.payActive)
          .then(res => res.data);

        if (res.code === 0) {
          this.htmlData = res.data.html;
          this.$nextTick(() => {
            document.getElementById("alipaysubmit").submit();
          }).then();
        } else if (res.code === -1) {
          this.loadingShow = false;
          this.$emit("pop");
          this.$emit("cancel");
        } else {
          this.loadingShow = false;
          this.$emit("pop");
          this.$emit("cancel");
        }
      }
    },
    async checkOrder(orderNumber, delay, times) {
      this.payCode.cancel = false;
      this.payCode.time = times + 1;
      for (let i = 1; i <= times; i++) {
        if (this.payCode.cancel) {
          return false;
        }
        const code = await buy.checkOrder(orderNumber).then(res => {
          return res.data.code;
        });

        if (code === 0) {
          return true;
        } else {
          this.payCode.time--;
          await this.sleep(delay);
        }
      }
      this.$emit("cancel");
    },
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    cancelPayCode() {
      this.$emit("cancel");
      this.payCode.cancel = true;
    },
    successGo() {
      this.$emit("cancel");
    },
    changeAccount() {
      this.$store.commit("quit");
      this.$router.push("/");
    },
    testAlipay() {
      document.getElementById("alipaysubmit").submit();
    }
  },
  beforeUnmount() {
    this.payCode.cancel = true;
  }

  //:TODO 支付成功界面图标
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}

.pay {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(64, 64, 67, 0.3);
  max-width: 750px;
  min-width: 600px;
  min-height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.pay-header {
  padding: 5px 40px;
  display: flex;
  align-items: center;
}

.logo {
  margin-top: -60px;
  flex: 1;
  z-index: 999;
}

.account {
  font-size: 14px;
  font-weight: 400;
}

.account b {
  color: #1a73e8;
}

.account span {
  color: #7f868a;
  padding-left: 10px;
  font-size: 12px;
}

.pay-content {
  padding: 5px 40px;
}

.title b {
  font-size: 14px;
  color: #7f868a;
  font-weight: 400;
}

.title h1 {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
  padding: 10px 0;
  color: #3d4043;
}

.title p {
  font-size: 14px;
  margin: 0;
  padding: 5px 0;
}

.step {
  margin-top: 10px;
}

.step-title {
  font-size: 14px;
}

.step-title span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #bcc0c4;
  color: #ffffff;
  text-align: center;
  margin-right: 10px;
}

.month-list ul {
  margin: 0;
  list-style: none;
  padding: 0 30px;
}

.month-list ul li.active {
  background-color: #1a73e8;
  border: 1px #1a73e8 solid;
  color: #ffffff !important;
}

.month-list ul li.active p {
  color: #ffffff;
}

.month-list ul li.active .info h2 {
  color: #ffffff;
}

.month-list ul li.active .price h1 {
  color: #ffffff;
}

.month-list ul li.active .price h1 b {
  color: #ffffff;
}

.month {
  width: 300px;
  display: flex;
  border: 1px #dadce0 solid;
  border-radius: 2px;
  padding: 15px 20px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.month:hover {
  background-color: #e8f0fe;
  border: 1px #e8f0fe solid;
}

.month .info {
  padding-right: 20px;
}

.month .info h2 {
  font-size: 18px;
  margin: 0;
  font-weight: 400;
}

.month .info h2 span {
  font-size: 12px;
}

.month .info p {
  font-size: 12px;
  margin: 0;
  padding-top: 5px;
  color: #81868a;
  font-weight: 400;
}

.month .price {
}

.month .price h1 {
  margin: 0;
  color: #1a73e8;
  font-size: 21px;
  font-weight: 400;
}

.month .price h1 span {
  font-size: 14px;
}

.month .price h1 b {
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  color: #81868a;
}

.pay-list ul {
  display: flex;
  margin: 0;
  padding: 10px 0 0 30px;
}

.pay-list ul li {
  list-style: none;
  font-size: 14px;
  border: 1px #dadce0 solid;
  border-radius: 2px;
  padding: 8px 30px;
  margin-right: 20px;
  font-weight: 400;
  cursor: pointer;
}

.pay-list ul li:hover {
  border: 1px #e8f0fe solid;
  background-color: #e8f0fe;
}

.pay-list ul li.active {
  background-color: #1a73e8;
  color: #ffffff;
  border: none;
}

.submit {
  padding: 10px 0 5px 0;
  margin-top: 20px;
  border-top: 1px #dadce0 solid;
  text-align: right;
}

.cancel-button {
  border: none;
  padding: 8px 24px;
  border-radius: 2px;
  font-size: 14px;
  color: #7f868a;
  margin-right: 10px;
  background: #ffffff;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #e8f0fe;
  color: #1a73e8;
}

.pay-code {
  text-align: center;
  padding: 40px 0;
}

.pay-code h1 {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}

.pay-code h2 {
  font-size: 16px;
  font-weight: 400;
}

.cancel-pay-button {
  background: #f1f3f4;
  font-size: 16px;
  padding: 8px 40px;
}
.pay-code-img {
  padding: 25px;
}
.pay-code-img img {
  width: 300px;
  height: 300px;
}
.pay-success {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pay-success h1 {
  font-size: 28px;
  font-weight: 400;
}
.change-account {
  cursor: pointer;
}
.discount {
  font-size: 12px;
}
</style>
