<template>
  <a-config-provider :locale="zhCN">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
export default {
  name: "App",
  data() {
    return {
      email: ""
    };
  },
  setup() {
    return {
      zhCN
    };
  }
};
</script>
<style>
#app {
  height: 100%;
}
.ant-result-content {
  background: none;
}
.share-title {
  font-size: 16px;
  font-weight: 500;
}
.ant-btn {
  height: 36px;
  padding: 0 24px;
}
.bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}
</style>
