<template>
  <loading v-if="loadingShow"></loading>
  <header class="header">
    <h3>邀请</h3>
    <p>
      使用您的邀请码（或邀请链接）邀请用户，分享您对候鸟的喜爱，您会获得最高20%的永久佣金。
    </p>
    <div class="invite-number">
      <label class="number">
        邀请码： <b>{{ $store.state.user.inviteCode }}</b>
      </label>
      <label class="invite-link">
        邀请链接：
        <input
          id="link"
          class="link"
          type="text"
          :value="
            'https://www.chaojivps.com/aff/?code=' +
              $store.state.user.inviteCode
          "
          readonly
        />
      </label>
      <z-button @click="copyLink" class="copy-button"
        >复制邀请链接到剪贴板</z-button
      >
    </div>
  </header>
  <div class="content">
    <a-table
      :columns="columns"
      :data-source="inviteList"
      :row-key="record => record.id"
      :pagination="{ pageSize: 20 }"
    ></a-table>
    <div class="rules">
      <h4>邀请规则：</h4>
      <p>
        <b>1、</b
        >用户邀请的新用户注册并购买套餐后，则将新用户订购套餐的10-20%奖励给推荐用户
      </p>

      <p>
        <b>2、</b
        >邀请用户所获的佣金满15天方可提现，提现金额必须大于100元，每次提现需把返利账户里的金额全部一次性提走，每个月只能申请提现一次；
      </p>

      <p>
        <b>3、</b
        >佣金账户余额不能直接用来支付候鸟系统，需提现至账户余额才可以用来支付；
      </p>

      <p>
        <b>4、</b
        >提现操作后，财务会在7日内进行返款，节假日顺延，造成的不便敬请谅解！
      </p>

      <h4>阶梯佣金规则：</h4>

      <p>邀请5个（包括5个）付费用户佣金10%；</p>
      <p>邀请6-10个（包括10个）付费用户佣金15%；</p>
      <p>邀请10个以上付费用户佣金20%</p>

      <i>本公司对以上规则拥有最终解释权</i>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/Loading.vue";
import { user } from "@/utils/api";
import ZButton from "../../components/ZButton.vue";

export default {
  name: "ConsoleInvite",
  data() {
    return {
      loadingShow: false,
      inviteList: [],
      columns: [
        {
          title: "用户ID",
          dataIndex: "id"
        },
        {
          title: "用户名",
          dataIndex: "email"
        },
        {
          title: "注册时间",
          dataIndex: "add_time"
        },
        {
          title: "消费金额",
          dataIndex: "money"
        }
      ]
    };
  },
  methods: {
    async getInviteList() {
      this.loadingShow = true;
      this.inviteList = await user.getInviteList().then(res => {
        if (res.data.code === 0) {
          return res.data.data;
        } else {
          return [];
        }
      });
      this.loadingShow = false;
    },
    copyLink() {
      const link = document.getElementById("link");
      link.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
    }
  },
  mounted() {
    this.getInviteList();
  },
  components: { Loading, ZButton }
};
</script>

<style scoped>
h1 {
  font-size: 28px;
  font-weight: 400;
}

.header h3 {
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0;
}

.header p {
  margin: 0;
}

.content {
  padding-top: 40px;
}

.content .list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.module {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px #eceff1 solid;
}

.module span {
  display: block;
  width: 20%;
}

.module span b {
  padding-left: 10px;
  font-weight: 400;
}

.module .id {
  width: 20%;
}

.title {
  font-size: 14px;
  background: #f4f7f7;
  color: #676a6e;
}

.tips {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
  color: #676a6e;
}

.label-block {
  display: block;
}
.link {
  font-size: 14px;
  padding: 10px 20px;
  width: 400px;
  border: 1px #eceff1 solid;
  border-radius: 2px;
}
.number {
  font-size: 14px;
  padding-right: 20px;
}
.invite-number {
  padding-top: 20px;
}
.invite-link {
  font-size: 14px;
}
.copy-button {
  margin-left: 10px;
}
.rules {
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 14px;
  background: #f8f9fa;
}
.rules h4 {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
}
</style>
