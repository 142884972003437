<template>
  <aside class="crumbs">
    <router-link to="/console/account">子账户</router-link> >>
    <span>{{ $route.query.email }}</span>
  </aside>
  <header class="header">
    <div class="title">
      <h3>{{ $route.query.email }}</h3>
      <p>环境列表</p>
    </div>
    <a-button @click="refreshList" type="primary" :loading="refreshLoading">
      刷新列表
    </a-button>
  </header>

  <a-table
    class="session-table"
    :columns="columns"
    :row-key="record => record.id"
    :data-source="sessionList"
    :loading="loading"
  ></a-table>
</template>

<script>
import { ref, onMounted } from "vue";
import { user, client } from "@/utils/api";

export default {
  name: "ConsoleAccountSonSessions",
  props: ["id", "email"],
  setup(props) {
    const columns = ref([
      {
        title: "ID",
        dataIndex: "id"
      },
      {
        title: "环境名称",
        dataIndex: "session_name"
      },
      {
        title: "分组",
        dataIndex: "group_name"
      },
      {
        title: "版本",
        dataIndex: "version"
      },
      {
        title: "创建时间",
        dataIndex: "create_time"
      },
      {
        title: "最近修改",
        dataIndex: "update_time"
      }
    ]);
    const loading = ref(true);
    const refreshLoading = ref(false);
    const sessionList = ref([]);

    async function getSessionList() {
      loading.value = true;
      await user.getSessionList(props.id).then(res => {
        if (res.data.code === 0) {
          sessionList.value = res.data.data.list;
        } else {
          // :TODO 未成功请求处理
        }
      });
      loading.value = false;
    }

    async function refreshList() {
      refreshLoading.value = true;
      await client.refreshList(props.id).then();
      await getSessionList().then();
      refreshLoading.value = false;
    }

    onMounted(() => {
      getSessionList().then();
    });

    return {
      columns,
      sessionList,
      loading,
      refreshLoading,
      refreshList
    };
  }
};
</script>

<style scoped>
.header {
  display: flex;
  padding-right: 40px;
  align-items: center;
}
.title {
  flex: 1;
}
.header h3 {
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0;
}

.header p {
  margin: 0;
}
.session-table {
  margin-top: 20px;
}
</style>
