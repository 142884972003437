import service from "./axiosConfig.js";
import store from "../store";

//base
const base = {
  // 主服务器
  // url: "https://admin.chaojivps.com"
  url: "https://admin.chaojivps.com" // 测试服务器
};

export const user = {
  login(accounts, pwd) {
    return service.post(`${base.url}/api/supervps/login`, { accounts, pwd });
  },
  reg(accounts, pwd, mobile, invitecode, connect) {
    return service.post(`${base.url}/api/supervps/reg`, {
      accounts,
      pwd,
      invitecode,
	  mobile,
      connect
    });
  },
  getOrderList() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/orderlist`, { token });
  },
  getPriceList() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/items`, { token });
  },
  getInviteList() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/invitelist`, { token });
  },
  getSessionList(son) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/sessionlist`, {
      son_id: son,
      token
    });
  },
  getHistoryList(mod) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/sessionlist`, {
      mod,
      token
    });
  },
  getSonList() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/grouplist`, { token });
  },
  getGroupList() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/sonlist`, { token });
  },
  addSon(email, pwd) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/addson`, {
      token,
      son_accounts: email,
      son_pwd: pwd
    });
  },
  getShareList(mod) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/sharelist`, { token, mod });
  },
  changePassword(oldPwd, newPwd) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/changePwd`, {
      token,
      oldPwd,
      newPwd
    });
  },
  // 删除子用户
  delSon(id) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/delson`, {
      token,
      son_id: id
    });
  },
  changeSonPassword(uid, pwd) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/editson`, {
      token,
      son_uid: uid,
      son_pwd: pwd
    });
  }
};
export const session = {
  setSession(sessionId) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/delvps`, {
      token,
      ids: sessionId
    });
  }
};
export const reg = {
  sendActiveLinkByEmail(email) {
    return service.post(`${base.url}/api/supervps/sendactiveurl`, {
      accounts: email
    });
  },
  activeAccount(hash) {
    return service.post(`${base.url}/api/supervps/activeaccount`, { hash });
  }
};
export const client = {
  forceOffline() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/offline`, { token });
  },
  getStatus() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/status`, { token });
  },
  setSession(mod, sessionID, toID) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/setsession`, {
      token,
      mod,
      session_id: sessionID,
      to_uid: toID
    });
  },
  restoreSession(mod, sessionID) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/setsession`, {
      token,
      mod,
      session_id: sessionID
    });
  },
  refreshList(sonId) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/refreshsessionlist`, {
      token,
      son_id: sonId
    });
  }
};
export const buy = {
  buy(itemId, paymentId, isUpdate = 0) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/buyitem`, {
      token,
      itemid: itemId,
      payment_id: paymentId,
      is_update: isUpdate
    });
  },
  checkOrder(orderNumber) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/ordercheck`, {
      token,
      order_no: orderNumber
    });
  },
  getQrCode(orderNumber) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/orderqrcode`, {
      token,
      order_no: orderNumber
    });
  },
  renewList() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/renewlist`, { token });
  },
  renew(days, paymentId) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/renew`, {
      token,
      days,
      payment_id: paymentId
    });
  }
};

export const clientRoute = {
  skip(token) {
    return service.post(`${base.url}/api/supervps/status`, {
      clienttoken: token
    });
  }
};

export const info = {
  version() {
    return service.post(`${base.url}/api/supervps/version`, {});
  },
  sendMailCode() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/sendcode`, {
      token,
      type: "email"
    });
  },
  checkMailCode(code) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/profile`, {
      token,
      type: "email",
      code
    });
  },
  sendGetBackPasswordMail(accounts) {
    return service.post(`${base.url}/api/supervps/sendpwdurl`, {
      accounts,
      type: "email"
    });
  },
  resetPassword(pwd, hash) {
    return service.post(`${base.url}/api/supervps/resetpwd`, {
      pwd,
      hash
    });
  },
  checkHash(hash) {
    return service.post(`${base.url}/api/supervps/resetpwd`, {
      mod: "verify",
      hash
    });
  }
};

export const lumi = {
  login(username, password) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/lmlogin`, {
      token,
      username,
      password
    });
  },
  list() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/lmgetzonelist`, {
      token
    });
  },
  delPipe(name) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/lmdelzone`, {
      token,
      zone_name: name
    });
  },
  country() {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/lmcountrylist`, {
      token
    });
  },
  createPipe(name, type, country) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/lmaddzone`, {
      token,
      zone_name: name,
      zone_type: type,
      country_abbr: country
    });
  },
  sendToClient(remark, line, dns, name, password) {
    const token = store.state.user.token;
    return service.post(`${base.url}/api/supervps/lmtoclient`, {
      token,
      remark,
      line,
      dns,
      zone_name: name,
      zone_password: password
    });
  }
};
