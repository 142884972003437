<template>
  <div>
    <div v-html="payGateWay"></div>
    {{ payGateWay }}
    <a-button type="primary" @click="testAlipay">提交</a-button>
  </div>
</template>

<script>
export default {
  name: "Alipay",
  data() {
    return {
      payGateWay: ""
    };
  },
  methods: {
    testAlipay() {
      document.getElementById("alipaysubmit").submit();
    }
  },
  mounted() {
    this.payGateWay = this.$route.query.htmlData;
    // this.$nextTick(()=>{
    //   document.getElementById('aliphatic').submit()
    // })
  }
};
</script>

<style scoped></style>
