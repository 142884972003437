<template>
  <header class="header"></header>
  <div class="content">
    <router-view></router-view>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ConsoleLuminati",
  setup() {
    const luminatiOn = ref(true);
    return {
      luminatiOn
    };
  }
};
</script>

<style scoped>
.header {
  padding: 0 40px 0 0;
}
.header h3 {
  font-size: 28px;
  font-weight: 500;
  margin: 10px 0;
}

.header p {
  margin: 0;
  font-size: 14px;
  color: #5f6368;
}
.content {
  padding-top: 20px;
}
.action-button {
  margin-right: 20px;
}
</style>
