<template>
  <div class="bg">
    <div class="modal-form" v-show="formShow">
      <h2 class="title">
        添加子账户
      </h2>
      <div class="form">
        <a-form layout="vertical">
          <a-form-item ref="email" name="email" v-bind="validateInfos.email">
            <a-input
              v-model:value="accountData.email"
              placeholder="请输入子账户邮箱"
            />
          </a-form-item>
          <a-form-item
            ref="password"
            name="password"
            v-bind="validateInfos.password"
          >
            <a-input
              v-model:value="accountData.password"
              placeholder="请输入子账户密码"
            />
          </a-form-item>
          <a-form-item class="submit">
            <a-button
              :loading="submitLoading"
              class="submit-button"
              type="primary"
              @click="addSon"
            >
              提交
            </a-button>
            <a-button @click="$emit('close')">取消</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-result
      class="result"
      status="success"
      title="添加子用户成功"
      v-if="successShow"
    >
      <div class="result-submit">
        <a-button type="primary" @click="closeAll">
          确认
        </a-button>
      </div>
    </a-result>
    <a-result
      class="result"
      status="warning"
      :title="warningContent.title"
      :sub-title="warningContent.subTitle"
      v-if="warningShow"
    >
      <div class="result-submit">
        <a-button type="primary" @click="closeAll">
          确认
        </a-button>
      </div>
    </a-result>
  </div>
</template>

<script>
import { user } from "@/utils/api";

import { useForm } from "@ant-design-vue/use";

import { onMounted, reactive, ref } from "vue";

export default {
  name: "ModalForm",
  setup(props, { emit }) {
    // 按钮loading
    const submitLoading = ref(false);
    // 表单数据
    const accountData = reactive({
      email: "",
      password: ""
    });
    // 成功回调
    const successShow = ref(false);
    // 警告回调
    const warningShow = ref(false);
    const warningContent = reactive({
      title: "出现了一些问题",
      subTitle: "请联系客服人员或重试"
    });
    // 表单显示
    const formShow = ref(true);
    // 校验规则
    const rulesForm = reactive({
      email: [
        { required: true, message: "邮箱不能为空", trigger: "blur" },
        { type: "email", message: "邮箱格式不正确", trigger: "blur" }
      ],
      password: [
        { required: true, message: "密码不能为空", trigger: "blur" },
        { min: 6, max: 18, message: "密码6到18位字符", trigger: "blur" }
      ]
    });
    // 创建表单
    const { resetFields, validate, validateInfos } = useForm(
      accountData,
      rulesForm
    );

    // 添加子用户方法
    function addSon() {
      validate()
        .then(() => {
          submitLoading.value = true;
          user.addSon(accountData.email, accountData.password).then(res => {
            submitLoading.value = false;
            if (res.data.code === 0) {
              emit("refresh");
              formShow.value = false;
              successShow.value = true;
            } else {
              formShow.value = false;
              // 设置警告信息
              warningContent.title = res.data.msg;
              warningContent.subTitle = "";
              warningShow.value = true;
            }
          });
        })
        .catch(err => console.log(err));
    }

    // 关闭总方法
    function closeAll() {
      formShow.value = true;
      successShow.value = false;
      warningShow.value = false;
      emit("close");
    }

    onMounted(() => {
      formShow.value = true;
      successShow.value = false;
      warningShow.value = false;
    });
    return {
      accountData,
      submitLoading,
      addSon,
      successShow,
      warningShow,
      formShow,
      closeAll,
      rulesForm,
      warningContent,
      validateInfos,
      resetFields
    };
  },
  components: {}
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}
.modal-form {
  margin-top: 100px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(64, 64, 67, 0.3);
  max-width: 560px;
  min-width: 360px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #5f6368;
  padding: 20px;
}
.title {
  font-size: 16px;
  line-height: 24px;
  color: #3c4043;
  margin: 0;
  padding: 0 24px 20px 0;
}
.title h2 {
  font-size: 16px;
}
.title p {
  font-size: 14px;
  color: #7f868a;
  margin: 0;
}
.submit {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
.submit-button {
  margin-right: 10px;
}
.account {
  margin-bottom: 10px;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
}

.result {
  background: #ffffff;
  border-radius: 4px;
  margin-top: 100px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 300px;
}

.result .result-submit {
  display: flex;
  justify-content: center;
}
</style>
