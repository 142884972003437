<template>
  <console-header></console-header>
  <section class="content">
    <section class="nav">
      <div class="links">
        <router-link to="/">
          控制台
        </router-link>
        <router-link to="/console/bills">
          订单
        </router-link>
        <router-link to="/console/shop">
          购买
        </router-link>
        <!--<router-link
          to="/console/account"
          v-if="$store.state.user.isSon === '0'"
        >
          子账户
        </router-link>-->
        <!--        <router-link to="/console/luminati/" class="luminati">-->
        <!--          <img src="../assets/img/luminati.png" width="10" alt="luminati" />-->
        <!--          <span style="padding-left: 4px;">Luminati</span>-->
        <!--        </router-link>-->
        <router-link to="/console/session">
          VPS
        </router-link>
        <router-link to="/console/invite">
          邀请
        </router-link>
      </div>
    </section>
    <section class="view">
      <section class="view-container">
        <a-alert
          :message="$store.state.user.discountInfo.desc || ''"
          type="success"
          banner
          v-if="
            $store.state.user.discountInfo &&
              $store.state.user.discountInfo.desc
          "
        >
          <template #icon><RocketOutlined /></template>
        </a-alert>
        <router-view></router-view>
      </section>
    </section>
  </section>
</template>

<script>
import ConsoleHeader from "./console/ConsoleHeader.vue";
import { RocketOutlined } from "@ant-design/icons-vue";

// 引入图标
export default {
  name: "Console",
  components: { ConsoleHeader, RocketOutlined }
};
</script>

<style scoped>
.content {
  padding-top: 40px;
  display: flex;
}
.nav {
  width: 260px;
  flex-shrink: 0;
}
.links a {
  display: block;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #80868b;
  text-decoration: none;
  font-weight: 500;
  padding-left: 100px;
}
.links a.router-link-active {
  background: #e8f0fe;
  border-radius: 0 40px 40px 0;
  color: #1d73e9;
}
.links a.router-link-exact-active {
  background: #e8f0fe;
  border-radius: 0 40px 40px 0;
  color: #1d73e9;
}
.view {
  margin-left: 60px;
  flex: 1;
  display: flex;
  justify-content: center;
}
.view-container {
  flex: 1;
  max-width: 1400px;
}
a.luminati {
  display: flex;
  align-items: baseline;
}
</style>
