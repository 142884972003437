<template>
  <div class="reg-container">
    <div class="reg">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Password"
};
</script>

<style scoped>
.reg-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("https://z3.ax1x.com/2021/07/29/WHokxH.jpg");
  background-size: cover;
}
.reg {
  border-radius: 8px;
  max-width: 750px;
  min-height: 400px;
  position: relative;
  padding: 40px;
  text-align: center;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 120px;
}
@media only screen and (max-width: 480px) {
  .reg {
    padding: 30px;
  }
}
</style>
