<template>
  <div class="account">
    <div class="title">
      <div class="tips">
        当前登录Luminati账户:
        <b class="green">{{ store.state.user.luminatiUser }}</b>
        <router-link to="/console/luminati/login">更换账户</router-link>
      </div>
    </div>
    <div class="operations">
      <a-button
        type="primary"
        @click="createPipeVisible = true"
        class="operations-button "
        >创建通道</a-button
      >
      <a-button
        class="operations-button"
        @click="refreshLuminatiPipeList"
        :loading="refreshLoading"
        >刷新通道列表</a-button
      >
    </div>
    <div class="pipe-list">
      <a-table
        :columns="columns"
        :data-source="pipeData"
        :loading="pipeListLoading"
        :row-key="record => record.name"
      >
        <template #name="{record}">
          {{ record.name }}
        </template>
        <template #action="{record}">
          <a-button
            type="primary"
            @click="
              sendVisible = true;
              sendDataRef.name = record.name;
              sendDataRef.password = record.password;
            "
            class=" form-button"
            >发送到客户端</a-button
          >
          <a-popconfirm
            :title="'您确定要删除通道' + record.name + '吗?'"
            ok-text="确定"
            cancel-text="取消"
            @confirm="delPipe(record.name)"
          >
            <a-button>删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
    <a-modal
      v-model:visible="sendVisible"
      title="发送到超级VPS客户端"
      @ok="sendProxy"
      ok-text="立即发送"
      cancel-text="取消"
      :ok-button-props="{ loading: sendLoading }"
    >
      <div class="send-form">
        <a-form :label-col="{ span: 6 }">
          <a-form-item label="通道名称">
            {{ sendDataRef.name }}
          </a-form-item>
          <a-form-item label="备注" v-bind="validateInfos.remark" required>
            <a-input
              v-model:value="sendDataRef.remark"
              style="width: 300px"
            ></a-input>
          </a-form-item>
          <a-form-item label="线路" required>
            <a-select
              v-model:value="sendDataRef.path"
              placeholder="请选择线路"
              style="width: 300px"
            >
              <a-select-option value="">默认线路</a-select-option>
              <a-select-option value="-route_err-pass_dyn">
                pass_dyn
              </a-select-option>
              <a-select-option value="-route_err-block">
                block
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="DNS解析" required>
            <a-select
              v-model:value="sendDataRef.dns"
              placeholder="DNS解析"
              style="width: 300px"
            >
              <a-select-option value="">
                Fastest DNS resolution
              </a-select-option>
              <a-select-option value="-dns-local">
                DNS resolution at Super Proxy
              </a-select-option>
              <a-select-option value="-dns-remote">
                DNS resolution at Proxy Peer
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="createPipeVisible"
      title="创建Luminati通道"
      ok-text="创建通道"
      cancel-text="取消"
      @ok="createPipe"
      :ok-button-props="{ loading: createPipeLoading }"
    >
      <a-form :label-col="{ span: 5 }">
        <a-form-item label="通道名称" v-bind="validateCreatePipeInfos.name">
          <a-input
            placeholder="英文字母或数字,不允许使用汉字或特殊符号"
            v-model:value="createPipeRef.name"
            style="width: 300px"
          ></a-input>
        </a-form-item>
        <a-form-item label="通道类型" v-bind="validateCreatePipeInfos.type">
          <a-radio-group
            v-model:value="createPipeRef.type"
            button-style="solid"
          >
            <a-radio-button value="static_resident">静态住宅</a-radio-button>
            <a-radio-button value="dynamic_resident">动态住宅</a-radio-button>
            <a-radio-button value="data_center">数据中心</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          label="IP所属国家"
          v-bind="validateCreatePipeInfos.country"
        >
          <a-select v-model:value="createPipeRef.country" style="width: 200px">
            <a-select-option
              v-for="country in countries"
              :key="country.code"
              :value="country.code"
            >
              {{ country.code.toUpperCase() }} {{ country.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { useForm } from "@ant-design-vue/use";
import { useStore } from "vuex";
import { lumi } from "@/utils/api";
import { message } from "ant-design-vue";

export default {
  name: "LuminatiAccounts",
  setup() {
    const store = useStore();
    // 获得通道列表
    const pipeData = ref([]);
    const pipeListLoading = ref(false);
    async function getLuminatiPipeList() {
      pipeListLoading.value = true;
      await lumi.list().then(res => {
        if (res.data.code === 0) {
          pipeData.value = res.data.data;
        } else {
          message.error(res.data.msg);
        }
      });
      pipeListLoading.value = false;
    }
    onMounted(() => {
      getLuminatiPipeList().then();
      getCountrys().then();
    });
    // 刷新通道列表
    const refreshLoading = ref(false);
    async function refreshLuminatiPipeList() {
      refreshLoading.value = true;
      await lumi.list().then(res => {
        console.log(res);
        if (res.data.code === 0) {
          message.success("刷新成功");
          pipeData.value = res.data.data;
        }
      });
      refreshLoading.value = false;
    }

    const columns = [
      {
        title: "通道名称",
        key: "name",
        slots: { customRender: "name" }
      },
      {
        title: "通道类型",
        key: "zone_type",
        dataIndex: "zone_type"
      },
      {
        title: "国家",
        key: "country_abbr",
        dataIndex: "country_abbr"
      },
      {
        title: "密码",
        key: "password",
        dataIndex: "password"
      },
      {
        title: "操作",
        key: "action",
        slots: { customRender: "action" }
      }
    ];

    // 删除通道
    async function delPipe(name) {
      await lumi.delPipe(name).then(res => {
        if (res.data.code === 0) {
          message.success("删除成功");
          pipeData.value = res.data.data;
        } else {
          message.error(res.data.msg);
        }
      });
    }
    // 校验通道名
    const checkPipeName = async (rule, value) => {
      return new Promise((resolve, reject) => {
        if (!value) {
          reject("通道名不能为空");
        }
        if (!value.match(/^[a-zA-Z0-9]+$/u)) {
          reject("通道名仅能使用字母或数字");
        } else {
          resolve("done");
        }
      });
    };
    const countries = ref({});
    // 获得国家
    async function getCountrys() {
      await lumi.country().then(res => {
        if (res.data.code === 0) {
          countries.value = res.data.data.country_cn_list;
        } else {
          message.error("获得国家列表失败，请联系客服");
        }
      });
    }
    // 添加通道
    const createPipeVisible = ref(false);
    const createPipeLoading = ref(false);
    const createPipeRef = reactive({
      type: "static_resident",
      name: "",
      country: ""
    });
    const createPipeRulesRef = reactive({
      type: [
        {
          required: true,
          message: "请选择通道类型"
        }
      ],
      name: [
        {
          validator: checkPipeName,
          trigger: "blur"
        }
      ],
      country: [
        {
          required: true,
          message: "请选择IP所属国家"
        }
      ]
    });
    const {
      resetFields: resetCreatePipe,
      validate: validateCreatePipe,
      validateInfos: validateCreatePipeInfos
    } = useForm(createPipeRef, createPipeRulesRef);
    async function createPipe() {
      validateCreatePipe()
        .then(() => {
          createPipeLoading.value = true;
          lumi
            .createPipe(
              createPipeRef.name,
              createPipeRef.type,
              createPipeRef.country
            )
            .then(res => {
              createPipeLoading.value = false;
              console.log(res);
              if (res.data.code === 0) {
                message.success("通道创建成功");
                createPipeVisible.value = false;
                pipeData.value = res.data.data;
              } else {
                message.error(res.data.msg);
              }
            })
            .catch(err => {
              createPipeLoading.value = false;
              console.log(err);
            });
        })
        .catch(error => console.log(error));
    }
    //发送到客户端
    const sendVisible = ref(false);
    const sendDataRef = reactive({
      name: "",
      remark: "",
      path: "",
      dns: "",
      password: ""
    });
    const sendRulesRef = reactive({
      remark: [
        { required: true, message: "备注名不能为空" },
        { max: 30, message: "备注名不能超过30个字符" }
      ]
    });
    const { resetFields, validate, validateInfos } = useForm(
      sendDataRef,
      sendRulesRef
    );
    const sendLoading = ref(false);
    async function sendProxy() {
      validate()
        .then(() => {
          sendLoading.value = true;
          lumi
            .sendToClient(
              sendDataRef.remark,
              sendDataRef.path,
              sendDataRef.dns,
              sendDataRef.name,
              sendDataRef.password
            )
            .then(res => {
              sendLoading.value = false;
              if (res.data.code === 0) {
                message.success("发送成功");
                sendVisible.value = false;
              } else {
                message.error(res.data.msg);
              }
            })
            .catch(err => {
              sendLoading.value = false;
              message.error(err);
            });
        })
        .catch(err => console.log(err));
    }
    return {
      store,
      columns,
      pipeData,
      sendVisible,
      sendProxy,
      sendDataRef,
      sendRulesRef,
      resetFields,
      validate,
      createPipeVisible,
      createPipe,
      createPipeRef,
      createPipeRulesRef,
      resetCreatePipe,
      validateCreatePipe,
      refreshLuminatiPipeList,
      refreshLoading,
      pipeListLoading,
      delPipe,
      validateCreatePipeInfos,
      countries,
      createPipeLoading,
      validateInfos,
      sendLoading
    };
  }
};
</script>

<style scoped>
.tips {
  background-color: rgba(82, 99, 115, 0.1);
  color: #526373;
  width: 800px;
  padding: 20px;
  border-radius: 6px;
}
.tips .green {
  color: #526373;
  margin-right: 10px;
}
.pipe-list {
  max-width: 800px;
}
.account {
}
.operations {
  width: 800px;
  margin: 10px 0;
}
.operations-button {
  margin-right: 10px;
}
.form-button {
  margin-right: 10px;
}
</style>
