<template>
  <div class="operation">
    <a-popconfirm
      cancel-text="取消"
      ok-text="确定"
      title="您确定要删除选中的VPS服务器么?"
      @cancel="cancel"
      @confirm="batchDel"
      v-if="selectedRowKeys.length !== 0"
    >
      <a-button
        class="operation-button"
        :disabled="selectedRowKeys.length === 0"
        type="primary"
      >
        批量删除
      </a-button>
    </a-popconfirm>
    <div class="placeholder"></div>
    <label class="operation-search">
      <a-input-search
        v-model:value="keyword"
        :loading="searchLoading"
        @search="searchSessionList()"
        @change="searchChange"
      />
    </label>
    <a-button
      class="operation-button"
      type="primary"
      @click="refreshSessionList"
      >刷新列表</a-button
    >
  </div>
  <a-table
    class="session-table"
    :columns="columns"
    :row-key="record => record.id"
    :data-source="sessionList"
    :loading="loading"
    :row-selection="rowSelection"
  >
    <template #action="{record}">
      <a-popconfirm
        cancel-text="取消"
        ok-text="确定"
        title="您确定要删除这个VPS服务器么?"
        @cancel="cancel"
        @confirm="delHistorySession(record.id)"
      >
        <a-button class="restore">删除</a-button>
      </a-popconfirm>
    </template>
  </a-table>
  <aside class="bg" v-if="shareShow">
    <div class="share">
      <header class="header">
        <span>
          要分享的环境名称
        </span>
        <div v-html="shareSessionName" class="share-title"></div>
      </header>
      <section class="select">
        <span>
          要分享给谁
        </span>
        <a-select
          v-model:value="sharePeople"
          ref="shareSelect"
          :defaultOpen="true"
          size="large"
          class="select"
          :autofocus="true"
          label-in-value
          @change="shareChange"
        >
          <a-select-option v-for="son in sonList" :key="son.id" :value="son.id">
            {{ son.email }}
          </a-select-option>
        </a-select>
      </section>
      <section class="submit">
        <a-button type="primary" @click="setSession" class="submit-button"
          >确认分享</a-button
        >
        <a-button @click="cancel">取消</a-button>
      </section>
    </div>
  </aside>
  <popup v-show="popupShow" @confirm="next">
    <template v-slot:title>
      {{ popupTitle }}
    </template>
    <template v-slot:describe>
      {{ popupContent }}
    </template>
  </popup>
</template>

<script>
import { client, user, session } from "@/utils/api";
import { onMounted, ref, inject, watch, reactive } from "vue";
import { message, Modal } from "ant-design-vue";
import { useRouter } from "vue-router";
import Popup from "@/components/Popup";
import cnchar from "cnchar";
import { useStore } from "vuex";

export default {
  name: "ConsoleSessionList",
  components: { Popup },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
          this.selectedRowKeys = selectedRowKeys;
        }
      };
    }
  },
  setup() {
    const store = useStore();
    const selectedRows = ref([]);
    const selectedRowKeys = ref([]);
    const refresh = inject("refresh");
    const router = useRouter();
    const groupsFilter = ref([]);
    // 多选

    const columns = reactive([
      {
        title: "ID",
        dataIndex: "id",
        sorter: (a, b) => a.id - b.id
      },
      {
        title: "VPS服务器",
        dataIndex: "session_name"
      },
      {
        title: "分组",
        dataIndex: "group_name",
        filters: groupsFilter.value,
        onFilter: (value, record) => record.group_name === value
      },
      {
        title: "版本",
        dataIndex: "version"
      },
      {
        title: "创建时间",
        dataIndex: "create_time",
        sorter: (a, b) =>
          new Date(a.create_time).getTime() - new Date(b.create_time).getTime()
      },
      {
        title: "最近修改",
        dataIndex: "update_time",
        sorter: (a, b) =>
          new Date(a.update_time).getTime() - new Date(b.update_time).getTime()
      },
      {
        title: "操作",
        slots: { customRender: "action" }
      }
    ]);
    const sessionList = ref([]);
    const resultList = ref([]);
    const loading = ref(true);

    /**
     * 刷新列表
     * **/
    async function refreshSessionList() {
      groupsFilter.value = [];
      await client.refreshList().then();
      await getSessionList().then();
    }

    // 获得分组列表
    async function getSessionList() {
      loading.value = true;
      user.getSessionList().then(res => {
        loading.value = false;
        if (res.data.code === 0) {
          setGroupsFilter(res);
          resultList.value = res.data.data.list;
          sessionList.value = res.data.data.list;
        } else {
          // :TODO 未成功请求处理
        }
      });
    }
    // 设置分组
    async function setGroupsFilter(res) {
      const groupsResponse = [];
      res.data.data.list.forEach(item => {
        groupsResponse.push(item.group_name);
      });
      Array.from(new Set(groupsResponse)).forEach(item => {
        groupsFilter.value.push({
          text: item,
          value: item
        });
      });
    }

    // 检索环境列表
    const searchLoading = ref(false);
    const keyword = ref("");
    function searchSessionList() {
      searchLoading.value = true;
      setTimeout(() => {
        sessionList.value = resultList.value;
        sessionList.value = sessionList.value.filter(session => {
          return (
            cnchar
              .spell(session.session_name, "first", "low")
              .toLowerCase()
              .indexOf(
                cnchar.spell(keyword.value, "first", "low").toLowerCase()
              ) > -1
          );
        });
      }, 10);

      setTimeout(() => {
        searchLoading.value = false;
      }, 500);
    }

    function searchChange() {
      if (keyword.value === "") {
        setTimeout(() => {
          searchSessionList();
        }, 50);
      }
    }
    // 发送到客户端
    async function restoreSession(sessionID) {
      await client.restoreSession("own", sessionID).then(res => {
        message.info(res.data.msg);
      });
    }

    watch(refresh, () => {
      getSessionList().then();
    });

    // 点击批量分享按钮
    function batchShare() {
      let sessionID = "";
      let sessionName = "";
      if (selectedRows.value.length >= 5) {
        for (let i = 0; i <= 3; i++) {
          sessionName =
            sessionName + selectedRows.value[i].session_name + "<br />";
        }
        sessionName =
          sessionName +
          ".....<br/>" +
          "共" +
          selectedRows.value.length +
          "个环境";
      } else {
        for (const selectedRow of selectedRows.value) {
          sessionName = sessionName + selectedRow.session_name + "<br/>";
        }
      }
      for (const shareId of selectedRowKeys.value) {
        sessionID = sessionID + shareId + ",";
      }
      shareSession(sessionID, sessionName);
    }

    // 点击分享按钮
    const shareSessionID = ref("");
    const shareSessionName = ref("");
    const shareShow = ref(false);

    function shareSession(sessionID, sessionName) {
      console.log(store.state);
      if (store.state.user.vip >= 3 || store.state.user.isSon > 0) {
        shareSessionID.value = sessionID;
        shareSessionName.value = sessionName;
        shareShow.value = true;
      } else {
        Modal.confirm({
          title: "您没有可分享的子账户",
          content: "您可以购买含子账户的套餐，或者添加相应子账户",
          okText: "去购买",
          cancelText: "取消",
          onOk() {
            router.push("/console/shop").then();
          },
          onCancel() {}
        });
      }
    }
	
	// 批量删除历史环境
	function onSelectChange(rowKeys) {
	  selectedRowKeys.value = rowKeys;
	}
	async function batchDel() {
	  await delHistorySession(selectedRowKeys.value.toString()).then(
	    () => (selectedRowKeys.value = [])
	  );
	}
	// 删除历史环境
	async function delHistorySession(id) {
	  await session.setSession(id).then(res => {
	    if (res.data.code === 0) {
	      message.success(res.data.msg);
	    }
	  });
	  getSessionList().then();
	}

    //

    const sonList = ref([]);
    const sonNumber = ref(0);

    // 获得子账户列表
    async function getSonList() {
      await user.getSonList().then(res => {
        if (res.data.code === 0) {
          sonList.value = res.data.data.list;
          sonNumber.value = res.data.data.list.length;
        } else {
          // :TODO 处理未成功请求
        }
      });
    }

    // 选择不同的分享人
    const sharePeople = ref({});
    // 弹窗内容
    const popupShow = ref(false); // 弹出窗口
    const popupTitle = ref(""); // 弹出窗口标题
    const popupContent = ref(""); // 弹出窗口内容
    async function setSession() {
      await client
        .setSession("share", shareSessionID.value, sharePeople.value.value)
        .then(res => {
          shareShow.value = false;
          if (res.data.code === 0) {
            // 弹出分享成功窗口
            popupTitle.value = "分享成功";
            popupContent.value = res.data.msg;
            popupShow.value = true;
          } else {
            // 弹出失败窗口
            popupTitle.value = "分享失败";
            popupContent.value = res.data.msg;
            popupShow.value = true;
          }
        });
    }
    // next
    function next() {
      popupShow.value = false;
    }
    function cancel() {
      shareShow.value = false;
    }

    onMounted(() => {
      getSessionList().then();
      getSonList().then();
    });
    return {
      sessionList, // 环境列表
      loading,
      columns,
      sharePeople, // 分享人ID
      sonList, // 子账户列表
      shareSession, // 获得分享环境句柄
      setSession, // 开始转移
      shareShow,
      shareSessionName,
      // 弹出窗口配置
      popupTitle,
      popupContent,
      popupShow,
      next,
      cancel,
      selectedRowKeys,
      batchShare,
      keyword,
      searchSessionList,
      getSessionList,
      selectedRows,
      refreshSessionList,
      restoreSession,
      groupsFilter,
      searchLoading,
      searchChange,
	  delHistorySession,
	  onSelectChange,
	  batchDel
    };
  }
};
</script>

<style scoped>
.tips {
  padding: 10px 0;
  font-size: 14px;
}

.select {
  width: 260px;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}

.share {
  width: 300px;
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
}

.share .header span {
  color: #7f868a;
}

.share .select span {
  color: #7f868a;
  display: block;
  padding-bottom: 10px;
}

.share .submit {
  margin-top: 20px;
}

.share .submit .submit-button {
  margin-right: 10px;
}
.operation {
  display: flex;
  padding: 10px;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
}
.operation-button {
  margin-right: 10px;
}
.operation-search {
  margin-right: 10px;
  width: 200px;
}
.placeholder {
  flex: 1;
}
</style>
