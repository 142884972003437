<template>
  <div class="bg">
    <div class="change">
      <h2 class="title">
        修改密码
      </h2>
      <div class="form">
        <a-form layout="vertical">
          <a-form-item
            ref="oldPassword"
            name="oldPassword"
            v-bind="validateInfos.oldPassword"
          >
            <a-input
              v-model:value="passwordData.oldPassword"
              placeholder="请输入原有密码"
              type="password"
            />
          </a-form-item>
          <a-form-item
            ref="newPassword"
            name="newPassword"
            v-bind="validateInfos.newPassword"
          >
            <a-input
              v-model:value="passwordData.newPassword"
              placeholder="请输入新密码"
              type="password"
            />
          </a-form-item>
          <a-form-item
            ref="newPasswordAgain"
            name="newPasswordAgain"
            v-bind="validateInfos.newPasswordAgain"
          >
            <a-input
              v-model:value="passwordData.newPasswordAgain"
              placeholder="请再次输入密码"
              type="password"
            />
          </a-form-item>
          <a-form-item class="submit">
            <a-button
              :loading="submitLoading"
              class="submit-button"
              type="primary"
              @click="changePassword"
            >
              提交
            </a-button>
            <a-button @click="$emit('close')">取消</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useForm } from "@ant-design-vue/use";
import { user } from "@/utils/api";

export default {
  name: "ChangePassword",
  setup(props, { emit }) {
    // 按钮loading
    const submitLoading = ref(false);
    // 表单数据
    const passwordData = reactive({
      oldPassword: "",
      newPassword: "",
      newPasswordAgain: ""
    });
    // 成功回调
    const successShow = ref(false);
    // 警告回调
    const warningShow = ref(false);
    const warningContent = reactive({
      title: "出现了一些问题",
      subTitle: "请联系客服人员或重试"
    });
    // 表单显示
    const formShow = ref(true);
    // 校验规则
    const rulesForm = reactive({
      oldPassword: [
        { required: true, message: "密码不能为空", trigger: "blur" },
        {
          min: 6,
          max: 18,
          message: "您的密码应该是6到18位字符",
          trigger: "blur"
        }
      ],
      newPassword: [
        { required: true, message: "密码不能为空", trigger: "blur" },
        { min: 6, max: 18, message: "新密码6到18位字符", trigger: "blur" }
      ],
      newPasswordAgain: [
        { required: true, message: "密码不能为空", trigger: "blur" },
        { min: 6, max: 18, message: "请输入同样的密码", trigger: "blur" }
      ]
    });
    // 创建表单
    const { resetFields, validate, validateInfos } = useForm(
      passwordData,
      rulesForm
    );

    // 提交方法
    async function changePassword() {
      validate()
        .then(res => {
          if (res.newPassword === res.oldPassword) {
            //:TODO 新密码不能和原密码相同
            validateInfos.newPassword.validateStatus = "error";
            validateInfos.newPassword.help = "新密码不能和原密码相同";
          } else if (res.newPassword !== res.newPasswordAgain) {
            //:TODO 请重复输入新密码
            validateInfos.newPasswordAgain.validateStatus = "error";
            validateInfos.newPasswordAgain.help = "请重复输入新密码";
          } else {
            //:TODO 请求修改密码
            user
              .changePassword(res.oldPassword, res.newPasswordAgain)
              .then(res => {
                if (res.data.code === 0) {
                  emit("success");
                  emit("close");
                } else if (res.data.code === -6) {
                  validateInfos.oldPassword.validateStatus = "error";
                  validateInfos.oldPassword.help = "旧密码错误";
                } else {
                  validateInfos.newPassword.validateStatus = "error";
                  validateInfos.newPassword.help = res.data.msg;
                }
              })
              .catch(err => {
                console.log(err);
              });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }

    return {
      submitLoading,
      passwordData,
      resetFields,
      validate,
      validateInfos,
      changePassword,
      successShow,
      warningShow,
      formShow,
      warningContent
    };
  }
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}

.change {
  margin-top: 100px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(64, 64, 67, 0.3);
  max-width: 560px;
  min-width: 360px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #5f6368;
  padding: 20px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: #3c4043;
  margin: 0;
  padding: 0 24px 20px 0;
}

.title h2 {
  font-size: 16px;
}

.title p {
  font-size: 14px;
  color: #7f868a;
  margin: 0;
}

.submit {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.submit-button {
  margin-right: 10px;
}

.account {
  margin-bottom: 10px;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
}

.result {
  background: #ffffff;
  border-radius: 4px;
  margin-top: 100px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  height: 300px;
}

.result .result-submit {
  display: flex;
  justify-content: center;
}
</style>
