<template>
  <div class="bg">
    <div class="pay">
      <loading v-show="loadingShow"></loading>
      <div class="pay-form" v-show="payFormShow">
        <header class="pay-header">
          <div class="logo">
            <img src="../assets/img/logo-big.png" width="80" alt="logo" />
          </div>
          <div class="account">
            <b>{{ $store.state.user.email }}</b>
            <span class="change-account" @click="changeAccount">更换账号</span>
          </div>
        </header>
        <div class="pay-content">
          <div class="title">
            <b>订购超级VPS</b>
            <h1>超级VPS - {{ payData.name }}</h1>
            <p>{{ payData.info }}</p>
          </div>
          <div class="step one">
            <div class="step-title">
              <span class="point">1</span>
              <b>选择您的用户数量</b>
            </div>
            <div class="pay-list">
              <ul>
                <li
                  :class="{ active: userNumber === '3' }"
                  @click="userNumberChange('3')"
                >
                  3用户
                </li>
                <li
                  :class="{ active: userNumber === '5' }"
                  @click="userNumberChange('5')"
                >
                  5用户
                </li>
                <li
                  :class="{ active: userNumber === '8' }"
                  @click="userNumberChange('8')"
                >
                  8用户
                </li>
                <li
                  :class="{ active: userNumber === '10' }"
                  @click="userNumberChange('10')"
                >
                  10用户
                </li>
              </ul>
            </div>
          </div>
          <div class="step two">
            <div class="step-title">
              <span class="point">2</span>
              <b>选择您的结算周期</b>
            </div>
            <div class="pay-list price-list">
              <ul>
                <li
                  v-for="pay in payMonth"
                  :key="pay.id"
                  :class="{ active: pay.days === payDays }"
                  @click="
                    changePayMonth(
                      pay.id,
                      pay.days,
                      calcPrice(pay.price) > pay.price_discount
                        ? pay.price_discount
                        : calcPrice(pay.price)
                    )
                  "
                >
                  {{ Math.floor(pay.days / 30) }} 个月
                  <h4>
                    {{
                      calcPrice(pay.price) > pay.price_discount
                        ? pay.price_discount
                        : calcPrice(pay.price)
                    }}元
                  </h4>
                  <p
                    class="discount"
                    v-if="
                      $store.state.user.discountInfo &&
                        $store.state.user.discountInfo.desc
                    "
                  >
                    活动节省{{
                      calcPrice(pay.price) > pay.price_discount
                        ? pay.price - pay.price_discount
                        : pay.price - calcPrice(pay.price)
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="step">
            <div class="step-title">
              <span class="point">3</span>
              <b>选择您的付款方式</b>
            </div>
            <div class="pay-list">
              <ul>
                <li
                  :class="{ active: payMode.wechat.id === payActive }"
                  @click="changePay(payMode.wechat.id)"
                >
                  {{ payMode.wechat.name }}
                </li>
                <li
                  :class="{ active: payMode.alipay.id === payActive }"
                  @click="changePay(payMode.alipay.id)"
                >
                  {{ payMode.alipay.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="submit">
            <button class="cancel-button" @click="$emit('cancel')">取消</button>
            <z-button @click="tipsNoSupport">下一步</z-button>
          </div>
        </div>
      </div>
      <!--alipay form-->
      <div v-html="htmlData"></div>
      <!--二维码扫描-->
      <div class="pay-code" v-if="payCodeShow">
        <h1>使用微信扫描二维码完成支付</h1>
        <!--        <p>支付{{ selectPrice }}元</p>-->
        <h2>请在 {{ payCode.time }} 秒内完成支付</h2>
        <div class="pay-code-img">
          <img
            :src="
              'https://admin.chaojivps.com/api/qrcode/show?url=' +
                payCode.payPicUrl
            "
            :alt="payCode.payOrderNumber"
          />
        </div>
        <div class="cancel-pay">
          <button
            class="cancel-button cancel-pay-button"
            @click="cancelPayCode"
          >
            取消
          </button>
        </div>
      </div>
      <div class="pay-success" v-if="paySuccessShow">
        <img src="../assets/img/success.png" width="100" alt="支付成功" />
        <h1>支付成功</h1>
        <div class="cancel-pay">
          <z-button @click="successGo">确定</z-button>
        </div>
      </div>
    </div>
  </div>
  <!--弹出框-->
  <popup v-show="popShowInfo" @confirm="payGo">
    <template v-slot:title>
      您正在尝试升级套餐
    </template>
    <template v-slot:describe>
      超级VPS套餐升级会通过计算您所升级套餐与现有套餐剩余时间的差价 +
      新采购套餐价格来计算，如有不清楚请联系超级VPS客服
    </template>
  </popup>
  <!--alipay form-->
  <div v-html="alipayHtmlData"></div>
</template>

<script>
import { message, Modal } from "ant-design-vue";

import Popup from "../components/Popup.vue";
import ZButton from "./ZButton.vue";
import Loading from "./Loading.vue";
import { buy } from "@/utils/api";
import { useStore } from "vuex";
import { h, ref } from "vue";

export default {
  emits: ["cancel", "pop"],
  props: {
    payData: {
      type: Object
    },
    defaultSelected: {
      type: String
    },
    payMode: {
      type: Object
    }
  },
  setup() {
    const store = useStore();

    /**
     * 计算优惠价格
     * @param price
     * @return {number|*}
     */
    // 计算折扣
    function calcPrice(price) {
      const discount = Number(
        store.state.user.discountInfo
          ? store.state.user.discountInfo.discount
          : 0
      );
      if (discount !== 0) {
        return Math.floor((price * (100 - discount)) / 100);
      } else {
        return calcCoupon(price);
      }
    }
    // 计算满减
    function calcCoupon(price) {
      const coupon = store.state.user.discountInfo
        ? store.state.user.discountInfo.coupon.split("|")
        : [];
      let discountMax = 0;
      if (coupon.length > 0) {
        coupon.forEach(item => {
          const limitPrice = item.split("-");
          if (Number(limitPrice[0]) <= price) {
            if (Number(limitPrice[1]) > discountMax) {
              discountMax = Number(limitPrice[1]);
            }
          }
        });
      }
      return price - discountMax;
    }
    // 请求微信支付
    const wechatPayOrderNo = ref(null);
    const wechatPayImgUrl = ref(null);
    const wechatPayShow = ref(false);
    async function requestWechatPay(orderNumber, payImgUrl) {
      wechatPayOrderNo.value = orderNumber;
      wechatPayImgUrl.value = payImgUrl;
      wechatPayShow.value = true;
    }
    function tipsNoSupport() {
      message.info("旧版套餐已不再支持");
    }
    return {
      calcPrice,
      requestWechatPay,
      tipsNoSupport
    };
  },
  data() {
    return {
      popShowInfo: false,
      payActive: this.payMode.wechat.id,
      loadingShow: false,
      payFormShow: true,
      payCodeShow: false,
      payCode: {
        price: "",
        payOrderNumber: "",
        payPicUrl: "",
        time: 300,
        cancel: false
      },
      popShow: false,
      paySuccessShow: false,
      // 结算周期数据
      payMonth: this.payData.five,
      // 用户数量
      userNumber: this.defaultSelected,
      payDays: "30",
      selectItem: "",
      selectPrice: "",
      htmlData: ""
    };
  },
  mounted() {
    this.findSelectedItem();
  },
  name: "PayTeam",
  components: { Loading, ZButton, Popup },
  methods: {
    userNumberChange(num) {
      switch (num) {
        case "3":
          this.payMonth = this.payData.three;
          this.userNumber = "3";
          break;
        case "5":
          this.payMonth = this.payData.five;
          this.userNumber = "5";
          break;
        case "8":
          this.payMonth = this.payData.eight;
          this.userNumber = "8";
          break;
        case "10":
          this.payMonth = this.payData.ten;
          this.userNumber = "10";
          break;
        default:
          this.payMonth = this.payData.five;
          this.userNumber = "5";
          break;
      }
    },
    changePayMonth(id, days) {
      this.selectItem = id;
      this.payDays = days;
    },
    monthChange(code, price) {
      this.monthActive = code;
      this.payCode.price = price;
    },
    changePay(code) {
      this.payActive = code;
    },
    async payGo() {
      this.popShowInfo = false;
      // 打开loading
      this.findSelectedItem();
      // 打开loading
      this.loadingShow = true;
      //微信支付
      if (this.payActive === this.payMode.wechat.id) {
        let res;
        if (this.payDays === "0") {
          res = await buy
            .buy(this.selectItem, this.payActive, "1")
            .then(res => res.data);
        } else {
          res = await buy
            .buy(this.selectItem, this.payActive)
            .then(res => res.data);
        }
        if (res.code === 0) {
          this.payCode.payOrderNumber = res.data.order_no;
          this.payCode.payPicUrl = encodeURIComponent(res.data.payurl);
          // 关闭loading
          this.loadingShow = false;
          // 关闭表单
          this.payFormShow = false;
          // 打开扫码支付
          this.payCodeShow = true;
          // 开始检测
          const payState = await this.checkOrder(
            this.payCode.payOrderNumber,
            1000,
            300
          );
          if (payState) {
            this.payCodeShow = false;
            this.paySuccessShow = true;
          } else {
            this.$emit("cancel");
            this.payCode.cancel = true;
          }
        } else if (res.code === -1) {
          this.loadingShow = false;
          Modal.warn({
            title: res.msg,
            content: h("div", {}, [
              h("img", {
                style: { width: "300px", verticalAlign: "middle" },
                src: "https://www.chaojivps.com/img/kefu.png"
              })
            ]),
            onOk() {}
          });
          this.$emit("cancel");
        } else {
          this.loadingShow = false;
          Modal.warn({
            title: res.msg,
            content: h("div", {}, [
              h("img", {
                style: { width: "300px", verticalAlign: "middle" },
                src: "https://www.chaojivps.com/img/kefu.png"
              })
            ]),
            onOk() {}
          });
          this.$emit("cancel");
        }
      } else {
        const res = await buy
          .buy(this.selectItem, this.payActive)
          .then(res => res.data);
        if (res.code === 0) {
          this.htmlData = res.data.html;
          this.$nextTick(() => {
            document.getElementById("alipaysubmit").submit();
          }).then();
        } else if (res.code === -1) {
          this.loadingShow = false;
          Modal.warn({
            title: res.msg,
            onOk() {}
          });
          this.$emit("cancel");
        } else {
          this.loadingShow = false;
          Modal.warn({
            title: res.msg,
            onOk() {}
          });
          this.$emit("cancel");
        }
      }
    },
    async checkOrder(orderNumber, delay, times) {
      this.payCode.cancel = false;
      this.payCode.time = times + 1;
      for (let i = 1; i <= times; i++) {
        if (this.payCode.cancel) {
          return false;
        }
        const code = await buy.checkOrder(orderNumber).then(res => {
          return res.data.code;
        });
        if (code === 0) {
          return true;
        } else {
          this.payCode.time--;
          await this.sleep(delay);
        }
      }
      this.$emit("cancel");
    },
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    cancelPayCode() {
      this.$emit("cancel");
      this.payCode.cancel = true;
    },
    successGo() {
      this.$emit("cancel");
    },
    changeAccount() {
      this.$store.commit("quit");
      this.$router.push("/");
    },
    // 找到现在的选择item
    findSelectedItem() {
      switch (this.userNumber) {
        case "3":
          this.payMonth = this.payData.three;
          this.payData.three.forEach(item => {
            if (item.days === this.payDays) {
              this.selectItem = item.id;
              this.selectPrice =
                this.calcPrice(item.price) > item.price_discount
                  ? item.price_discount
                  : this.calcPrice(item.price);
            }
          });
          break;
        case "5":
          this.payMonth = this.payData.five;
          this.payData.five.forEach(item => {
            if (item.days === this.payDays) {
              this.selectItem = item.id;
              this.selectPrice =
                this.calcPrice(item.price) > item.price_discount
                  ? item.price_discount
                  : this.calcPrice(item.price);
            }
          });
          break;
        case "8":
          this.payMonth = this.payData.eight;
          this.payData.eight.forEach(item => {
            if (item.days === this.payDays) {
              this.selectItem = item.id;
              this.selectPrice =
                this.calcPrice(item.price) > item.price_discount
                  ? item.price_discount
                  : this.calcPrice(item.price);
            }
          });
          break;
        case "10":
          this.payMonth = this.payData.ten;
          this.payData.ten.forEach(item => {
            if (item.days === this.payDays) {
              this.selectItem = item.id;
              this.selectPrice =
                this.calcPrice(item.price) > item.price_discount
                  ? item.price_discount
                  : this.calcPrice(item.price);
            }
          });
          break;
        default:
          break;
      }
    }
  },
  beforeUnmount() {
    this.payCode.cancel = true;
  },
  watch: {
    defaultSelected() {
      this.userNumber = this.defaultSelected;
    }
  }
  //:TODO 支付成功界面图标
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}

.pay {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(64, 64, 67, 0.3);
  max-width: 750px;
  min-width: 600px;
  min-height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.pay-header {
  padding: 5px 40px;
  display: flex;
  align-items: center;
}

.logo {
  margin-top: -60px;
  flex: 1;
  z-index: 999;
}

.account {
  font-size: 14px;
  font-weight: 400;
}

.account b {
  color: #1a73e8;
}

.account span {
  color: #7f868a;
  padding-left: 10px;
  font-size: 12px;
}

.pay-content {
  padding: 5px 40px;
}

.title b {
  font-size: 14px;
  color: #7f868a;
  font-weight: 400;
}

.title h1 {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
  padding: 10px 0;
  color: #3d4043;
}

.title p {
  font-size: 14px;
  margin: 0;
  padding: 5px 0;
}

.step {
  margin-top: 10px;
  margin-bottom: 20px;
}

.step-title {
  font-size: 14px;
}

.step-title span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #bcc0c4;
  color: #ffffff;
  text-align: center;
  margin-right: 10px;
}

.month-list ul {
  margin: 0;
  list-style: none;
  padding: 0 30px;
}

.month-list ul li.active {
  background-color: #1a73e8;
  border: 1px #1a73e8 solid;
  color: #ffffff !important;
}

.month-list ul li.active p {
  color: #ffffff;
}

.month-list ul li.active .info h2 {
  color: #ffffff;
}

.month-list ul li.active .price h1 {
  color: #ffffff;
}

.month-list ul li.active .price h1 b {
  color: #ffffff;
}

.month {
  width: 300px;
  display: flex;
  border: 1px #dadce0 solid;
  border-radius: 2px;
  padding: 15px 20px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.month:hover {
  background-color: #e8f0fe;
  border: 1px #e8f0fe solid;
}

.month .info {
  padding-right: 20px;
}

.month .info h2 {
  font-size: 18px;
  margin: 0;
  font-weight: 400;
}

.month .info h2 span {
  font-size: 12px;
}

.month .info p {
  font-size: 12px;
  margin: 0;
  padding-top: 5px;
  color: #81868a;
  font-weight: 400;
}

.month .price {
}

.month .price h1 {
  margin: 0;
  color: #1a73e8;
  font-size: 21px;
  font-weight: 400;
}

.month .price h1 span {
  font-size: 14px;
}

.month .price h1 b {
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  color: #81868a;
}

.pay-list ul {
  display: flex;
  margin: 0;
  padding: 10px 0 0 30px;
}

.pay-list ul li {
  list-style: none;
  font-size: 14px;
  border: 1px #dadce0 solid;
  border-radius: 2px;
  padding: 8px;
  margin-right: 20px;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
}

.price-list ul li {
  text-align: left;
  padding: 8px 20px 0 20px;
}

.pay-list ul li:hover {
  border: 1px #e8f0fe solid;
  background-color: #e8f0fe;
}

.pay-list ul li.active {
  background-color: #1a73e8;
  color: #ffffff;
  border: none;
}
.pay-list ul li.active h4 {
  color: #ffffff;
}

.submit {
  padding: 10px 0 5px 0;
  margin-top: 20px;
  border-top: 1px #dadce0 solid;
  text-align: right;
}

.cancel-button {
  border: none;
  padding: 8px 24px;
  border-radius: 2px;
  font-size: 14px;
  color: #7f868a;
  margin-right: 10px;
  background: #ffffff;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #e8f0fe;
  color: #1a73e8;
}

.pay-code {
  text-align: center;
  padding: 40px 0;
}

.pay-code h1 {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}

.pay-code h2 {
  font-size: 16px;
  font-weight: 400;
}

.cancel-pay-button {
  background: #f1f3f4;
  font-size: 16px;
  padding: 8px 40px;
}

.pay-code-img {
  padding: 25px;
}

.pay-code-img img {
  width: 300px;
  height: 300px;
}

.pay-success {
  display: flex;
  min-height: 600px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pay-success h1 {
  padding-top: 20px;
  font-size: 24px;
  font-weight: 400;
}

.change-account {
  cursor: pointer;
}
.discount {
  margin: 0;
  padding: 4px;
  font-size: 12px;
}
</style>
