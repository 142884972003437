<template>
  <div class="bg">
    <div class="content">
      <h2 class="title">
        <slot name="title"></slot>
      </h2>
      <div class="describe">
        <slot></slot>
      </div>
      <div class="submit">
        <button class="submit-button" @click="$emit('cancel')">
          取消
        </button>
        <a-button type="primary" @click="$emit('ok')">
          立即续费
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopPay",
  emits: ["ok", "cancel"]
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}
.content {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(64, 64, 67, 0.3);
  min-width: 280px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.title {
  font-size: 16px;
  line-height: 24px;
  color: #3c4043;
  margin: 0;
  padding: 0 24px 14px 0;
  font-weight: 400;
}
.content {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #5f6368;
  padding: 10px 24px 10px 24px;
}
.submit {
  display: flex;
  justify-content: flex-end;
  min-height: 52px;
  margin: 0;
  padding: 8px;
}
.submit-button {
  color: #1a73e8;
  font-size: 14px;
  font-weight: 500;
  margin-right: 6px;
  border: none;
  border-radius: 2px;
  background: none;
  padding: 6px 20px;
  cursor: pointer;
}
.submit-button:hover {
  background-color: #e8f0fe;
}
</style>
