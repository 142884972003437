<template>
  <div class="reg-code">
    <div class="content">
      <div class="form">
        <header class="header">
          <img src="../../assets/img/logo.png" width="40" alt="logo" />
          <h2>验证您的注册邮箱</h2>
        </header>
        <label class="code-introduce">
          为了安全起见，我们希望确定是您本人在登录。我们向您的邮箱发送一个 6
          位数的验证码。
        </label>
        <label class="code-input">
          <input-outline
            type="small"
            default-title="邮箱验证码"
            check="code"
            v-model:input-value="code.value"
            :error="code.error"
            :error-tips="code.errorTips"
            ref="codeInput"
            v-on:check-result="checkCodeCorrect"
          />
        </label>
        <label class="code-tips">
          如果没收到验证码，请检查您的垃圾邮箱，或
          <a href="#" @click="sendMailAgain">重新发送</a>
        </label>
        <div class="submit">
          <z-button @click="checkCode">验证</z-button>
        </div>
      </div>
      <div class="pic">
        <img src="../../assets/img/reg-robot.png" width="244" alt="robot" />
      </div>
    </div>
  </div>
</template>

<script>
import InputOutline from "../../components/InputOutline.vue";
import ZButton from "../../components/ZButton.vue";
import { onMounted, reactive, ref } from "vue";
import { info } from "@/utils/api";
import { message } from "ant-design-vue";

export default {
  name: "RegCode",
  components: { ZButton, InputOutline },
  setup(props, { emit }) {
    const codeInput = ref(null);
    const code = reactive({
      value: "",
      error: false,
      errorTips: "",
      checkResult: false
    });
    async function checkCode() {
      console.log(codeInput.value);
      codeInput.value.checkInput();
      console.log(code.checkResult);
      if (code.checkResult) {
        info.checkMailCode(code.value).then(res => {
          console.log(res.data);
          if (res.data.code === 0) {
            message.success("验证成功");
            emit("success");
            emit("close");
          } else {
            message.warn(res.data.msg);
          }
        });
      } else {
        console.log("检查错误");
      }
    }
    function checkCodeCorrect(result) {
      code.checkResult = result;
    }
    function sendMailAgain() {
      info.sendMailCode().then(res => {
        if (res.data.code === 0) {
          message.success("验证已发送,请查收");
        } else {
          message.info(res.data.msg);
        }
      });
    }
    onMounted(() => {
      info.sendMailCode().then(res => {
        if (res.data.code === 0) {
          message.success("验证邮件已发送,请查收");
        } else {
          message.info(res.data.msg);
        }
      });
    });
    return {
      code,
      checkCode,
      codeInput,
      checkCodeCorrect,
      sendMailAgain
    };
  }
};
</script>

<style scoped>
.reg-code {
  text-align: left;
}

.header {
  color: #202124;
}

.header h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 10px 0;
}

.content {
  display: flex;
}

.form {
  padding: 0 40px 0 0;
}

.code-introduce {
  font-size: 12px;
  color: #7f868a;
}

.code-tips {
  font-size: 12px;
  color: #80868b;
  padding: 10px 0;
}

.code-tips a {
  color: #1d73e9;
}

.pic {
  padding: 20px 20px;
}
.submit {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.submit-link {
  flex: 1;
}
.submit-link a {
  color: #1d73e9;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
}
</style>
