<template>
  <loading v-if="loadingShow"></loading>
  <div class="info">
    <div class="account module">
      <h1>欢迎光临为海外电商运营而生的超级VPS管理器</h1>
      <h2>{{ $store.state.user.email }}</h2>
      <div class="operation">
        <a-button class="install-button" @click="openInstall" type="primary">
          下载最新版本
        </a-button>
        <div class="version-tips">
          <p>最新版本: {{ version.data }}</p>
          <p>
            使用版本:
            {{ $store.state.user.ver ? $store.state.user.ver : "历史版本" }}
          </p>
        </div>
      </div>
    </div>
    <div class="module">
      <h4>您当前的套餐:</h4>
      <p></p>
      <div class="vip-detail">
        <p>
          {{ $store.state.user.vipTitle }} |
          用户版 |
          到期时间: <b>{{ $store.state.user.vipTime }}</b>
        </p>
        <a-button
          @click="$router.push('/console/shop')"
          type="primary"
          class="vip-button"
          >升级</a-button
        >
        <a-button
          type="primary"
          @click="getRenewList"
          v-if="
            Number($store.state.user.vip) > 0 &&
              Number($store.state.user.vip) < 99
          "
          >续费</a-button
        >
      </div>
    </div>
    <div class="now module">
      <span class="title">当前账号在线状态</span>
      <h2>{{ $store.state.user.status.online === "1" ? "在线" : "不在线" }}</h2>
      <p>
        <span v-if="$store.state.user.status.online === 1">登录IP: </span>
        <span v-if="!($store.state.user.status.online === 1)">最近登录IP: </span
        >{{ $store.state.user.status.ip }} 上次登录时间:
        {{ $store.state.user.status.login_time }}
      </p>
      <div class="operation">
        <a-button @click="testOnline" type="primary">刷新状态</a-button>
        <a-button
          v-if="$store.state.user.status.online === '1'"
          @click="forceOffline"
          type="link"
        >
          强制设备下线
        </a-button>
      </div>
    </div>
    <div class="module">
      <span class="title"
        >您的邀请码和邀请链接，邀请朋友成为超级VPS的伙伴可以获得奖励哦</span
      >
      <h1>{{ $store.state.user.inviteCode }}</h1>
      <label class="label-block">
        <input
          id="link"
          class="link"
          type="text"
          :value="
            'https://www.chaojivps.com/aff/?code=' +
              $store.state.user.inviteCode
          "
          readonly
        />
      </label>
      <a-button @click="copyLink" type="primary">复制邀请链接到剪贴板</a-button>
    </div>
    <div class="module">
      <span class="title">有疑问？您可以咨询我们的技术团队</span>
      <div class="module-flex">
        <div class="wechat">
          <p>客服 (企业微信)</p>
          <p>
            <img src="../../assets/img/kefu.png" width="200" alt="ehouniao" />
          </p>
        </div>
        <div class="wechat">
          <p>商务合作：chaojivps</p>
          <p>
            <img
              src="../../assets/img/shangwu.png"
              width="200"
              alt="chaojivps"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
  <popup v-show="popShow" @confirm="next">
    <template v-slot:title>
      下线成功
    </template>
    <template v-slot:describe>
      您可以尝试重新登录超级VPS客户端，问题如果没有解决，请尝试重新操作或联系客服。
    </template>
  </popup>
  <pop-pay v-show="popPayShow" @ok="renew" @cancel="popPayShow = false">
    <div class="renew-title">
      <h1>续费超级VPS套餐</h1>
    </div>
    <h4>续费时间</h4>
    <ul class="months">
      <li
        v-for="item in renewList"
        v-bind:key="item.id"
        :class="{ active: item.days === activeDays }"
        @click="changeActive(item.days, item.price_discount)"
      >
        <h2>{{ item.title }}</h2>
        <h3>￥{{ item.price_discount }}</h3>
        <p v-if="item.price > item.price_discount">
          节省 {{ item.price - item.price_discount }} 元
        </p>
      </li>
    </ul>
    <h4>支付方式</h4>
    <ul class="payments">
      <li
        v-for="(payment, index) in payments"
        v-bind:key="payment.id"
        @click="changeActivePay(payment.id, index)"
        :class="{ active: payment.id === paymentActiveId }"
      >
        {{ payment.name }}
      </li>
    </ul>
    <loading v-if="payPopLoading"></loading>
  </pop-pay>
  <!--弹出二维码支付订单-->
  <order-pay
    v-if="payInfo.show"
    :pic-url="payInfo.imgUrl"
    :order-no="payInfo.orderNo"
    :pay-model="payInfo.payModel"
    @cancel="payInfo.show = false"
    @success="payInfo.show = false"
  ></order-pay>
</template>

<script>
import Popup from "../../components/Popup.vue";
import PopPay from "@/components/PopPay";
import { buy, client, info } from "@/utils/api";
import Loading from "../../components/Loading.vue";
import { ref, reactive } from "vue";
import { message } from "ant-design-vue";
import OrderPay from "@/components/OrderPay";

export default {
  name: "ConsoleInfo",
  components: { OrderPay, Loading, Popup, PopPay },
  data() {
    return {
      popShow: false,
      interId: "",
      version: {}
    };
  },
  setup() {
    // 切换续费时间
    const activeDays = ref("180");
    const activePrice = ref(null);
    function changeActive(days, price) {
      activeDays.value = days;
      activePrice.value = price;
    }
    const loadingShow = ref(false);
    const renewList = ref([]);
    const payments = ref([]);
    const paymentActiveId = ref(null);

    function changeActivePay(id, index) {
      paymentActiveId.value = id;
      payInfo.payModel = index;
    }

    const popPayShow = ref(false);
    async function getRenewList() {
      loadingShow.value = true;
      await buy.renewList().then(res => {
        loadingShow.value = false;
        if (res.data.code === 0) {
          renewList.value = res.data.data.itemlist;
          payments.value = res.data.data.payment.slice(0, 2);
          paymentActiveId.value = payments.value[0].id;
          activePrice.value = renewList.value[1].price;
          popPayShow.value = true;
        } else {
          message.warn(res.data.msg);
        }
      });
    }

    const payInfo = reactive({
      orderNo: "",
      imgUrl: "",
      show: false,
      payModel: 0
    });

    /**
     * 续费
     */
    const payPopLoading = ref(false);
    async function renew() {
      payPopLoading.value = true;
      // 开始支付
      await buy.renew(activeDays.value, paymentActiveId.value).then(res => {
        payInfo.imgUrl = res.data.data.payurl;
        payInfo.orderNo = res.data.data.order_no;
        popPayShow.value = false;
        payPopLoading.value = false;
        payInfo.show = true;
      });
    }
    return {
      getRenewList,
      popPayShow,
      renewList,
      activeDays,
      activePrice,
      changeActive,
      loadingShow,
      payments,
      paymentActiveId,
      renew,
      payInfo, // 支付信息
      changeActivePay,
      payPopLoading
    };
  },
  methods: {
    forceOffline() {
      client.forceOffline().then(res => {
        if (res.data.code === 0) {
          this.popShow = true;
        }
      });
    },
    next() {
      this.popShow = false;
    },
    testOnline() {
      this.loadingShow = true;
      client.getStatus().then(res => {
        console.log(res);
        this.loadingShow = false;
        if (res.data.code === 0) {
          this.$store.commit("updateStatus", res.data.data);
        }
      });
    },
    copyLink() {
      const link = document.getElementById("link");
      link.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
    },
    async getVersion() {
      this.version = await info.version().then(res => res.data);
    },
    openInstall() {
      window.open("https://www.chaojivps.com/download.php", "_blank");
    }
  },
  mounted() {
    this.testOnline();
    this.getVersion().then();
  }
};
</script>

<style scoped>
.module {
  padding: 20px 0;
  border-bottom: 1px #dadce0 dashed;
}
.title {
  font-size: 14px;
}
.account h1 {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}
.module h2 {
  font-size: 24px;
  font-weight: 400;
  padding: 5px 0;
}
.module h1 {
  font-size: 28px;
  font-weight: 400;
  padding: 10px 0;
  margin: 0;
}
.tips {
  font-size: 14px;
}
.client-test {
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
}
.client-test:hover {
  color: #1a73e8;
  background-color: #e8f0fe;
}
.label-block {
  display: block;
}
.link {
  font-size: 14px;
  padding: 10px 20px;
  width: 400px;
  border: 1px #eceff1 solid;
  border-radius: 2px;
}
.module-flex {
  display: flex;
}
.wechat {
  padding-right: 40px;
}
.operation {
  display: flex;
}
.install-button {
  margin-left: 10px;
}
.version-tips {
  line-height: 18px;
  margin-left: 10px;
  margin-top: 1px;
}
.version-tips p {
  padding: 0;
  margin: 3px 0 0 0;
}
ul.months {
  display: flex;
  margin: 0;
  padding: 10px 0 20px 0;
}
ul.months li {
  list-style: none;
  padding: 20px 60px 5px 10px;
  background: #f1f5fa;
  border-radius: 4px;
  margin: 0 20px 0 0;
}
ul.months li:last-child {
  margin-right: 0;
}
ul.months li h2 {
  color: #1a73e8;
}
ul.months li h3 {
  color: #1a73e8;
}
ul.months li.active {
  background: #1a73e8;
  color: #ffffff;
}
ul.months li.active h2 {
  color: #ffffff;
}
ul.months li.active h3 {
  color: #ffffff;
}
ul.months li:hover {
  background: #1a73e8;
  color: #ffffff;
  cursor: pointer;
}
ul.months li:hover h2 {
  color: #ffffff;
}
ul.months li:hover h3 {
  color: #ffffff;
}
ul.payments {
  margin: 0;
  padding: 10px 0;
  display: flex;
}
ul.payments li {
  list-style: none;
  padding: 10px 30px;
  background: #f1f5fa;
  border-radius: 4px;
  margin: 0 20px 0 0;
}
ul.payments li.active {
  background: #1a73e8;
  color: #ffffff;
}
ul.payments li:hover {
  background: #1a73e8;
  color: #ffffff;
  cursor: pointer;
}
.vip-button {
  margin-right: 10px;
}
.renew-title {
  border-bottom: 1px #f0f0f0 solid;
  margin-bottom: 10px;
}
.renew-title h1 {
  font-size: 18px;
}
</style>
