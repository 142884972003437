<template>
  <loading v-if="loadingShow"></loading>
  <div class="reg-form">
    <div class="content">
      <div class="form">
        <header class="header">
          <div class="logo-link">
            <img src="../../assets/img/logo.png" width="40" alt="logo" />
            <a href="https://www.chaojivps.com/">返回首页</a>
          </div>
          <h2>创建您的超级VPS账号</h2>
          <!--用户协议-->
          <div class="agreement">
            创建超级VPS账号即代表您同意
            <a
              href="https://www.chaojivps.com/console/agreement"
              target="_blank"
              >超级VPS用户服务协议</a
            >
          </div>
        </header>
        <label class="email">
          <input-outline
            ref="email"
            type="small"
            default-title="邮箱地址"
            v-model:input-value="email.value"
            check="email"
            :error="email.error"
            :error-tips="email.errorTips"
            v-on:check-result="checkEmail"
          />
        </label>
        <label class="email-tips">
          我们会向您的邮箱发送邮件, 请保证邮箱可以正常接收邮件
        </label>
        <div class="password-container">
          <label class="password">
            <input-outline
              ref="password"
              check="password"
              type="small"
              default-title="密码"
              v-model:input-value="password.value"
              :error="password.error"
              :error-tips="password.errorTips"
              v-on:check-result="checkPassword"
            />
          </label>
          <label class="password-confirm">
            <input-outline
              type="small"
              check="password"
              default-title="确认密码"
              v-model:input-value="passwordConfirm.value"
              @focusout="checkCorrect"
              :error="passwordConfirm.error"
              :error-tips="passwordConfirm.errorTips"
            />
          </label>
        </div>
        <div class="password-tips">
          使用8个或更多字符 (字母、数字、符号的组合)
        </div>
		<label class="mobile">
		  <input-outline
		    ref="mobile"
		    type="small"
		    default-title="手机号码"
		    v-model:input-value="mobile.value"
		    check="mobile"
		    :error="mobile.error"
		    :error-tips="mobile.errorTips"
		    v-on:check-result="checkMobile"
		  />
		</label>
		<div class="mobile-tips">
		  填写中国手机号码
		</div>
        <div class="contact-container">
          <label class="contact">
            <a-dropdown class="contact-dropdown">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <span class="contact-span">
                  {{ connect.name }}
                </span>
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu @click="changeContactMode">
                  <a-menu-item key="weixin">
                    微信
                  </a-menu-item>
                  <a-menu-item key="skype">
                    skype
                  </a-menu-item>
                  <a-menu-item key="telegram">
                    Telegram
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <input type="text" class="contact-input" v-model="connect.value" />
          </label>
        </div>
        <div class="invite-tips">
          填写您的联系方式，skype或微信或telegram(可以为空)
        </div>
        <label class="invite">
          <input-outline
            ref="invitationCode"
            type="small"
            default-title="邀请码"
            v-model:input-value="invitationCode.value"
            check="invite"
            :error="invitationCode.error"
            :error-tips="invitationCode.errorTips"
            v-on:check-result="checkInvite"
          />
        </label>
        <div class="invite-tips">
          填写4位数字邀请码 (可以为空)
        </div>

        <div class="submit">
          <div class="submit-link">
            <router-link to="/">登录现有账号</router-link>
          </div>
          <z-button @click="reg">下一步</z-button>
        </div>
      </div>
      <div class="pic">
        <img src="../../assets/img/reg-robot.png" width="244" alt="robot" />
      </div>
    </div>
  </div>
</template>

<script>
import InputOutline from "../../components/InputOutline.vue";
import ZButton from "../../components/ZButton.vue";
import Loading from "../../components/Loading.vue";

import { DownOutlined } from "@ant-design/icons-vue";

import { user } from "@/utils/api";

export default {
  name: "RegForm",
  props: {
    code: {
      type: String,
      default: ""
    }
  },
  components: { Loading, ZButton, InputOutline, DownOutlined },
  data() {
    return {
      email: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false
      },
      mobile: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false
      },
      invitationCode: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false
      },
      password: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false
      },
      passwordConfirm: {
        value: "",
        error: false,
        errorTips: ""
      },
      connect: {
        value: "",
        error: false,
        errorTips: "",
        mode: "weixin",
        name: "微信"
      },
      loadingShow: false
    };
  },
  methods: {
    async reg() {
      console.log(this.connect.mode + ":" + this.connect.value);
      // 调用类型检查
      this.$refs.email.checkInput();
      this.$refs.password.checkInput();
      this.$refs.invitationCode.checkInput();
	  this.$refs.mobile.checkInput();
      this.checkCorrect();
      // 添加联系方式
      const connect = this.connect.mode + ":" + this.connect.value;
      // 打开loading
      this.loadingShow = true;
      if (
        this.password.checkResult &&
        this.invitationCode.checkResult &&
        this.email.checkResult &&
        this.mobile.checkResult &&
        this.checkCorrect()
      ) {
        const regData = await user
          .reg(
            this.email.value,
            this.password.value,
            this.mobile.value,
            this.invitationCode.value,
            connect
          )
          .then(res => res.data);
        // 关闭loading
        this.loadingShow = false;
        switch (regData.code) {
          case 0:
            // :TODO 注册成功
            // 提交store
            // this.$store.commit("login", regData.data);
            await this.$router.push(`/reg/success?mail=${this.email.value}`);
            break;
          case -3:
            this.email.error = true;
            this.email.errorTips = regData.msg;
            break;
          case -4:
            this.password.error = true;
            this.password.errorTips = regData.msg;
            break;
          case -7:
            this.mobile.error = true;
            this.mobile.errorTips = regData.msg;
            break;
          default:
            break;
        }
        if (regData.code === 0) {
          this.loadingShow = false;
        } else {
          this.loadingShow = false;
          this.email.error = true;
          this.email.errorTips = regData.msg;
        }
      } else {
        this.loadingShow = false;
        return false;
      }
    },
    // 检测密码是否一致
    checkCorrect() {
      if (this.password.value === this.passwordConfirm.value) {
        return true;
      } else {
        this.passwordConfirm.error = true;
        this.passwordConfirm.errorTips = "两次输入的密码不一致";
        return false;
      }
    },
    checkEmail(result) {
      this.email.checkResult = result;
    },
    checkPassword(result) {
      this.password.checkResult = result;
    },
    checkMobile(result) {
      this.mobile.checkResult = result;
    },
    checkInvite(result) {
      this.invitationCode.checkResult = result;
    },
    compareCode() {
      if (this.code !== "") {
        this.invitationCode.value = this.code;
        this.$store.commit("putCode", this.code);
      } else if (localStorage.getItem("code") !== null) {
        this.invitationCode.value = localStorage.getItem("code");
      } else {
        this.invitationCode.value = "";
      }
    },
    changeContactMode({ key }) {
      if (key === "weixin") {
        this.connect.mode = "weixin";
        this.connect.name = "微信";
      } else {
        this.connect.mode = "skype";
        this.connect.name = "skype";
      }
      switch (key) {
        case "weixin":
          this.connect.mode = "weixin";
          this.connect.name = "微信";
          break;
        case "skype":
          this.connect.mode = "skype";
          this.connect.name = "skype";
          break;
        case "telegram":
          this.connect.mode = "telegram";
          this.connect.name = "Telegram";
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.compareCode();
    });
  }
};
</script>

<style scoped>
.agreement {
  font-size: 12px;
  color: #7f868a;
  padding-bottom: 10px;
}
.agreement a {
  color: #7f868a;
  text-decoration: underline;
}
.reg-form {
  text-align: left;
}
.header {
  color: #202124;
}

.header h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 10px 0 4px 0;
}
.content {
  display: flex;
}
.form {
  padding: 0 50px 0 0;
}
.email-tips {
  font-size: 12px;
  color: #7f868a;
  padding: 5px 0;
}
.password-container {
  display: flex;
}
.password {
  margin-right: 20px;
}
.password-tips {
  font-size: 12px;
  color: #7f868a;
  padding: 5px 0;
}
.invite-tips {
  font-size: 12px;
  color: #7f868a;
  padding: 5px 0;
}
.mobile-tips {
  font-size: 12px;
  color: #7f868a;
  padding: 5px 0;
}
.pic {
  padding: 20px 20px;
}
.submit {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.submit-link {
  flex: 1;
}
.submit-link a {
  color: #1d73e9;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
}
.logo-link {
  display: flex;
  align-items: center;
}
.logo-link a {
  padding-left: 10px;
  font-size: 14px;
  color: #7f868a;
}
@media only screen and (max-width: 800px) {
  .pic {
    display: none;
  }
  .form {
    padding-right: 0;
  }
}
.contact-container {
  margin-top: 5px;
}
.contact {
  display: flex;
  border: 1px #dadce0 solid;
  padding: 6px 8px;
  font-size: 15px;
  width: 100%;
  border-radius: 4px;
}
.contact-dropdown {
  margin-left: 5px;
  width: 60px;
  display: flex;
  align-items: center;
}
.contact-dropdown .contact-span {
  display: block;
  flex: 1;
}
.contact .contact-input {
  padding: 0;
  border: 0;
  margin-left: 10px;
  background: none;
  flex: 1;
  border-left: 1px #dadce0 solid;
}
</style>
