<template>
  <loading v-if="loadingShow"></loading>
  <h2 class="title">购买或升级适合您的超级VPS</h2>
  <div class="vip-introduce">
    <!--未购买套餐介绍-->
    <h5 class="title-l">会员价格499元/月起</h5>
    <p class="tips">
      超级VPS管理器免费提供安全远程桌面服务。
      购买超级VPS，即表示您同意接受《超级VPS服务条款》。
    </p>
    <div class="back">
      <router-link to="/console/old_shop" v-if="$store.state.user.isOld === 1"
        >查看旧版套餐</router-link
      >
    </div>
  </div>
  <div class="items">
    <!--个人无限版-->
    <div class="item color">
      <div class="item-content">
        <div class="top">
          <h2>{{ products.singlePlus.name }}</h2>
          <p>适用于个人</p>
          <div class="price">
            <!--推荐商品-->
            <button
              class="price-button"
              v-if="products.singlePlus.recommend[0]"
              @click="
                openProductsSelect(
                  products.singlePlus,
                  0,
                  products.singlePlus.recommend[0].title
                )
              "
            >
              {{ products.singlePlus.recommend[0].price }}元 /
              {{ products.singlePlus.recommend[0].title }}
            </button>
            <p v-if="$store.state.user.discountInfo.tip" class="discount">
              {{ $store.state.user.discountInfo.tip }}
            </p>
          </div>
          <div class="other">
            <h5>或按照其它周期付费</h5>
            <div class="recommend-list" v-if="products.singlePlus.groups[0]">
              <button
                v-for="item in products.singlePlus.groups[0]
                  .filter(item => item.is_recommend !== '1')
                  .slice(0, 2)"
                :key="item.id"
                class="other-button"
                @click="openProductsSelect(products.singlePlus, 0, item.title)"
              >
                {{ item.price }}元 / {{ item.title }}
              </button>
            </div>
          </div>
        </div>
        <div class="bottom">
          <ul>
            <li
              v-for="(des, index) in products.singlePlus.desc"
              v-bind:key="index"
            >
              {{ des }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="item-content">
        <div class="top">
          <h2>{{ products.team.name }}</h2>
          <p>适用于团队</p>
          <div class="price">
            <button
              class="price-button"
              v-if="products.team.recommend[0]"
              @click="
                openProductsSelect(
                  products.team,
                  products.team.recommend[0].give_son_num,
                  products.team.recommend[0].title
                )
              "
            >
              {{ products.team.recommend[0].give_son_num }}用户 /
              {{ products.team.recommend[0].price }}元 /
              {{ products.team.recommend[0].title }}
            </button>
            <p v-if="$store.state.user.discountInfo.tip" class="discount">
              {{ $store.state.user.discountInfo.tip }}
            </p>
          </div>
          <div class="other">
            <h5>或选择优惠套餐</h5>
            <div class="recommend-list" v-if="products.team.recommend">
              <button
                class="other-button"
                v-for="item in products.team.recommend.slice(1, 3)"
                :key="item.id"
                @click="
                  openProductsSelect(
                    products.team,
                    item.give_son_num,
                    item.title
                  )
                "
              >
                {{ item.give_son_num }}用户 / {{ item.price }}元 /
                {{ item.title }}
              </button>
            </div>
          </div>
        </div>
        <div class="bottom">
          <ul>
            <li v-for="(des, index) in products.team.desc" v-bind:key="index">
              {{ des }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--环境包-->
    <div class="item">
      <div class="item-content">
        <div class="top">
          <h2>专属定制</h2>
          <p>更多用户，更多环境</p>
          <div class="price">
            <button class="price-button" @click="contactService">
              联系客服
            </button>
            <p v-if="$store.state.user.discountInfo.tip" class="discount">
              {{ $store.state.user.discountInfo.tip }}
            </p>
          </div>
          <div class="other">
            <h5>更多定制服务请联系客服咨询</h5>
            <button class="other-button" @click="contactService">
              自动化服务
            </button>
            <button class="other-button" @click="contactService">
              环境包套餐
            </button>
          </div>
        </div>
        <div class="bottom">
          <ul>
            <li>20+用户</li>
            <li>10000+可定制浏览器环境</li>
            <li>数据云端同步</li>
            <li>多线程操作</li>
            <li>可选自动化服务</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="tips">
    注：如果以上套餐的浏览器环境数量未能满足您的需求，请联系<a
      @click="contactService"
      href="#"
      >微信客服</a
    >单独购买环境套餐包！
  </div>
  <!--产品选择-->
  <products-shop
    :products="productsSelect"
    :pays="pays"
    :focus="focus"
    v-if="productsShopShow"
    @cancel="productsShopShow = false"
  ></products-shop>
  <!--弹出客服-->
  <service
    v-if="serviceInfo.show"
    @cancel="serviceInfo.show = false"
    :tips="serviceInfo.tips"
  ></service>
</template>

<script>
import { user } from "@/utils/api";
import { onMounted, ref, reactive } from "vue";
import Loading from "../../components/Loading.vue";
import { message } from "ant-design-vue";
import { calcPrice } from "@/utils/price";
import ProductsShop from "@/components/ProductsShop";
import Service from "@/components/Service";

export default {
  name: "ConsoleShop",
  components: { Service, ProductsShop, Loading },
  setup() {
    // 焦点
    const focus = reactive({
      group: 0,
      title: 0
    });
    const loadingShow = ref(false);
    /**
     * 新处理逻辑
     */
    // 支付方式
    const pays = ref([]);
    function setPays(payment) {
      const [wechat = {}, alipay = {}] = payment;
      pays.value = [wechat, alipay];
    }
    // 获得商品
    async function getProducts() {
      await user.getPriceList().then(res => {
        if (res.data.code === 0) {
          handleItemList(res.data.data.itemlist);
          setPays(res.data.data.payment);
          loadingShow.value = false;
        } else {
          message.error(res.data.msg);
        }
      });
    }
    const products = reactive({
      single: {
        name: "个人版",
        vip: "1",
        desc: [],
        groups: []
      },
      singlePlus: {
        name: "个人无限版",
        vip: "2",
        groups: [],
        desc: [],
        recommend: []
      },
      team: {
        name: "团队协作版",
        vip: "3",
        groups: [],
        desc: [],
        recommend: []
      },
      session: {}
    });
    // 映射商品列表,根据商品子用户数量为商品分组
    function handleItemList(itemList) {
      const {
        singleplus: singlePlus = {},
        newteam: team = {},
        session = {}
      } = itemList;
      // 个人无限版
      products.singlePlus.name = singlePlus.name;
      products.singlePlus.vip = singlePlus.vip;
      products.singlePlus.desc = singlePlus.desc;
      products.singlePlus.groups = groups(singlePlus.data);
      products.singlePlus.recommend = findRecommendProducts(
        products.singlePlus.groups
      );
      // 团队版
      products.team.name = team.name;
      products.team.vip = team.vip;
      products.team.desc = team.desc;
      products.team.groups = groups(team.data);
      products.team.recommend = findRecommendProducts(products.team.groups);
      // 环境包
      products.session = session;
    }
    // 依据商品子用户数量为商品分组
    function groups(items) {
      const groups = {};
      items.forEach(item => {
        const group = item.give_son_num;
        groups[group] = groups[group] || [];
        groups[group].push(item);
      });
      return Object.keys(groups).map(group => groups[group]);
    }
    // 找出推荐商品
    function findRecommendProducts(groups) {
      const recommend = [];
      for (const key in groups) {
        groups[key].forEach(item => {
          if (item.is_recommend === "1") {
            recommend.push(item);
          }
        });
      }
      return recommend;
    }

    // 打开产品选择
    const productsShopShow = ref(false);
    const productsSelect = ref(null);
    function openProductsSelect(products, group, title) {
      productsSelect.value = products;
      focus.group = group;
      focus.title = title;
      productsShopShow.value = true;
    }

    // 联系客服
    const serviceInfo = reactive({
      show: false,
      tips: ""
    });
    function contactService() {
      serviceInfo.tips = "专属定制，为您所想，请扫描二维码联系客服";
      serviceInfo.show = true;
    }

    onMounted(() => {
      loadingShow.value = true;
      getProducts().then();
    });
    return {
      products, // 产品列表
      loadingShow,
      focus,
      calcPrice,
      contactService,
      getProducts,
      openProductsSelect,
      productsSelect,
      productsShopShow,
      serviceInfo, // 联系客服
      pays // 支付方式
    };
  }
};
</script>

<style scoped>
.title {
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0;
}

.title-l {
  font-size: 20px;
  font-weight: 400;
  padding: 10px 0;
  margin: 10px 0;
}

.tips {
  font-size: 14px;
  color: #7f868a;
}

.item {
  width: 260px;
  box-shadow: 0 1px 6px rgba(60, 64, 67, 0.3);
  border-radius: 8px;
  background: #ffffff;
  margin: 12px 24px 12px 12px;
  text-align: center;
}

.color {
  border: 4px #1d73e9 solid;
}

.items {
  padding-top: 40px;
  display: flex;
}

.top {
  min-height: 200px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  text-align: center;
  word-break: break-word;
}

.top h2 {
  margin: 50px 0 0 0;
  font-size: 26px;
  font-weight: 400;
}

.price-button {
  margin: 30px 0 0 0;
  min-width: 143px;
  color: #1d73e9;
  background: #ffffff;
  border: 1px #dadce0 solid;
  border-radius: 4px;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.price-button:hover {
  background: #e8f0fe;
  color: #2065ca;
}

.other {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.other h5 {
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0;
}

.other-button {
  display: block;
  border: none;
  border-radius: 4px;
  padding: 8px 24px;
  font-size: 14px;
  color: #1d73e9;
  font-weight: 400;
  background: none;
  cursor: pointer;
}

.other-button:hover {
  background: #e8f0fe;
  color: #2065ca;
}

.bottom {
  text-align: left;
  margin: 20px;
  padding: 20px 0;
  border-top: 1px #dadce0 solid;
}

.bottom h6 {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.bottom ul {
  padding: 0;
}

.bottom ul li {
  list-style: none;
  font-size: 14px;
  padding: 5px 0;
}

.color .price-button {
  background-color: #1d73e9;
  color: #ffffff;
  border: none;
}

.color .price-button:hover {
  background-color: #2065ca;
}

.null .price-button {
  color: #7f868a;
  font-weight: 400;
}

.null .price-button:hover {
  background-color: #f0f0f0;
}

.null .other {
  color: #dadce0;
}

.null .other-button {
  color: #dadce0;
}

.null .other-button:hover {
  background: #ffffff;
}
.discount {
  font-size: 12px;
  color: #fa8c16;
  font-weight: 400;
  padding-top: 10px;
  margin: 0;
}
.orange {
  padding: 4px 0;
  font-size: 12px;
  color: #fa8c16;
  font-weight: 400;
}
.vip-info {
  padding-top: 10px;
  border-top: 1px #eceff1 solid;
}
.activity-tips {
  border-top: 1px #f1f5fa solid;
  margin-top: 40px;
  padding-top: 20px;
}
.activity-tips ul {
  margin: 0;
  padding: 0;
}
.activity-tips ul li {
  list-style: none;
}
.recommend-list {
  text-align: center;
}
.tips {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px #dedede solid;
}
</style>
