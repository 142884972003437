<template>
  <div class="account">
    <header class="header">
      <h3>子账户</h3>
      <p>您可以使用候鸟的团队协作套餐管理您的团队账户</p>
      <a-button type="primary" @click="addSon" class="add">添加子账户</a-button>
      <span
        >您还可以开通 <b>{{ sonAccount }}</b> 个子账户
      </span>
    </header>
    <a-table
      class="account-table"
      :columns="columns"
      :row-key="record => record.id"
      :data-source="accountList"
      :loading="loading"
    >
      <template #check="{ record }">
        <router-link
          :to="{
            path: '/console/account/id/' + record.id,
            query: { email: record.email }
          }"
        >
          查看
        </router-link>
        <a-button
          type="link"
          @click="setChangePassword(record.id, record.email)"
          >修改密码</a-button
        >
        <a-popconfirm
          title="您确定要删除这个子用户么？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="delSon(record.id)"
        >
          <a-button type="link">
            删除
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>
  </div>
  <ModalForm
    v-if="modalShow"
    v-on:close="modalShow = false"
    v-on:refresh="refreshList"
  ></ModalForm>
  <!--修改密码-->
  <a-modal
    :title="'修改' + changePassword.account + '的密码'"
    v-model:visible="changePassword.show"
    :confirm-loading="changePassword.loading"
    @ok="submitChangePassword"
  >
    <a-input v-model:value="changePassword.password"></a-input>
  </a-modal>
</template>

<script>
import ModalForm from "@/components/ModalForm";
import { user } from "@/utils/api";
import { ref, onMounted, reactive } from "vue";
import { message, Modal } from "ant-design-vue";
import { useRouter } from "vue-router";

export default {
  name: "ConsoleAccountSonList",
  components: { ModalForm },
  setup() {
    const router = useRouter();
    const columns = ref([
      {
        title: "ID",
        key: "id",
        dataIndex: "id"
      },
      {
        title: "账户名",
        key: "account",
        dataIndex: "email"
      },
      {
        title: "创建时间",
        key: "time",
        dataIndex: "add_time"
      },
      {
        title: "环境数量",
        key: "session",
        dataIndex: "session_num",
        slots: { title: "环境数量", customRender: "session" }
      },
      {
        title: "操作",
        key: "check",
        slots: { customRender: "check" }
      }
    ]);
    const modalShow = ref(false);
    const loading = ref(false);
    const accountList = ref([]);
    // 成功展示
    const successShow = ref(false);
    // 警告展示
    const warningShow = ref(false);
    // 剩余子账户数量
    const sonAccount = ref(0);
    async function getSonList() {
      loading.value = true;
      await user.getGroupList().then(res => {
        if (res.data.code === 0) {
          accountList.value = res.data.data.list;
          sonAccount.value = res.data.data.son_num;
        } else {
          // :TODO 请求失败处理
        }
      });
      loading.value = false;
    }
    // 刷新子账户列表
    function refreshList() {
      getSonList().then();
    }
    // 页面加载时调用拉取子账户列表接口
    onMounted(() => {
      getSonList().then();
    });
    // 删除子账户
    async function delSon(id) {
      await user.delSon(id).then(res => {
        if (res.data.code === 0) {
          message.success("删除成功");
          getSonList().then();
        } else {
          //:TODO 删除子账户失败
        }
      });
    }
    /**
     * 添加子账户
     * @return {Promise<void>}
     */
    async function addSon() {
      // 判断是否还有子账户
      // 没有子账户弹出是否购买
      if (Number(sonAccount.value) <= 0) {
        Modal.confirm({
          title: "您已经没有剩余的子账户",
          content: "您可以联系客服购买更多的子账户，或者重新管理您的子账户.",
          okText: "去购买",
          cancelText: "取消",
          onOk() {
            router.push("/console/shop").then();
          },
          onCancel() {}
        });
      } else {
        modalShow.value = true;
      }
    }

    /**
     * 修改子账户密码
     */
    function setChangePassword(id, account) {
      changePassword.id = id;
      changePassword.show = true;
      changePassword.account = account;
    }
    const changePassword = reactive({
      show: false,
      password: "",
      loading: false,
      id: "",
      account: ""
    });
    async function submitChangePassword() {
      changePassword.loading = true;
      await user
        .changeSonPassword(changePassword.id, changePassword.password)
        .then(res => {
          changePassword.loading = false;
          if (res.data.code === 0) {
            message.success(res.data.msg);
            changePassword.show = false;
          } else {
            message.warn(res.data.msg);
          }
        });
    }

    return {
      addSon,
      modalShow,
      accountList,
      loading,
      columns,
      sonAccount,
      refreshList,
      delSon,
      successShow,
      warningShow,
      changePassword, // 修改密码
      submitChangePassword,
      setChangePassword
    };
  }
};
</script>

<style scoped>
.header h3 {
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0;
}

.header p {
  margin: 0;
  padding-bottom: 20px;
}

.account-table {
  margin-top: 20px;
}

.add {
  margin-right: 20px;
}
</style>
