<template>
  <loading v-if="loadingShow"></loading>
  <header class="header">
    <h3>订单</h3>
    <div class="space"></div>
    <a-button @click="getBills">刷新订单</a-button>
  </header>
  <div class="content">
    <ul>
      <li class="title">
        <span class="order-no"> <b>订单号</b></span>
        <span class="order-product">
          <b>订购产品</b>
        </span>
        <span class="order-price"> <b>金额</b></span>
        <span class="order-time"> <b>创建时间</b></span>
        <span class="order-state">
          <b>状态</b>
        </span>
      </li>
      <li v-for="bill in billsList" :key="bill.id">
        <span class="order-no">
          <b>{{ bill.order_no }}</b>
        </span>
        <span class="order-product">
          <div class="order-product-inner">
            <b>{{ bill.item.category }} {{ bill.item.title }} </b>
            <a-tooltip class="order-tooltip" v-if="bill.item.tips !== ''">
              <template #title>{{ bill.item.tips }}</template>
              <InfoCircleOutlined />
            </a-tooltip>
          </div>
        </span>
        <span class="order-price">
          <b>{{ bill.total_price }}</b>
        </span>
        <span class="order-time">
          <b>{{ bill.add_time }}</b>
        </span>
        <span class="order-state">
          <a-button
            size="small"
            type="primary"
            v-if="bill.status === -3"
            @click="getQrCode(bill.order_no)"
            >立即付款</a-button
          >
          <b v-else
            >{{ bill.status }} {{ bill.pay_time ? bill.pay_time : "" }}</b
          >
        </span>
      </li>
    </ul>
  </div>
  <div class="bg" v-if="qrcodeShow">
    <div class="qr-code" ref="qrcodeTarget">
      <h3>请使用微信或支付宝扫码支付</h3>
      <div class="qr-image-container">
        <img
          :src="
            'https://admin.chaojivps.com/api/qrcode/show?url=' +
              encodeURI(qrCodeImage)
          "
          alt="qr-code"
          class="qr-image"
        />
        <div class="qr-icon">
          <img src="../../assets/img/logo.png" width="40" alt="" />
        </div>
      </div>

      <div class="info">
        <p>
          套餐: <b>{{ qrInfo.title }}</b>
        </p>
        <p>
          订单号: <b>{{ qrInfo.order }}</b>
        </p>
        <p>
          金额: <b>{{ qrInfo.price }}</b>
        </p>
        <p>
          创建日期: <b> {{ qrInfo.time }}</b>
        </p>
      </div>
    </div>
    <h2 class="tips">点击空白处关闭</h2>
  </div>
</template>

<script>
import { buy, user } from "@/utils/api";

import { ref, onMounted, reactive } from "vue";
import Loading from "../../components/Loading.vue";
import { onClickOutside } from "@vueuse/core";
import { InfoCircleOutlined } from "@ant-design/icons-vue";

export default {
  name: "ConsoleBills",
  components: { Loading, InfoCircleOutlined },
  setup() {
    const loadingShow = ref(false);
    const billsList = ref([]);
    async function getBills() {
      loadingShow.value = true;
      const billsData = await user.getOrderList().then(res => res.data);
      loadingShow.value = false;
      if (billsData.code === 0) {
        console.log(billsData.data);
        billsList.value = billsData.data;
      } else {
        //:TODO 重新获取
      }
    }

    const qrcodeShow = ref(false);
    const qrCodeImage = ref("");
    const qrInfo = reactive({
      title: "",
      price: "",
      time: "",
      order: ""
    });
    const qrcodeTarget = ref(null);
    async function getQrCode(orderNumber) {
      await buy.getQrCode(orderNumber).then(res => {
        console.log(res.data);
        qrCodeImage.value = res.data.data.qrcode;
        qrInfo.title = res.data.data.vipname;
        qrInfo.price = res.data.data.total_price;
        qrInfo.order = res.data.data.order_no;
        qrInfo.time = res.data.data.add_time;
        qrcodeShow.value = true;
      });
    }
    onClickOutside(qrcodeTarget, () => {
      qrcodeShow.value = false;
      getBills().then();
    });

    onMounted(() => {
      getBills().then();
    });
    return {
      billsList,
      loadingShow,
      getQrCode,
      qrCodeImage,
      qrInfo,
      qrcodeShow,
      qrcodeTarget,
      getBills
    };
  }
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}
.order-product-inner {
  display: flex;
  align-content: center;
}
.order-product-inner .order-tooltip {
  font-size: 12px;
  margin-top: 6px;
  margin-left: 4px;
  color: #1a73e8;
  cursor: pointer;
}
.header {
  display: flex;
  padding-right: 100px;
}
.header .space {
  flex: 1;
}
.header h3 {
  font-size: 21px;
  font-weight: 400;
}
.content {
  padding: 0 100px 0 0;
}
.content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.content ul li {
  padding: 15px 0;
  display: flex;
  font-size: 14px;
  border-bottom: 1px #eceff1 solid;
}
.background-pray {
  background-color: #f1f5fa;
}
.content .title {
  font-size: 14px;
  background: #f4f7f7;
  color: #676a6e;
}
.content span {
  display: block;
  text-align: left;
}
.content span b {
  padding-left: 10px;
  font-weight: 400;
}
.order-no {
  width: 30%;
  overflow: hidden;
}
.order-product {
  width: 20%;
}
.order-price {
  width: 10%;
}
.order-time {
  width: 20%;
}
.order-state {
  width: 20%;
}
.qr-code {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
}
.qr-code h3 {
  text-align: center;
  color: #1a73e8;
}
.qr-code .qr-image {
  width: 260px;
  height: 260px;
}
.qr-image-container {
  width: 260px;
  height: 260px;
  position: relative;
}
.qr-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}
.qr-code .info {
  margin-top: 20px;
}
.tips {
  color: rgba(255, 255, 255, 0.3);
  margin-top: 20px;
}
</style>
