<template>
  <loading v-if="loadingShow"></loading>
  <h2 class="title">购买或升级适合您的超级VPS</h2>
  <div class="vip-introduce">
    <!--未购买套餐介绍-->
    <h5 class="title-l">会员价格499元/月起</h5>
    <p class="tips">
      超级VPS管理器免费提供安全远程桌面服务。
      购买超级VPS，即表示您同意接受《超级VPS服务条款》。
    </p>
    <div class="back">
      <router-link to="/console/shop">返回新版套餐</router-link>
    </div>
  </div>

  <div class="items">
    <!--个人无限版-->
    <div class="item">
      <div class="item-content">
        <div class="top">
          <h2>{{ price.single.name }}</h2>
          <p>适用于个人</p>
          <div class="price">
            <button
              class="price-button"
              @click="buy(price.single, price.single.recommend.id)"
            >
              ￥{{ price.single.recommend.price }}/
              {{ price.single.recommend.title }}
            </button>
            <!--            <p-->
            <!--              v-if="-->
            <!--                $store.state.user.discountInfo &&-->
            <!--                  $store.state.user.discountInfo.desc-->
            <!--              "-->
            <!--              class="discount"-->
            <!--            >-->
            <!--              活动价-->
            <!--              {{ calcPrice(price.singlePlus.recommend.price) }}元,节省-->
            <!--              {{-->
            <!--                price.singlePlus.recommend.price - -->
            <!--                  calcPrice(price.singlePlus.recommend.price)-->
            <!--              }}-->
            <!--              元-->
            <!--            </p>-->
            <p v-if="$store.state.user.discountInfo.tip" class="discount">
              {{ $store.state.user.discountInfo.tip }}
            </p>
          </div>
          <div class="other">
            <h5>或选择其他套餐</h5>

            <button
              v-for="item in price.single.priceList"
              :key="item.id"
              class="other-button"
              @click="buy(price.single, item.id)"
            >
              ￥ {{ item.price }} / {{ item.title }}
            </button>
          </div>
        </div>
        <div class="bottom">
          <h6>超级VPS功能</h6>
          <ul>
            <li>1个用户</li>
            <li>20个浏览器环境</li>
            <li>数据云端同步</li>
            <li>多线程操作</li>
          </ul>
        </div>
      </div>
    </div>
    <!--个人无限版-->
    <div class="item color">
      <div class="item-content">
        <div class="top">
          <h2>{{ price.singlePlus.name }}</h2>
          <p>适用于个人</p>
          <div class="price">
            <button
              class="price-button"
              @click="buy(price.singlePlus, price.singlePlus.recommend.id)"
            >
              ￥{{ price.singlePlus.recommend.price }}/
              {{ price.singlePlus.recommend.title }}
            </button>
            <!--            <p-->
            <!--              v-if="-->
            <!--                $store.state.user.discountInfo &&-->
            <!--                  $store.state.user.discountInfo.desc-->
            <!--              "-->
            <!--              class="discount"-->
            <!--            >-->
            <!--              活动价-->
            <!--              {{ calcPrice(price.singlePlus.recommend.price) }}元,节省-->
            <!--              {{-->
            <!--                price.singlePlus.recommend.price - -->
            <!--                  calcPrice(price.singlePlus.recommend.price)-->
            <!--              }}-->
            <!--              元-->
            <!--            </p>-->
            <p v-if="$store.state.user.discountInfo.tip" class="discount">
              {{ $store.state.user.discountInfo.tip }}
            </p>
          </div>
          <div class="other">
            <h5>或选择其他套餐</h5>

            <button
              v-for="item in price.singlePlus.priceList"
              :key="item.id"
              class="other-button"
              @click="buy(price.singlePlus, item.id)"
            >
              ￥ {{ item.price }} / {{ item.title }}
            </button>
          </div>
        </div>
        <div class="bottom">
          <h6>超级VPS功能</h6>
          <ul>
            <li>1个用户</li>
            <li>无限个浏览器环境</li>
            <li>数据云端同步</li>
            <li>多线程操作</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="item-content">
        <div class="top">
          <h2>团队协作版</h2>
          <p>适用于团队</p>
          <div class="price">
            <button class="price-button" @click="buyTeam('3')">
              3用户/699元/月
            </button>
            <!--            <p-->
            <!--              v-if="-->
            <!--                $store.state.user.discountInfo &&-->
            <!--                  $store.state.user.discountInfo.desc-->
            <!--              "-->
            <!--              class="discount"-->
            <!--            >-->
            <!--              活动价-->
            <!--              {{ calcPrice(699) }}元,节省-->
            <!--              {{ 699 - calcPrice(699) }}-->
            <!--              元-->
            <!--            </p>-->
            <p v-if="$store.state.user.discountInfo.tip" class="discount">
              {{ $store.state.user.discountInfo.tip }}
            </p>
          </div>
          <div class="other">
            <h5>或选择优惠套餐</h5>
            <button class="other-button" @click="buyTeam('5')">
              5用户/999元/月
            </button>
            <button class="other-button" @click="buyTeam('8')">
              8用户/1599元/月
            </button>
          </div>
        </div>
        <div class="bottom">
          <h6>超级VPS功能</h6>
          <ul>
            <li>3+N个用户</li>
            <li>无限个浏览器环境</li>
            <li>数据云端同步</li>
            <li>多线程操作</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="activity-tips">-->
  <!--    <h4>618活动注意事项：</h4>-->
  <!--    <ul>-->
  <!--      <li>1、该活动不包括补差价升级套餐的用户.</li>-->
  <!--      <li>2、参与活动用户，活动后升级套餐按照付款实际金额补差价升级.</li>-->
  <!--      <li>3、虚拟产品不支持任何理由退款，请试用后谨慎参与活动.</li>-->
  <!--    </ul>-->
  <!--  </div>-->
  <pay
    v-if="payShow"
    @cancel="payShow = false"
    :pay-data="payData"
    :pay-mode="payMode"
    :default-selected="payData.defaultSelected"
    @pop="popShow = true"
  ></pay>
  <pay-team
    v-if="payTeamShow"
    @cancel="payTeamShow = false"
    :pay-data="teamData"
    :pay-mode="payMode"
    :default-selected="payTeamData.defaultSelected"
    @pop="popShow = true"
  >
  </pay-team>
  <popup v-show="popShow" @confirm="next">
    <template v-slot:title>
      暂时不可以购买不同的套餐
    </template>
    <template v-slot:describe>
      超级VPS目前不支持购买不同的套餐，敬请原谅
    </template>
  </popup>
</template>

<script>
import Popup from "../../components/Popup.vue";
import Pay from "../../components/Pay.vue";
import { user } from "@/utils/api";
import { onMounted, ref, reactive, h } from "vue";
import Loading from "../../components/Loading.vue";
import PayTeam from "@/components/PayTeam";
import { useStore } from "vuex";
import { Modal } from "ant-design-vue";

export default {
  name: "ConsoleShop",
  components: { PayTeam, Loading, Pay, Popup },
  setup() {
    const store = useStore();
    const loadingShow = ref(false);
    const price = ref({
      single: {
        name: "个人版",
        recommend: {
          price: "N/A",
          title: "N/A"
        }
      },
      singlePlus: {
        name: "个人无限版",
        recommend: {
          price: "N/A",
          title: "N/A"
        }
      },
      team: {
        name: "团队协作版",
        recommend: {
          price: "N/A",
          title: "N/A"
        }
      }
    });
    const payMode = ref({
      wechat: {},
      alipay: {}
    });

    const teamData = reactive({
      three: [],
      five: [],
      eight: [],
      ten: [],
      name: "团队协作版",
      info: "3+N个用户/无限浏览器环境/支持团队协作/数据云端同步/多线程操作"
    });

    // 计算价格
    function calcPrice(price) {
      const discount = Number(
        store.state.user.discountInfo
          ? store.state.user.discountInfo.discount
          : 0
      );
      if (discount !== 0) {
        return Math.floor((price * (100 - discount)) / 100);
      } else {
        return calcCoupon(price);
      }
    }
    // 计算满减
    function calcCoupon(price) {
      const coupon = store.state.user.discountInfo
        ? store.state.user.discountInfo.coupon.split("|")
        : [];
      let discountMax = 0;
      if (coupon.length > 0) {
        coupon.forEach(item => {
          const limitPrice = item.split("-");
          if (Number(limitPrice[0]) <= price) {
            if (Number(limitPrice[1]) > discountMax) {
              discountMax = Number(limitPrice[1]);
            }
          }
        });
      }
      return price - discountMax;
    }
    // 请求价格列表
    async function getPriceList() {
      const priceData = await user
        .getPriceList()
        .then(res => res.data)
        .then();
      // 判断是否操作成功，操作失败添加重试按钮重新请求
      if (priceData.code === 0) {
        // 解构并赋初始值
        const { itemlist: itemList = {}, payment } = priceData.data;
        // 映射价格数据
        const {
          single = {},
          singleplus: singlePlus = {}
          // team = {}
        } = itemList;
        const team = itemList.team;
        // 映射支付方式数据
        // :TODO 修改支付
        const [wechat = {}, alipay = {}] = payment;
        payMode.value = { wechat, alipay };
        // 重新组织价格信息，筛选推荐价格
        single.priceList = [];
        single.data.forEach(item => {
          if (item.is_recommend === "1") {
            single.recommend = item;
          } else {
            single.priceList.push(item);
          }
        });
        // 设置默认选中
        single.defaultSelected = single.recommend.id;
        singlePlus.priceList = [];
        singlePlus.data.forEach(item => {
          if (item.is_recommend === "1") {
            singlePlus.recommend = item;
          } else {
            singlePlus.priceList.push(item);
          }
        });
        //设置默认选中
        singlePlus.defaultSelected = singlePlus.recommend.id;

        /**
         * team数据映射
         * */
        team.data.forEach(item => {
          switch (item.give_son_num) {
            case "3":
              teamData.three.push(item);
              break;
            case "5":
              teamData.five.push(item);
              break;
            case "8":
              teamData.eight.push(item);
              break;
            case "10":
              teamData.ten.push(item);
              break;
            default:
              break;
          }
        });
        price.value = { single, singlePlus, team };
        price.value.single.info =
          "1个用户 / 20个浏览器环境 / 数据云端同步 / 多线程操作";
        price.value.singlePlus.info =
          "1个用户 / 无限浏览器环境 / 数据云端同步 / 多线程操作";
        // 赋值支付方式信息
        payment.value = { wechat, alipay };
      } else {
        // :TODO 处理请求价格失败
      }
    }

    // 联系客服
    function contactService() {
      Modal.info({
        title: "客服 - 企业微信",
        content: h("div", {}, [
          h("img", {
            style: { width: "300px", verticalAlign: "middle" },
            src: "https://www.chaojivps.com/img/kefu.png"
          })
        ]),
        onOk() {}
      });
    }

    onMounted(() => {
      loadingShow.value = true;
      getPriceList().then(() => {
        loadingShow.value = false;
      });
      calcPrice(100);
    });
    return {
      price,
      payMode,
      loadingShow,
      teamData,
      calcPrice,
      contactService
    };
  },
  data() {
    return {
      popShow: false,
      payShow: false,
      payTeamShow: false,
      payData: {
        name: "N/A",
        info: "N/A",
        recommend: {
          title: "N/A",
          price: "N/A"
        },
        priceList: [],
        defaultSelected: 0
      },
      payTeamData: {
        defaultSelected: "5"
      }
    };
  },
  methods: {
    //购买
    async buy(data, defaultId) {
      this.payData = data;
      this.payData.defaultSelected = defaultId;
      this.payShow = true;
    },
    buyTeam(defaultId) {
      this.payTeamData.defaultSelected = defaultId;
      this.payTeamShow = true;
    },
    // 下一步
    next() {
      this.popShow = false;
      this.payShow = false;
    },
    async payCode() {
      this.payShow = false;
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0;
}

.title-l {
  font-size: 20px;
  font-weight: 400;
  padding: 10px 0;
  margin: 10px 0;
}

.tips {
  font-size: 14px;
  color: #7f868a;
}

.item {
  width: 260px;
  box-shadow: 0 1px 6px rgba(60, 64, 67, 0.3);
  border-radius: 8px;
  background: #ffffff;
  margin: 12px 24px 12px 12px;
  text-align: center;
}

.color {
  border: 4px #1d73e9 solid;
}

.items {
  padding-top: 40px;
  display: flex;
}

.top {
  min-height: 200px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  text-align: center;
  word-break: break-word;
}

.top h2 {
  margin: 50px 0 0 0;
  font-size: 26px;
  font-weight: 400;
}

.price-button {
  margin: 30px 0 0 0;
  min-width: 143px;
  color: #1d73e9;
  background: #ffffff;
  border: 1px #dadce0 solid;
  border-radius: 4px;
  padding: 6px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.price-button:hover {
  background: #e8f0fe;
  color: #2065ca;
}

.other {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.other h5 {
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0;
}

.other-button {
  display: block;
  border: none;
  border-radius: 4px;
  padding: 8px 24px;
  font-size: 14px;
  color: #1d73e9;
  font-weight: 400;
  background: none;
  cursor: pointer;
}

.other-button:hover {
  background: #e8f0fe;
  color: #2065ca;
}

.bottom {
  text-align: left;
  margin: 20px;
  padding: 20px 0;
  border-top: 1px #dadce0 solid;
}

.bottom h6 {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.bottom ul {
  padding: 0;
}

.bottom ul li {
  list-style: none;
  font-size: 14px;
  padding: 5px 0;
}

.color .price-button {
  background-color: #1d73e9;
  color: #ffffff;
  border: none;
}

.color .price-button:hover {
  background-color: #2065ca;
}

.null .price-button {
  color: #7f868a;
  font-weight: 400;
}

.null .price-button:hover {
  background-color: #f0f0f0;
}

.null .other {
  color: #dadce0;
}

.null .other-button {
  color: #dadce0;
}

.null .other-button:hover {
  background: #ffffff;
}
.discount {
  font-size: 12px;
  color: #fa8c16;
  font-weight: 400;
  padding-top: 10px;
  margin: 0;
}
.orange {
  padding: 4px 0;
  font-size: 12px;
  color: #fa8c16;
  font-weight: 400;
}
.vip-info {
  padding-top: 10px;
  border-top: 1px #eceff1 solid;
}
.activity-tips {
  border-top: 1px #f1f5fa solid;
  margin-top: 40px;
  padding-top: 20px;
}
.activity-tips ul {
  margin: 0;
  padding: 0;
}
.activity-tips ul li {
  list-style: none;
}
</style>
