<template>
  <div class="reg-code">
    <div class="content">
      <div class="form">
        <header class="header">
          <div class="logo-link">
            <img src="../../assets/img/logo.png" width="40" alt="logo" />
            <a href="https://www.chaojivps.com/">返回首页</a>
          </div>
          <h2>找回密码</h2>
          <h3>请输入超级VPS账号的注册邮箱</h3>
        </header>
        <label class="email">
          <input-outline
            ref="emailRef"
            default-title="邮箱地址"
            v-model:input-value="email.value"
            check="email"
            :error="email.error"
            :error-tips="email.errorTips"
            v-on:check-result="checkEmail"
          />
        </label>
        <label class="email-tips">
          我们会向您的邮箱发送邮件, 请保证邮箱可以正常接收邮件
        </label>
        <div class="submit">
          <a-button @click="sendCode" type="primary" :loading="sendLoading">
            下一步
          </a-button>
        </div>
      </div>
      <div class="pic">
        <img src="../../assets/img/reg-robot.png" width="244" alt="robot" />
      </div>
    </div>
  </div>
</template>

<script>
import InputOutline from "@/components/InputOutline";
import { reactive, ref } from "vue";
import { info } from "@/utils/api";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
export default {
  name: "GetBack",
  components: { InputOutline },
  setup() {
    const router = useRouter();
    const emailRef = ref(null);
    const sendLoading = ref(false);
    const email = reactive({
      value: "",
      error: false,
      errorTips: "",
      checkResult: false
    });
    function checkEmail(result) {
      email.checkResult = result;
    }
    async function sendCode() {
      emailRef.value.checkInput();
      if (email.checkResult) {
        sendLoading.value = true;
        info.sendGetBackPasswordMail(email.value).then(res => {
          sendLoading.value = false;
          if (res.data.code === 0) {
            message.success("已成功发送邮件，请邮箱查收");
            router.push("/password/succeed");
          } else {
            email.error = true;
            email.errorTips = res.data.msg;
          }
        });
      }
    }
    return {
      email,
      checkEmail,
      emailRef,
      sendCode,
      sendLoading
    };
  }
};
</script>

<style scoped>
.email {
  display: block;
}
.reg-code {
  text-align: left;
}

.header {
  color: #202124;
  margin: 10px 0 0 0;
}

.header h2 {
  font-size: 22px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0;
}

.header h3 {
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  padding: 0;
}

.content {
  display: flex;
}

.form {
  padding: 0 40px 0 0;
}

.code-introduce {
  font-size: 12px;
  color: #7f868a;
}

.code-tips {
  font-size: 12px;
  color: #80868b;
  padding: 10px 0;
}

.code-tips a {
  color: #1d73e9;
}

.pic {
  padding: 20px 20px;
}
.submit {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.submit-link {
  flex: 1;
}
.submit-link a {
  color: #1d73e9;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
}
.email-tips {
  display: block;
  font-size: 12px;
  color: #7f868a;
  padding: 10px 0;
}
.logo-link {
  display: flex;
  align-items: center;
}
.logo-link a {
  padding-left: 10px;
  font-size: 14px;
  color: #7f868a;
}
</style>
