<template>
  <div class="bg">
    <div class="content">
      <h3 class="icon" v-if="payModel === 0">
        <WechatOutlined /> 微信扫码支付
      </h3>
      <h3 class="icon alipay" v-else><AlipaySquareFilled />支付宝扫码支付</h3>
      <div class="describe">
        <img
          :src="'https://admin.chaojivps.com/api/qrcode/show?url=' + picUrl"
          :alt="orderNo"
          width="200"
        />
      </div>
      <div class="check">
        <a-button
          type="primary"
          block
          @click="checkOrder"
          :loading="checkOrderLoading"
          >已完成支付</a-button
        >
      </div>
      <div class="submit">
        <a-button type="link" @click="$emit('cancel')" block>
          取消
        </a-button>
      </div>
      <loading v-if="checkOrderLoading"></loading>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import { WechatOutlined, AlipaySquareFilled } from "@ant-design/icons-vue";
import { ref } from "vue";
import { buy } from "@/utils/api";
import { message } from "ant-design-vue";

export default {
  name: "OrderPay",
  props: {
    payModel: Number,
    orderNo: String,
    picUrl: String
  },
  emits: ["cancel", "success"],
  components: { Loading, WechatOutlined, AlipaySquareFilled },
  setup(props, context) {
    const checkOrderLoading = ref(false);
    async function checkOrder() {
      checkOrderLoading.value = true;
      await buy.checkOrder(props.orderNo).then(res => {
        checkOrderLoading.value = false;
        if (res.data.code === 0) {
          message.success(res.data.msg);
          context.emit("success");
        } else {
          message.warn(res.data.msg + " ,如有疑问请联系客服。");
        }
      });
    }
    return {
      checkOrderLoading,
      checkOrder
    };
  }
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}
.content {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(64, 64, 67, 0.3);
  max-width: 560px;
  min-width: 280px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align: center;
}
.title {
  font-size: 16px;
  line-height: 24px;
  color: #3c4043;
  margin: 0;
  padding: 0 24px 14px 0;
  font-weight: 400;
}
.content {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #5f6368;
  padding: 20px 24px 20px 24px;
}
.check {
  margin-top: 10px;
}
.submit {
  margin-top: 10px;
}
.icon {
  color: #70d778;
}
.alipay {
  color: #1a73e8;
}
</style>
