<template>
  <div class="reg-code">
    <div class="content">
      <a-result
        status="success"
        title="修改密码邮件已发送至您的注册邮箱"
        sub-title="请登录邮箱查收邮件"
      >
        <a-button type="primary" @click="$router.push('/console')">
          返回控制台
        </a-button>
      </a-result>
    </div>
  </div>
</template>

<script>
export default {
  name: "SucceedSendCode"
};
</script>

<style scoped></style>
