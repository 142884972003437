<template>
  <div class="login-form">
    <header class="header">
      <img src="../assets/img/logo.png" width="50" alt="logo" />
      <h2>登录</h2>
      <p>使用您的超级VPS账号登录</p>
    </header>
    <section class="form">
      <input-outline
        default-title="电子邮件地址"
        v-model:input-value="account.value"
        v-on:check-result="checkAccount"
        :error="account.error"
        :error-tips="account.errorTips"
        ref="account"
      />
      <input-outline
        default-title="请输入您的密码"
        v-model:input-value="pwd.value"
        check="password"
        v-on:check-result="checkPwd"
        :error="pwd.error"
        :error-tips="pwd.errorTips"
        ref="pwd"
      />
      <label class="remember">
        <input type="checkbox" value="remember" class="remember-checkbox" />
        记住我
        <div class="placeholder"></div>
        <router-link to="/password" class="remember-link"
          >忘记了密码?</router-link
        >
      </label>
    </section>
    <section class="submit">
      <router-link to="/reg">创建账号</router-link>
      <div class="submit-button">
        <z-button @click="login">登录</z-button>
      </div>
    </section>
  </div>
  <loading v-show="loading"></loading>
</template>

<script>
import InputOutline from "../components/InputOutline.vue";
import ZButton from "../components/ZButton.vue";
import Loading from "../components/Loading.vue";
import { user } from "@/utils/api";

export default {
  name: "LoginForm",
  components: { Loading, ZButton, InputOutline },
  data() {
    return {
      loading: false,
      account: {
        value: "",
        check: false,
        checkResult: false,
        error: false,
        errorTips: ""
      },
      pwd: {
        value: "",
        check: false,
        checkResult: false,
        error: false,
        errorTips: ""
      }
    };
  },
  methods: {
    async login() {
      // 调用input内部检查格式
      this.$refs.account.checkInput();
      this.$refs.pwd.checkInput();
      // 检查格式正确，打开loading开始请求
      if (this.account.checkResult && this.pwd.checkResult) {
        // 检查结果置为false，以便后续观察
        this.account.error = false;
        this.pwd.error = false;
        this.loading = true;
        const accounts = this.account.value;
        const pwd = this.pwd.value;
        const res = await user.login(accounts, pwd).then(res => {
          return res.data;
        });
        // 登录失败，关闭loading标识错误，以进行修改
        if (res.code === 0) {
          // 关闭loading
          this.loading = false;
          // 提交到vuex
          // this.$store.commit("login", res.data);
          // 跳转到控制台
          // await this.$router.push("/console");
		  window.location.href = await res.data.pay_url;
        } else if (res.code === -2) {
          await this.$router.push(`/reg/success?mail=${accounts}`);
        } else {
          this.loading = false;
          this.account.error = true;
          this.account.errorTips = "用户名不存在或密码错误";
          this.pwd.error = true;
          this.pwd.errorTips = "用户名不存在或密码错误";
        }
      }
    },
    checkAccount(result) {
      this.account.checkResult = result;
    },
    checkPwd(result) {
      this.pwd.checkResult = result;
    }
  }
};
</script>

<style scoped>
.header {
  color: #202124;
}

.header h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 10px 0;
}

.header p {
  margin: 0;
  font-size: 14px;
}

.form {
  margin-top: 10px;
}

.remember {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  color: #80868b;
  padding-top: 10px;
}

.remember-checkbox {
  margin: 0 8px 0 0;
  border-color: #80868b !important;
}

.submit {
  margin-top: 20px;
  text-align: left;
  display: flex;
  align-items: center;
}

.submit a {
  font-size: 14px;
  color: #1d73e9;
  text-decoration: none;
  font-weight: 500;
}

.submit-button {
  flex: 1;
  text-align: right;
}
.placeholder {
  flex: 1;
}
.remember-link {
  color: #7f868a;
}
</style>
