<template>
  <console-header></console-header>
  <div class="content">
    <section class="first">
      <div class="slogan-container">
        <div class="slogan">
          <h3>为海外电商运营而生的</h3>
          <h1>防关联浏览器</h1>
          <h4>一台设备 + 候鸟 = 1000个账号</h4>
          <p>
            告别一个设备一台虚拟机管理一个账号的老模式吧，拥抱候鸟浏览器对工作效率的绝对提升。一台设备管理您的所有账号，
            有条不紊，保持专注，并与团队保持同步。
          </p>
        </div>
      </div>
      <div class="reg-container">
        <div class="reg">
          <div class="logo">
            <img src="../assets/img/logo.png" width="40" alt="logo" />
            <h1>创建您的候鸟账号</h1>
          </div>
          <form class="reg-form">
            <label class="email">
              <input-outline
                ref="email"
                type="small"
                default-title="邮箱地址"
                v-model:input-value="email.value"
                check="email"
                :error="email.error"
                :error-tips="email.errorTips"
                v-on:check-result="checkEmail"
              />
              <label class="tips">
                我们会向您发送邮件用于验证, 请保证邮箱可以正常接收邮件
              </label>
            </label>
            <label class="password-container">
              <label class="password">
                <input-outline
                  ref="password"
                  check="password"
                  type="small"
                  default-title="密码"
                  v-model:input-value="password.value"
                  :error="password.error"
                  :error-tips="password.errorTips"
                  v-on:check-result="checkPassword"
                />
              </label>
              <label class="password-confirm">
                <input-outline
                  type="small"
                  check="password"
                  default-title="确认密码"
                  v-model:input-value="passwordConfirm.value"
                  @focusout="checkCorrect"
                  :error="passwordConfirm.error"
                  :error-tips="passwordConfirm.errorTips"
                />
              </label>
            </label>
            <label class="tips">
              使用8个或更多字符 (字母、数字、符号的组合)
            </label>
            <label class="invite">
              <input-outline
                ref="invitationCode"
                type="small"
                default-title="邀请码"
                v-model:input-value="invitationCode.value"
                check="invite"
                :error="invitationCode.error"
                :error-tips="invitationCode.errorTips"
                v-on:check-result="checkInvite"
              />
              <label class="tips">
                填写4位数字邀请码 (可以为空)
              </label>
            </label>
          </form>
          <div class="submit">
            <div class="submit-link">
              <router-link to="/">登录现有账号</router-link>
            </div>
            <z-button @click="reg">创建账号</z-button>
          </div>
          <div class="service">
            有账号方面的疑问？ 您可以 <a href="#">联系我们的客服</a>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer class="footer-container">
    <div class="footer">
      <div class="logo-black">
        <img src="../assets/img/logo-black.png" alt="logo" height="40" />
      </div>
      <div class="footer-info">
        <div class="copyright">
          @MBBROWSER www.mbbroser.com 候鸟版权所有
        </div>
        <div class="wechat">
          <span>
            客服(微信): ehouniao
          </span>
          <span>
            商务合作(微信): mbbrowser
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import ConsoleHeader from "@/pages/console/ConsoleHeader";
import InputOutline from "@/components/InputOutline";
import ZButton from "@/components/ZButton";
import { user } from "@/utils/api";

export default {
  name: "Invite",
  props: {
    code: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      email: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false
      },
      invitationCode: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false
      },
      password: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false
      },
      passwordConfirm: {
        value: "",
        error: false,
        errorTips: ""
      },
      loadingShow: false
    };
  },
  components: { ZButton, ConsoleHeader, InputOutline },
  methods: {
    async reg() {
      // 调用类型检查
      this.$refs.email.checkInput();
      this.$refs.password.checkInput();
      this.$refs.invitationCode.checkInput();
      this.checkCorrect();
      // 打开loading
      this.loadingShow = true;
      if (
        this.password.checkResult &&
        this.invitationCode.checkResult &&
        this.email.checkResult &&
        this.checkCorrect()
      ) {
        const regData = await user
          .reg(this.email.value, this.password.value, this.invitationCode.value)
          .then(res => res.data);
        // 关闭loading
        this.loadingShow = false;
        switch (regData.code) {
          case 0:
            // :TODO 注册成功
            // 提交store
            this.$store.commit("login", regData.data);
            await this.$router.push("/reg/success");
            break;
          case -3:
            this.email.error = true;
            this.email.errorTips = regData.msg;
            break;
          case -4:
            this.password.error = true;
            this.password.errorTips = regData.msg;
            break;
          default:
            break;
        }
        if (regData.code === 0) {
          this.loadingShow = false;
        } else {
          this.loadingShow = false;
          this.email.error = true;
          this.email.errorTips = regData.msg;
        }
      } else {
        this.loadingShow = false;
        return false;
      }
    },
    // 检测密码是否一致
    checkCorrect() {
      if (this.password.value === this.passwordConfirm.value) {
        return true;
      } else {
        this.passwordConfirm.error = true;
        this.passwordConfirm.errorTips = "两次输入的密码不一致";
        return false;
      }
    },
    checkEmail(result) {
      this.email.checkResult = result;
    },
    checkPassword(result) {
      this.password.checkResult = result;
    },
    checkInvite(result) {
      this.invitationCode.checkResult = result;
    },
    compareCode() {
      if (this.code !== "") {
        this.invitationCode.value = this.code;
        this.$store.commit("putCode", this.code);
      } else if (localStorage.getItem("code") !== null) {
        this.invitationCode.value = localStorage.getItem("code");
      } else {
        this.invitationCode.value = "";
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.compareCode();
    });
  }
};
</script>

<style scoped>
.content {
  background: url("../assets/img/bg.png") no-repeat center center;
  min-height: 100vh;
}

.first {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slogan-container {
  flex: 1;
  padding-right: 200px;
}

.slogan {
  max-width: 500px;
}

.slogan h3 {
  font-size: 36px;
  font-weight: 400;
  margin: 0;
  padding: 5px 0;
  color: #1a73e8;
}

.slogan h1 {
  font-size: 48px;
  font-weight: 500;
  margin: 0;
  padding: 5px 0;
  color: #1a73e8;
}

.slogan h4 {
  font-size: 24px;
  font-weight: 400;
  margin: 10px 0;
  padding: 5px 10px;
  background: #1a73e8;
  color: #ffffff;
  border-radius: 4px;
  display: inline-block;
}

.slogan p {
  font-size: 16px;
  line-height: 26px;
  color: #616161;
}

.reg {
  max-width: 410px;
  border-radius: 6px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.reg-form {
  padding: 0 30px;
}

.logo {
  padding: 30px 30px 0 30px;
}

.logo h1 {
  font-size: 21px;
  font-weight: 400;
}

.tips {
  font-size: 12px;
  color: #7f868a;
  padding: 8px 0;
}

.password-container {
  display: flex;
}

.password {
  display: block;
  margin-right: 10px;
  flex: 1;
  width: 30%;
}

.password-confirm {
  flex: 1;
  display: block;
}

.submit {
  display: flex;
  align-items: center;
  padding: 20px 30px 10px 30px;
}

.submit-link {
  flex: 1;
}

.submit-link a {
  color: #1d73e9;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
}

.service {
  padding: 20px 30px;
  text-align: left;
  background: #f8f9fa;
  font-size: 12px;
  color: #616161;
}

.service a {
  color: #1d73e9;
}

.footer {
  background: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 20px;
  font-size: 14px;
}

.footer .logo-black {
  padding-bottom: 10px;
}

.footer-info {
  display: flex;
}
.wechat {
  padding-left: 20px;
}

@media only screen and (max-width: 1100px) {
  .slogan-container {
    padding-right: 60px;
  }
  .slogan h3 {
    font-size: 30px;
  }

  .slogan h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 980px) {
  .first {
    flex-direction: column;
  }

  .slogan-container {
    display: flex;
    justify-content: center;
    text-align: center;
    order: 1;
    flex-wrap: wrap;
    padding: 0 20px 100px 20px;
  }

  .reg-container {
    display: flex;
    order: 2;
    justify-content: center;
    padding: 0 20px;
  }

  .slogan h3 {
  }
}

@media only screen and (max-width: 480px) {
  .first {
    padding: 60px 0;
  }

  .slogan-container {
    padding-bottom: 40px;
  }

  .slogan h3 {
    font-size: 30px;
  }

  .slogan h4 {
    font-size: 18px;
  }
}
</style>
