import store from "@/store";
// 计算价格
export const calcPrice = price => {
  const discount = Number(
    store.state.user.discountInfo ? store.state.user.discountInfo.discount : 0
  );
  if (discount !== 0) {
    return Math.floor((price * (100 - discount)) / 100);
  } else {
    return calcCoupon(price);
  }
};
// 计算满减
function calcCoupon(price) {
  const coupon = store.state.user.discountInfo
    ? store.state.user.discountInfo.coupon.split("|")
    : [];
  let discountMax = 0;
  if (coupon.length > 0) {
    coupon.forEach(item => {
      const limitPrice = item.split("-");
      if (Number(limitPrice[0]) <= price) {
        if (Number(limitPrice[1]) > discountMax) {
          discountMax = Number(limitPrice[1]);
        }
      }
    });
  }
  return price - discountMax;
}
