<template>
  <div>
    <div class="info">
      请登录Luminati账号,登录后账户即与超级VPS账号松散绑定(不唯一绑定),可同步代理到超级VPS客户端。
    </div>
    <div class="form">
      <a-form layout="vertical">
        <a-form-item label="Luminati账户" v-bind="validateInfos.username">
          <a-input
            size="large"
            style="width: 400px"
            v-model:value="loginRef.username"
          />
        </a-form-item>
        <a-form-item label="Luminati密码" v-bind="validateInfos.password">
          <a-input-password
            size="large"
            style="width: 400px"
            v-model:value="loginRef.password"
          />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            class="action-button login-button"
            size="large"
            @click="loginLumi"
            :loading="loginLoading"
          >
            登录Luminati
          </a-button>
          <a-button class="action-button" size="large">注册Luminati</a-button>
        </a-form-item>
      </a-form>
    </div>
    <p class="promise">
      超级VPS会妥善加密保存您的账户信息,并承诺绝不泄露给任何人,更多内容请查阅<a
        >超级VPS用户协议</a
      >
    </p>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useForm } from "@ant-design-vue/use";
import { lumi } from "@/utils/api";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";

export default {
  name: "LuminatiLogin",
  setup() {
    const store = useStore();
    const router = useRouter();
    const loginRef = reactive({
      username: "",
      password: ""
    });
    const loginRulesRef = reactive({
      username: [
        {
          required: true,
          message: "用户名不能为空"
        }
      ],
      password: [
        {
          required: true,
          message: "密码不能为空"
        }
      ]
    });
    const { resetFields, validate, validateInfos } = useForm(
      loginRef,
      loginRulesRef
    );
    const loginLoading = ref(false);
    async function loginLumi() {
      validate()
        .then(() => {
          loginLoading.value = true;
          lumi
            .login(loginRef.username, loginRef.password)
            .then(res => {
              loginLoading.value = false;
              if (res.data.code === 0) {
                store.commit("lumiLogin", loginRef.username);
                message.success("登录Luminati成功");
                router.push("/console/luminati/");
              } else {
                message.error(res.data.msg);
              }
            })
            .catch(err => {
              loginLoading.value = false;
              console.log(err);
            });
        })
        .catch(err => {
          console.log(err);
        });
    }
    return {
      loginRef,
      resetFields,
      validateInfos,
      loginLumi,
      loginLoading
    };
  }
};
</script>

<style scoped>
.form {
  width: 400px;
}
.info {
  background-color: rgba(82, 99, 115, 0.1);
  color: #526373;
  max-width: 400px;
  padding: 20px;
  border-radius: 6px;
}
.form {
  max-width: 400px;
  padding: 20px 0 0 0;
  color: #526373;
}
.promise {
  font-size: 12px;
  display: block;
  max-width: 400px;
}
.action-button {
  margin-right: 10px;
}
</style>
