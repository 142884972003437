<template>
  <header class="header">
    <div class="links">
      <router-link to="/console/session/">我的VPS服务器列表</router-link>
      <!--<router-link to="/console/session/share/">
        我分享的环境
      </router-link>
      <router-link to="/console/session/accept/">
        我接收的环境
      </router-link>
      <router-link to="/console/session/history">
        我的历史配置环境
      </router-link>-->
    </div>
    <!--    <div class="refresh">-->
    <!--      <a-button-->
    <!--          type="primary"-->
    <!--          @click="refreshList"-->
    <!--          :loading = 'loading'-->
    <!--      >-->
    <!--        刷新列表-->
    <!--      </a-button>-->
    <!--    </div>-->
  </header>
  <section class="content">
    <router-view></router-view>
  </section>
</template>

<script>
import { client } from "@/utils/api";
import { ref, provide } from "vue";

export default {
  name: "ConsoleSession",
  setup() {
    const loading = ref(false);
    const refresh = ref(false);
    provide("refresh", refresh);

    async function refreshList() {
      loading.value = true;
      await client.refreshList().then();
      loading.value = false;
      refresh.value = !refresh.value;
    }

    return {
      loading,
      refreshList
    };
  }
};
</script>

<style scoped>
.header {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
}

.header h3 {
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0;
}

.refresh {
  flex: 1;
  text-align: right;
  padding-right: 40px;
}

.header p {
  margin: 0;
}

.header a {
  font-size: 28px;
  margin-right: 40px;
  color: #5f6368;
}

.header a.router-link-exact-active {
  font-size: 28px;
  color: #1a73e8;
  border-bottom: 2px #1a73e8 solid;
}

.session-table {
  margin-top: 40px;
}
</style>
