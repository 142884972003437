import { createRouter, createWebHistory } from "vue-router";
import Login from "./pages/Login.vue";
import LoginForm from "./pages/LoginForm.vue";
import Reg from "./pages/Reg.vue";
import RegForm from "./pages/reg/RegForm.vue";
import RegCode from "./pages/reg/RegCode.vue";
import Console from "./pages/Console.vue";
import ConsoleInfo from "./pages/console/ConsoleInfo.vue";
import ConsoleShop from "./pages/console/ConsoleShop.vue";
import ConsoleBills from "./pages/console/ConsoleBills.vue";
import ConsoleInvite from "./pages/console/ConsoleInvite.vue";
import Agreement from "./pages/Agreement.vue";
import RegSuccess from "./pages/reg/RegSuccess.vue";
import Invite from "@/pages/Invite.vue";

import store from "./store";
import NotFound from "./pages/NotFound.vue";
import ConsoleAccount from "@/pages/console/ConsoleAccount";
import ConsoleSession from "@/pages/console/ConsoleSession";
import ConsoleAccountSonSessions from "@/pages/console/ConsoleAccountSonSessions";
import ConsoleAccountSonList from "@/pages/console/ConsoleAccountSonList";
import ConsoleSessionList from "@/pages/console/ConsoleSessionList";
//import ConsoleSessionShareList from "@/pages/console/ConsoleSessionShareList";
//import ConsoleSessionAcceptList from "@/pages/console/ConsoleSessionAcceptList";
//import ConsoleSessionHistory from "@/pages/console/ConsoleSessionHistory";
import Alipay from "@/components/Alipay";
import ConsoleRoute from "@/pages/console/ConsoleRoute.vue";
import Password from "@/pages/Password";
import GetBack from "@/pages/password/GetBack";
import SucceedSendCode from "@/pages/password/SucceedSendCode";
import ChangePassword from "@/pages/password/ChangePassword";
import ConsoleLuminati from "@/pages/console/ConsoleLuminati";
import LuminatiLogin from "@/pages/console/LuminatiLogin";
import LuminatiAccounts from "@/pages/console/LuminatiAccounts";
import Active from "@/pages/Active";
import Shop from "@/pages/console/Shop";

const routes = [
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
  {
    path: "/",
    component: Login,
    meta: {
      title: "账号登录-超级VPS管理器"
    },
    children: [
      {
        path: "",
        component: LoginForm
      }
    ]
  },
  {
    path: "/password",
    component: Password,
    children: [
      {
        path: "",
        component: GetBack
      },
      {
        path: "succeed",
        component: SucceedSendCode
      },
      {
        path: "change",
        component: ChangePassword,
        props: route => ({ hash: route.query.hash })
      }
    ]
  },
  {
    path: "/route",
    component: ConsoleRoute,
    props: route => ({ token: route.query.token })
  },
  {
    path: "/active",
    component: Active,
    props: route => ({ hash: route.query.hash })
  },
  {
    path: "/agreement",
    component: Agreement
  },
  {
    path: "/reg",
    component: Reg,
    meta: {
      title: "创建注册账号-超级VPS管理器"
    },
    children: [
      {
        path: "",
        component: RegForm,
        props: route => ({ code: route.query.code })
      },
      {
        path: "code",
        component: RegCode
      },
      {
        path: "success",
        component: RegSuccess,
        props: route => ({ mail: route.query.mail })
      }
    ]
  },
  {
    path: "/console",
    component: Console,
    meta: {
      title: "控制台-超级VPS管理器",
      requireLogin: true
    },
    children: [
      {
        path: "",
        component: ConsoleInfo
      },
      {
        path: "shop",
        component: Shop
      },
      {
        path: "old_shop",
        component: ConsoleShop
      },
      {
        path: "bills",
        component: ConsoleBills
      },
      {
        path: "invite",
        component: ConsoleInvite
      },
      // 子账户
      {
        path: "account",
        component: ConsoleAccount,
        children: [
          {
            path: "",
            component: ConsoleAccountSonList
          },
          {
            path: "id/:id",
            component: ConsoleAccountSonSessions,
            props: true
          }
        ]
      },
      // Luminati
      {
        path: "luminati",
        component: ConsoleLuminati,
        children: [
          {
            path: "",
            component: LuminatiAccounts,
            meta: {
              luminati: true
            }
          },
          {
            path: "login",
            component: LuminatiLogin
          }
        ]
      },
      // VPS列表
      {
        path: "session",
        component: ConsoleSession,
        children: [
          {
            path: "",
            component: ConsoleSessionList
          }
        ]
      }
    ]
  },
  {
    path: "/invite",
    component: Invite,
    props: route => ({ code: route.query.code })
  },
  {
    path: "/alipay",
    component: Alipay
  }
];

const router = createRouter({
  history: createWebHistory("/console/"),
  routes
});

// 路由前判断
router.beforeEach(to => {
  const sessionData = sessionStorage.getItem("user");
  const localData = localStorage.getItem("user");
  // 如果需要登录
  if (to.meta.requireLogin) {
    // 检查store&localstorage&sessionStorage里是否有记录
    if (store.state.user.token !== "" && store.state.user.discountInfo) {
      console.log("Welcome :)");
    } else if (sessionData !== null) {
      try {
        JSON.parse(sessionData);
        store.commit("updateLogin", JSON.parse(sessionData));
      } catch (error) {
        store.commit("quit");
        router.replace("/").then();
      }
    } else if (localData !== null) {
      try {
        JSON.parse(localData);
        store.commit("updateLogin", JSON.parse(localData));
      } catch (error) {
        store.commit("quit");
        router.replace("/").then();
      }
    } else {
      router.replace("/").then();
    }
  } else if (to.fullPath === "/") {
    if (store.state.user.token || localData) {
      router.push("/console").then();
    }
  } else if (to.meta.title) {
    document.title = to.meta.title;
  }
});

router.beforeEach(to => {
  if (to.meta.luminati) {
    if (!store.state.user.luminatiUser) {
      router.push("/console/luminati/login");
    }
  }
});

export default router;
