<template>
  <div class="bg">
    <div class="content">
      <h2 class="title">
        升级套餐需要将补齐当前套餐剩余时间差价
      </h2>
      <div class="describe">
        <p>
          当前套餐剩余时间: <b>{{ days }}天</b>
        </p>
        <p>
          需要补充差价: <b>{{ price }}元</b>
        </p>
        <p>
          合计: <b>{{ total }}元</b>
        </p>
      </div>
      <div class="submit">
        <a-button @click="$emit('cancel')" class="submit-button">取消</a-button>
        <a-button
          @click="$emit('confirm')"
          class="submit-button"
          type="primary"
        >
          确定升级
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderUpdate",
  props: { days: Number, price: Number, total: Number },
  emits: ["cancel", "confirm"]
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(32, 33, 36, 0.6);
}
.content {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(64, 64, 67, 0.3);
  max-width: 560px;
  min-width: 280px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.title {
  font-size: 16px;
  line-height: 24px;
  color: #1a73e8;
  margin: 0;
  padding: 0 24px 14px 0;
  font-weight: 500;
}
.content {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #5f6368;
  padding: 20px 20px 10px 20px;
}
.submit {
  display: flex;
  justify-content: flex-end;
  min-height: 52px;
  margin: 0;
  padding: 8px;
}
.submit-button {
  margin-left: 10px;
}
</style>
