<template>
  <header class="header">
    <div class="header-content">
      <div class="logo">
        <img src="../assets/img/logo-across.png" width="134" alt="logo" />
      </div>
      <a href="https://www.chaojivps.com/" class="back-link">
        返回首页
      </a>
    </div>
  </header>
  <div class="skipping">
    <a-spin size="large"></a-spin>
    <h3 class="skipping-title">激活中,请稍候...</h3>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { reg } from "@/utils/api";
import { message } from "ant-design-vue";

export default {
  props: { hash: String },
  name: "Active",
  setup(props) {
    const router = useRouter();
    async function active() {
      await reg.activeAccount(props.hash).then(res => {
        if (res.data.code === 0) {
          message.success(res.data.msg);
        } else {
          message.warn(`${res.data.msg}`);
        }
        router.replace("/console");
      });
    }
    onMounted(() => {
      active().then();
    });
  }
};
</script>

<style scoped>
.skipping {
  min-height: 400px;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.skipping-title {
  margin-top: 20px;
}
.header {
  height: 64px;
  line-height: 64px;
  color: #80868b;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 400;
}
.back-link {
  padding-left: 20px;
  color: #7f868a;
  text-decoration: none;
}
.back-link:hover {
  color: #1a73e8;
}
.header-content {
  display: flex;
  align-items: center;
  padding: 0 100px;
}

.logo {
  display: flex;
  align-items: center;
}
.user-info {
  flex: 1;
  text-align: right;
}
.user-info b {
  padding-right: 10px;
  color: #202124;
  font-weight: 400;
}
.quit {
  cursor: pointer;
}
@media only screen and (max-width: 1200px) {
  .header-content {
    padding: 0 20px;
  }
}
</style>
