<template>
  <div class="reg-code">
    <div class="content">
      <div class="form">
        <header class="header">
          <div class="logo-link">
            <img src="../../assets/img/logo.png" width="40" alt="logo" />
            <a href="https://www.chaojivps.com/">返回首页</a>
          </div>
          <h2>创建新密码</h2>
          <h3>创建账户{{ email }}的密码</h3>
        </header>
        <a-form class="password-form">
          <a-form-item class="password" v-bind="validateInfos.password">
            <a-input-password
              size="large"
              placeholder="请输入您的密码"
              class="password-input"
              v-model:value="passwordRef.password"
            />
          </a-form-item>
          <a-form-item
            class="password-correct password"
            v-bind="validateInfos.passwordCorrect"
          >
            <a-input-password
              size="large"
              placeholder="请再输入一次您的密码"
              class="password-input"
              v-model:value="passwordRef.passwordCorrect"
            />
          </a-form-item>
        </a-form>

        <div class="submit">
          <a-button
            type="primary"
            @click="changePassword"
            :loading="changeLoading"
          >
            提交修改
          </a-button>
        </div>
      </div>
      <div class="pic">
        <img src="../../assets/img/reg-robot.png" width="244" alt="robot" />
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, reactive, ref } from "vue";
import { useForm } from "@ant-design-vue/use";
import { info } from "@/utils/api";
import router from "@/router";
import { message } from "ant-design-vue";

export default {
  name: "ChangePassword",
  props: { hash: String },
  setup(props) {
    const passwordRef = reactive({
      password: "",
      passwordCorrect: ""
    });
    // 密码二次校验
    const validatePassword = async (rule, value) => {
      if (value !== passwordRef.password) {
        return Promise.reject("请输入一致的密码");
      } else {
        return Promise.resolve();
      }
    };
    const { resetFields, validate, validateInfos } = useForm(
      passwordRef,
      reactive({
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "密码应为6到18个字符",
            trigger: "change"
          }
        ],
        passwordCorrect: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur"
          },
          {
            min: 6,
            max: 18,
            message: "密码应为6到18个字符",
            trigger: "change"
          },
          {
            validator: validatePassword,
            trigger: "blur"
          }
        ]
      })
    );
    const changeLoading = ref(false);
    async function changePassword() {
      changeLoading.value = true;
      validate()
        .then(() => {
          changeLoading.value = false;
          info.resetPassword(passwordRef.password, props.hash).then(res => {
            if (res.data.code === 0) {
              message.success("密码修改成功，请重新登录");
              router.push("/console");
            } else {
              message.warn(res.data.msg);
            }
          });
        })
        .catch(() => {
          changeLoading.value = false;
        });
    }
    // 验证Hash
    const email = ref("");
    async function checkHash() {
      await info.checkHash(props.hash).then(res => {
        if (res.data.code === 0) {
          message.success("验证成功，请创建新密码");
          email.value = res.data.data.email;
        } else {
          message.error("链接过期或不正确,请查看最新邮件");
          router.push("/console");
        }
      });
    }
    onBeforeMount(() => {
      checkHash().then();
    });
    return {
      passwordRef,
      resetFields,
      validate,
      validateInfos,
      changePassword,
      changeLoading,
      email
    };
  }
};
</script>

<style scoped>
.email {
  display: block;
}
.reg-code {
  text-align: left;
}

.header {
  color: #202124;
  margin: 10px 0 0 0;
}

.header h2 {
  font-size: 22px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0;
}

.header h3 {
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  padding: 0;
}

.content {
  display: flex;
}

.form {
  padding: 0 40px 0 0;
}

.code-introduce {
  font-size: 12px;
  color: #7f868a;
}

.code-tips {
  font-size: 12px;
  color: #80868b;
  padding: 10px 0;
}

.code-tips a {
  color: #1d73e9;
}

.pic {
  padding: 20px 20px;
}
.submit {
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.submit-link {
  flex: 1;
}
.submit-link a {
  color: #1d73e9;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
}
.email-tips {
  display: block;
  font-size: 12px;
  color: #7f868a;
  padding: 10px 0;
}
.logo-link {
  display: flex;
  align-items: center;
}
.logo-link a {
  padding-left: 10px;
  font-size: 14px;
  color: #7f868a;
}
.password-form {
  margin-top: 20px;
}
.password {
  margin-bottom: 10px;
}
.password-input {
  width: 300px;
}
</style>
