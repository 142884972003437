<template>
  <div class="login-container">
    <div class="login">
      <router-view></router-view>
    </div>
    <a href="https://www.chaojivps.com/" class="link">返回首页</a>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {};
  }
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("https://z3.ax1x.com/2021/07/29/WHokxH.jpg");
  background-size: cover;
  flex-direction: column;
}
.login {
  border-radius: 8px;
  width: 420px;
  min-height: 400px;
  position: relative;
  padding: 40px;
  text-align: center;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 120px;
}

.link {
  color: #ffffff;
  text-decoration: underline;
}

@media only screen and (max-width: 480px) {
  .login {
    padding: 30px;
  }
}
</style>
